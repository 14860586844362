import { WebStorageStateStore } from "oidc-client-ts";

export default class LoyaltyWebStorageStateStore extends WebStorageStateStore {
  set(key: string, value: string): Promise<void> {
    // Override the key used for saving the user object. This is to avoid creating a new user object for each client ID
    if (key.startsWith("user:")) {
      return super.set("user", value);
    }

    return super.set(key, value);
  }

  get(key: string): Promise<string | null> {
    // Override the key used for getting the user object.
    if (key.startsWith("user:")) {
      return super.get("user");
    }

    return super.get(key);
  }

  remove(key: string): Promise<string | null> {
    // Override the key used for removing the user object.
    if (key.startsWith("user:")) {
      return super.remove("user");
    }

    return super.remove(key);
  }
}
