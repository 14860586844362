import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";

import { PERKS_BLOCK_ID } from "@/base/blocks/PerkBlock/PerkBlock";
import { Col } from "@/base/components/Layout";
import { TAB_BAR_ICONS } from "@/base/components/TabBar/constants";
import { useUser } from "@/base/components/UserProvider";
import { scrollToWithOffset } from "@/base/utils/scrollHelpers";
import { NavigationPath } from "@/constants/navigation";
import {
  fetchMenu,
  selectGiftCardNotificationsCount,
  selectHasMenuNotifications,
} from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { fetchPerks } from "@/store/perks";
import { shouldLoad } from "@/store/utils";
import { createUseStyles } from "@/theme";

import TabBarButton from "./TabBarButton";

export const BOTTOM_NAVBAR_HEIGHT = 64; // manually detected

const useStyles = createUseStyles(({ color, zIndex, spacing }) => ({
  container: {
    zIndex: zIndex.stickyMenu,
    backgroundColor: color.black,
    position: "fixed",
    bottom: 0,
    width: "100%",
    paddingTop: spacing.l,
    paddingBottom: spacing.l,
  },
}));

type Props = {
  openMenu: () => void;
};

const TabBar: React.FC<Props> = ({ openMenu }) => {
  const classes = useStyles();
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const [{ logIn }] = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hasMenuNotifications = useAppSelector(selectHasMenuNotifications);
  const perks = useAppSelector((state) => state.perks.perks);
  const menu = useAppSelector((state) => state.cms.menu);
  const giftCardNotificationsCount = useAppSelector(
    selectGiftCardNotificationsCount,
  );
  const dispatch = useAppDispatch();

  const giftCardAction = (): void => {
    if (isAuthenticated) {
      navigate(NavigationPath.GiftCard);
    } else if (location.pathname === NavigationPath.Home) {
      scrollToWithOffset(PERKS_BLOCK_ID);
      return;
    } else {
      navigate(`${NavigationPath.Home}#${PERKS_BLOCK_ID}`);
    }
  };

  useEffect(() => {
    if (isAuthenticated && shouldLoad(perks)) {
      void dispatch(fetchPerks());
    }

    if (shouldLoad(menu)) {
      void dispatch(fetchMenu());
    }
  }, [isAuthenticated, menu, perks]);

  return (
    <Col col={12} noMargin belowSm className={classes.container}>
      <Col alignCenter noMargin>
        <TabBarButton
          pathname={NavigationPath.Home}
          icon={TAB_BAR_ICONS.APOLLO_CLUB.defaultIcon}
          activeIcon={TAB_BAR_ICONS.APOLLO_CLUB.activeIcon}
          caption={t("tabBar.home")}
        />
      </Col>
      <Col alignCenter noMargin>
        <TabBarButton
          action={giftCardAction}
          pathname={NavigationPath.GiftCard}
          notificationsCount={giftCardNotificationsCount}
          icon={TAB_BAR_ICONS.GIFT_CARDS.defaultIcon}
          activeIcon={TAB_BAR_ICONS.GIFT_CARDS.activeIcon}
          caption={t("tabBar.giftCards")}
        />
      </Col>
      <Col alignCenter noMargin>
        <TabBarButton
          action={isAuthenticated ? undefined : logIn}
          icon={TAB_BAR_ICONS.PROFILE.defaultIcon}
          activeIcon={TAB_BAR_ICONS.PROFILE.activeIcon}
          pathname={isAuthenticated ? NavigationPath.Profile : undefined}
          caption={t("tabBar.profile")}
        />
      </Col>
      <Col alignCenter noMargin>
        <TabBarButton
          action={openMenu}
          icon={TAB_BAR_ICONS.BURGER_MENU.defaultIcon}
          hasNotifications={hasMenuNotifications}
          caption={t("tabBar.more")}
        />
      </Col>
    </Col>
  );
};

export default TabBar;
