import type React from "react";
import classnames from "classnames";

import { VisibilityBlock } from "@/base/components/Layout";
import {
  type BlockProps,
  HORIZONTAL_GUTTER,
  type MarginProps,
  ROW_MARGIN,
  ROW_MARGIN_MOBILE,
} from "@/base/components/Layout/constants";
import type { VisibilityProps } from "@/base/components/Layout/VisibilityBlock";
import { createUseStyles } from "@/theme";

const colPadding = HORIZONTAL_GUTTER / 2;
const horizontalPaddingLarge = ROW_MARGIN - colPadding;
const horizontalPaddingSmall = ROW_MARGIN_MOBILE - colPadding;

type StyleProps = MarginProps &
  VisibilityProps & {
    justifyCenter?: boolean;
  };

type RowProps = StyleProps & BlockProps;

const useStyles = createUseStyles<StyleProps>(({ sizes }) => ({
  row: {
    flexWrap: "wrap",
    flexDirection: "row",
    width: "100%",
    maxWidth: sizes.smContainer,
    margin: [0, "auto"],
    paddingLeft: ({ noMargin, noMarginLeft, noHorizontalMargin }) =>
      noMargin || noMarginLeft || noHorizontalMargin
        ? 0
        : horizontalPaddingSmall,
    paddingRight: ({ noMargin, noMarginRight, noHorizontalMargin }) =>
      noMargin || noMarginRight || noHorizontalMargin
        ? 0
        : horizontalPaddingSmall,
    display: "flex",

    [sizes.md]: {
      paddingLeft: ({ noMargin, noMarginLeft }) =>
        noMargin || noMarginLeft ? 0 : horizontalPaddingLarge,
      paddingRight: ({ noMargin, noMarginRight }) =>
        noMargin || noMarginRight ? 0 : horizontalPaddingLarge,
      maxWidth: sizes.lgContainer,
    },
    justifyContent: ({ justifyCenter }) => (justifyCenter ? "center" : "unset"),
  },
}));
const Row: React.FC<RowProps> = ({
  id,
  children,
  className,
  style,
  onClick,
  hide,
  belowSm,
  belowMd,
  belowLg,
  aboveSm,
  aboveMd,
  aboveLg,
  aboveXl,
  analyticsAttributes,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <VisibilityBlock
      hide={hide}
      belowSm={belowSm}
      belowMd={belowMd}
      belowLg={belowLg}
      aboveSm={aboveSm}
      aboveMd={aboveMd}
      aboveLg={aboveLg}
      aboveXl={aboveXl}
    >
      <div
        id={id}
        style={style}
        onClick={onClick}
        className={classnames([classes.row, className])}
        {...analyticsAttributes}
      >
        {children}
      </div>
    </VisibilityBlock>
  );
};

export default Row;
