import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import EditFormPage from "@/base/components/Form/v1/EditFormPage";
import GroupedFields from "@/base/components/Form/v1/EditFormPage/GroupedFields";
import ModalArray from "@/base/components/Modal/ModalArray";
import { EMAIL_ICON } from "@/constants/blobIcons";
import config from "@/constants/config";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import EditEmail from "@/pages/Email/components/EditEmail";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  fetchUserData,
  selectEmails,
  selectIsUserDataLoaded,
} from "@/store/user";

const EmailsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const emails = useAppSelector(selectEmails);
  const isUserDataLoaded = useAppSelector(selectIsUserDataLoaded);
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isUserDataLoaded && emails.length === 0) {
      navigate(NavigationPath.EditProfile);
    }
  }, [isUserDataLoaded, emails]);

  const primaryEmail = emails.find((email) => email.isPrimary);
  const secondaryEmails = emails.filter((email) => !email.isPrimary);

  return (
    <EditFormPage
      isLoading={!isUserDataLoaded || emails.length === 0}
      form={{
        headingIconUrl: EMAIL_ICON,
        headingTitle: t("edit_email_header"),
        headingSubTitle: t("edit_email_subtitle"),
        content: t("edit_email_helper_text"),
        addRow: t("add_email_address"),
        addRowAction: () => navigate(NavigationPath.AddEmail),
        hasMaxRowsReached: emails.length >= config.appConfig.maxEmailCount,
        maxRowsReachedText: t("max_emails_reached"),
        goBackText: t("go_to_profile"),
        goBackAction: () => navigate(NavigationPath.Profile),
      }}
    >
      <ModalArray
        modals={{
          primarySuccessModal: {
            title: t("modal_primary_email_update_title"),
            subTitle: t("modal_primary_email_update_subtitle"),
            content: t("modal_primary_email_update_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.EMAIL_PRIMARY_SUCCESS,
          },
          editSuccessModal: {
            title: t("modal_email_edited_title"),
            subTitle: t("modal_email_edited_subtitle"),
            content: t("modal_email_edited_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.EMAIL_CHANGE_SUCCESS,
          },
          errorModal: {
            title: t("modal_email_error_title"),
            subTitle: t("modal_email_error_subtitle"),
            content: t("modal_email_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.EMAIL_CHANGE_DECLINE,
          },
          removeSuccessModal: {
            title: t("modal_email_removed_title"),
            subTitle: t("modal_email_removed_subtitle"),
            content: t("modal_email_removed_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_EMAIL_SUCCESS,
          },
          removeErrorModal: {
            title: t("modal_email_remove_error_title"),
            subTitle: t("modal_email_remove_error_subtitle"),
            content: t("modal_email_remove_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_EMAIL_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={async () => {
          setCurrentModal(null);
          await dispatch(fetchUserData());
        }}
      />

      {primaryEmail && (
        <GroupedFields title={t("edit_email_primary_address")}>
          <EditEmail setModal={setCurrentModal} {...primaryEmail} />
        </GroupedFields>
      )}

      {secondaryEmails.length > 0 && (
        <GroupedFields title={t("edit_email_secondary_address")}>
          {secondaryEmails.map((email) => (
            <EditEmail setModal={setCurrentModal} key={email.id} {...email} />
          ))}
        </GroupedFields>
      )}
    </EditFormPage>
  );
};

export default EmailsPage;
