import * as React from "react";
import { memo } from "react";
const SvgEmptyCheckbox = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      width: 23,
      height: 23,
      x: 0.5,
      y: 0.5,
      fill: "#fff",
      stroke: "#828282",
      rx: 3.5
    }
  )
);
const Memo = memo(SvgEmptyCheckbox);
export default Memo;
