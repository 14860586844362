import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInterval } from "react-use";
import { isPresent } from "@apl-digital/utils";
import * as Sentry from "@sentry/react";

import Warning from "@/base/components/Error/Warning";
import { H4 } from "@/base/components/Global";
import Button from "@/base/components/Global/Button";
import P from "@/base/components/Global/P";
import { Row } from "@/base/components/Layout";
import Col from "@/base/components/Layout/Col";
import {
  clearSessionStorage,
  clearStorage,
  getSessionStorage,
  SessionStorageKey,
  setSessionStorage,
} from "@/base/utils/storage";
import config from "@/constants/config";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  topRow: {
    marginTop: spacing.x4l,
  },
  redirectBtn: {
    marginTop: "auto",
    marginBottom: "30%",
  },
}));

const reloadWebsite = () => {
  clearStorage(undefined, { includeUnmanaged: true });
  clearSessionStorage(
    [
      SessionStorageKey.SENTRY_REPLAY_SESSION,
      SessionStorageKey.RETRY_COUNTER_REFRESH,
    ],
    {
      mode: "keep",
      includeUnmanaged: true,
    },
  );

  window.open("/", "_self");
};

const ErrorPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [counter, setCounter] = useState(3);
  const randomIndex = useMemo(() => Math.floor(Math.random() * 10), []);

  const retryCounter = getSessionStorage(
    SessionStorageKey.RETRY_COUNTER_REFRESH,
    0,
  );

  const isAtMaxRetryCount =
    (isPresent(config.appConfig.errorPageMaxRetryCount) &&
      retryCounter >= config.appConfig.errorPageMaxRetryCount) ||
    !isPresent(config.appConfig.errorPageMaxRetryCount);

  Sentry.captureException(new Error("Showing error page"));

  useEffect(() => {
    // stop auto refresh after three tries
    if (!isAtMaxRetryCount && counter === 0) {
      setSessionStorage(
        SessionStorageKey.RETRY_COUNTER_REFRESH,
        retryCounter + 1,
      );

      reloadWebsite();
    }
  }, [retryCounter, counter]);

  useInterval(
    () => {
      setCounter(counter - 1);
    },
    !isAtMaxRetryCount && counter > 0 ? 1000 : null,
  );

  return (
    <div className={classes.container}>
      <Row className={classes.topRow}>
        <Col col={12}>
          {/* @ts-expect-error: waits for translations to accept dynamic keys */}
          <H4>{t(`error_page_msg_${randomIndex}`)}</H4>
        </Col>
        <Col col={12} alignEnd>
          {/* @ts-expect-error: waits for translations to accept dynamic keys */}
          <P>{t(`error_page_author_${randomIndex}`)}</P>
        </Col>
        <Col col={12}>
          <Warning
            message={t(
              isAtMaxRetryCount
                ? "error_page_no_retry_msg"
                : "error_page_main_msg",
            )}
          />
        </Col>
      </Row>
      <Row className={classes.redirectBtn}>
        <Col col={12}>
          <Button action={reloadWebsite}>
            {isAtMaxRetryCount
              ? t("error_page_redirect_manually_btn")
              : t("error_page_redirect_btn").replace("%d%", String(counter))}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ErrorPage;
