import type React from "react";
import { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";

import Pin from "@/base/components/GoogleMaps/Pin";
import type { GoogleMapsProps } from "@/base/components/GoogleMaps/types";
import { DEFAULT_ZOOM, getMapValues } from "@/base/utils/mapHelpers";
import config from "@/constants/config";
import { createUseStyles } from "@/theme";

type StyleProps = {
  height?: number;
};

const useStyles = createUseStyles<StyleProps>({
  mapContainer: {
    width: "100%",
    aspectRatio: ({ height }) => (height ? "unset" : 1.77),
    height: ({ height }) => height || "unset",
  },
});

const GoogleMaps: React.FC<GoogleMapsProps> = ({
  height,
  pins,
  filteredPins,
  currentBrand,
}) => {
  const classes = useStyles({ height });
  const divRef = useRef<HTMLDivElement>(null);

  const [center, setCenter] = useState<
    { lat: number; lng: number } | undefined
  >();
  const [zoom, setZoom] = useState<number | undefined>();

  useEffect(() => {
    const newValues = getMapValues(filteredPins, divRef);
    if (newValues) {
      setZoom(newValues.zoom);
      setCenter(newValues.center);
    }
  }, [filteredPins]);

  if (!center) return null;

  return (
    <div ref={divRef} className={classes.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.apiConfig.google.maps.apiKey }}
        center={center}
        zoom={zoom ?? DEFAULT_ZOOM}
      >
        {pins?.map((pin) => {
          if (currentBrand && currentBrand !== pin.brandSystemKey) return null;
          return (
            <Pin key={`map-pin-${pin.text}-${pin.lat}-${pin.lng}`} {...pin} />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMaps;
