import UserController from "@/api/controller/userController";
import { createApiGetAsyncThunk } from "@/store/utils";

export const fetchUserData = createApiGetAsyncThunk(
  "user/fetchUserData",
  UserController.getUserData,
  (state) => state.user.userData,
);

export const fetchLoyaltyPrograms = createApiGetAsyncThunk(
  "user/fetchLoyaltyPrograms",
  UserController.fetchLoyaltyPrograms,
  (state) => state.user.loyaltyPrograms,
);

export const fetchBenefits = createApiGetAsyncThunk(
  "user/fetchBenefits",
  UserController.fetchBenefits,
  (state) => state.user.benefits,
);

export const fetchTransactions = createApiGetAsyncThunk(
  "user/fetchTransactions",
  UserController.fetchTransactions,
  (state) => state.user.transactions,
);

export const fetchLatestTransactions = createApiGetAsyncThunk(
  "user/fetchLatestTransactions",
  UserController.fetchLatestTransactions,
  (state) => state.user.latestTransactions,
);
