import React, { useState } from "react";
import { InputAdornment } from "@mui/material";

import TextField, {
  type TextFieldProps,
} from "@/base/components/Form/v1/TextField";
import { Icon } from "@/base/components/Global";
import { HIDE_ICON, SHOW_ICON } from "@/constants/blobIcons";

/**
 * @deprecated - Use FormPasswordField instead
 **/
const PasswordField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  function PasswordField(props, ref) {
    const [shouldShowPassword, setShowPassword] = useState(false);

    const PasswordHideShow = () => {
      return (
        <span onClick={() => setShowPassword(!shouldShowPassword)}>
          <Icon icon={shouldShowPassword ? SHOW_ICON : HIDE_ICON} />
        </span>
      );
    };

    return (
      <TextField
        ref={ref}
        autoComplete="password"
        type={shouldShowPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <PasswordHideShow />
          </InputAdornment>
        }
        {...props}
      />
    );
  },
);

export default PasswordField;
