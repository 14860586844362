import { createUseStyles as ogCreateUseStyles } from "react-jss";
import { createTheme } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type { Styles } from "jss";

import commonTheme from "@/theme/common";

import type { BaseTheme } from "./baseTheme";

export const getTheme = (): BaseTheme => {
  return commonTheme;
};

export const createUseStyles = <Props = unknown, C extends string = string>(
  styles:
    | Styles<C, Props, BaseTheme>
    | ((theme: BaseTheme) => Styles<C, Props, undefined>),
) => ogCreateUseStyles<C, Props, BaseTheme>(styles);

const muiThemePalette = createTheme({
  palette: {
    primary: {
      main: commonTheme.color.primaryBrand,
    },
  },
});

export const muiTheme = createTheme(muiThemePalette, {
  shape: {
    borderRadius: commonTheme.borderRadius.s,
  },

  typography: {
    fontSize: commonTheme.font.size.s,
    fontFamily: commonTheme.font.family,
    fontWeightRegular: commonTheme.font.weight.s,
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: commonTheme.font.size.s,
          fontFamily: commonTheme.font.family,
          fontWeightRegular: commonTheme.font.weight.s,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: commonTheme.font.size.s,
          fontFamily: commonTheme.font.family,
        },
        input: {
          height: 24,

          paddingTop: commonTheme.spacing.m,
          paddingBottom: commonTheme.spacing.m,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: -3,
          height: 24,
          fontSize: commonTheme.font.size.s,
          fontFamily: commonTheme.font.family,
        },
        shrink: {
          top: 0,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: commonTheme.font.family,
          fontSize: commonTheme.font.size.xxs,
          fontWeight: commonTheme.font.weight.m,
        },
      },
    },

    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          overflow: "hidden",
        },
      },
    },

    MuiPickersMonth: {
      styleOverrides: {
        monthButton: {
          "&.Mui-selected": {
            color: muiThemePalette.palette.common.white,

            "&:hover": {
              backgroundColor: muiThemePalette.palette.primary.light,
            },
          },
          "&:focus": {
            "&.Mui-selected": {
              backgroundColor: muiThemePalette.palette.primary.light,
            },
          },
        },
      },
    },

    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          "&.Mui-selected": {
            color: muiThemePalette.palette.common.white,

            "&:hover": {
              backgroundColor: muiThemePalette.palette.primary.light,
            },
          },
          "&:focus": {
            "&.Mui-selected": {
              backgroundColor: muiThemePalette.palette.primary.light,
            },
          },
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: muiThemePalette.palette.common.white,

            "&:hover": {
              backgroundColor: muiThemePalette.palette.primary.light,
            },
          },
          "&:focus": {
            "&.Mui-selected": {
              backgroundColor: muiThemePalette.palette.primary.light,
            },
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: muiThemePalette.palette,
          padding: commonTheme.spacing.m,
          "&.Mui-selected": {
            backgroundColor: muiThemePalette.palette.grey["900"],
            color: commonTheme.color.white,

            "&:hover": {
              backgroundColor: muiThemePalette.palette.grey["800"],
            },

            "&:focus": {
              backgroundColor: muiThemePalette.palette.grey["800"],
            },
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        icon: {
          top: "unset",
        },
      },
    },
  },
});
