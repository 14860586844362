import { useEffect, useState } from "react";

const getMatches = (q: string): boolean => {
  // Prevents SSR issues
  if (typeof globalThis !== "undefined") {
    return globalThis.matchMedia(q).matches;
  }
  return false;
};

const useMediaQueryHook = (minWidth: number | string): boolean => {
  const query = `(min-width: ${minWidth}px)`;

  const [hasMatches, setMatches] = useState<boolean>(getMatches(query));

  const handleChange = () => setMatches(getMatches(query));

  useEffect(() => {
    const matchMedia = globalThis.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return hasMatches;
};

export default useMediaQueryHook;
