export const getUniqueKeyValues = <
  TRecord extends Record<string, unknown>,
  TKey extends keyof TRecord,
>(
  key: TKey,
  items?: TRecord[],
): TRecord[TKey][] => {
  const values = new Set<TRecord[TKey]>([]);

  if (items) {
    for (const item of items) {
      if (item[key]) {
        values.add(item[key]);
      }
    }
  }

  return [...values];
};
