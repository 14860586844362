import type React from "react";
import { useTranslation } from "react-i18next";

import EditIcon from "@/assets/svg/edit.svg?react";
import Link from "@/base/components/Global/Link";
import P from "@/base/components/Global/P";
import { Row } from "@/base/components/Layout";
import Col from "@/base/components/Layout/Col";
import config from "@/constants/config";
import { NavigationPath } from "@/constants/navigation";
import { useAppSelector } from "@/store/hooks";
import { selectPhones } from "@/store/user";

const PhoneBlock: React.FC = () => {
  const { t } = useTranslation();
  const phones = useAppSelector(selectPhones);

  const primaryPhone = phones.find((phone) => phone.isPrimary)?.number;
  const secondaryPhones = phones.filter((phone) => !phone.isPrimary);

  const phoneCount = phones.length || 0;

  return (
    <Row noMargin>
      <Col col={12} directionColumn hide={!primaryPhone}>
        <P gray3 xSmall noMargin>
          {t("primary_phone")}
        </P>
        <P noMargin gray1>
          {primaryPhone}
        </P>
      </Col>
      <Col col={12} directionColumn hide={secondaryPhones.length === 0}>
        <P gray3 xSmall noMargin>
          {t("secondary_phones")}
        </P>
        {secondaryPhones.map((e, idx) => (
          <P noMargin gray1 key={idx}>
            {e.number}
          </P>
        ))}
      </Col>
      <Col col={12} hide={!primaryPhone && secondaryPhones.length === 0}>
        <Link
          brandColor
          navigateTo={NavigationPath.Phones}
          suffixIcon={<EditIcon />}
        >
          {t("action_edit")}
        </Link>
      </Col>
      <Col col={12} hide={phoneCount >= config.appConfig.maxPhoneCount}>
        {phoneCount < 4 ? (
          <Link brandColor navigateTo={NavigationPath.AddPhone}>
            {t("add_phones")}
          </Link>
        ) : (
          <P>{t("max_phones_reached")}</P>
        )}
      </Col>
    </Row>
  );
};

export default PhoneBlock;
