const GRID_BASE = 8; // 8px is 100% for grid spacing

const commonTheme = {
  color: {
    black: "#101010", // 16
    gray1: "#333333", // 51
    gray2: "#4F4F4F", // 79
    gray3: "#828282", // 130
    gray4: "#BDBDBD", // 189
    gray5: "#E0E0E0", // 224
    gray6: "#F2F2F2", // 242
    white: "#FFFFFF", // 255
    primaryBrand: "#F68A1F",
    error: "#C62828",
    neutralGray: "#888",
    neutralMidGray: "#E1E1E1",
    success: "#51905a",
  },
  borderRadius: {
    s: 8,
    m: 16,
    l: 24,
  },
  font: {
    size: {
      x4s: 8,
      x3s: 10,
      xxs: 12,
      xs: 14,
      s: 16,
      ms: 18,
      m: 20,
      l: 22,
      xl: 24,
      xxl: 35,
      x3l: 40,
      x4l: 50,
    },
    lineHeight: {
      xxs: "12px",
      xs: "16px",
      s: "18px",
      m: "24px",
      l: "28px",
      xl: "35px",
      x2l: "48px",
      x3l: "57px",
    },
    weight: {
      xxs: 300,
      xs: 400,
      s: 500,
      m: 600,
      l: 700,
      xl: 800,
      xxl: 900,
    },
    family: '"Lato", sans-serif',
  },
  footer: {
    height: 56,
    itemHeight: 40,
  },
  spacing: {
    xxs: GRID_BASE * 0.25,
    xs: GRID_BASE * 0.5,
    s: GRID_BASE,
    m: GRID_BASE * 1.5,
    l: GRID_BASE * 2,
    xl: GRID_BASE * 3,
    xxl: GRID_BASE * 4,
    x3l: GRID_BASE * 5,
    x4l: GRID_BASE * 6,
  },
  zIndex: {
    dropdown: 2,
    stickyMenu: 3,
    modal: 10,
  },
  sizes: {
    smContainer: 430,
    mdContainer: 720,
    lgContainer: 750,
    smMinWidth: 576,
    mdMinWidth: 768,
    lgMinWidth: 992,
    xlMinWidth: 1200,
    sm: "@media screen and (min-width: 576px)",
    md: "@media screen and (min-width: 768px)",
    lg: "@media screen and (min-width: 992px)",
    xl: "@media screen and (min-width: 1200px)",
  },
  icon: {
    xl: 48,
  },
  opacity: {
    m: 0.5,
  },
  logo: {
    height: 48,
    width: 82,
  },
  card: {
    ratio: "1.715",
    minWidth: 320,
  },
};

export default commonTheme;
