import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { parseNumber, strict } from "@apl-digital/utils";
import * as Sentry from "@sentry/react";

import config from "@/constants/config";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  normalizeDepth: 10,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
      maskAllInputs: true,
    }),
    Sentry.extraErrorDataIntegration({
      captureErrorCause: true,
    }),
  ],

  tracePropagationTargets: ["localhost", config.apiConfig.loyaltyProxy.url],

  // Performance Monitoring
  tracesSampleRate: strict(
    parseNumber(import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE),
  ),
  profilesSampleRate: strict(
    parseNumber(import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE),
  ),

  // Session Replay
  replaysSessionSampleRate: strict(
    parseNumber(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
  ),
  replaysOnErrorSampleRate: strict(
    parseNumber(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
  ),
});
