import type React from "react";
import { useTranslation } from "react-i18next";

import EditIcon from "@/assets/svg/edit.svg?react";
import Link from "@/base/components/Global/Link";
import P from "@/base/components/Global/P";
import { Col, Row } from "@/base/components/Layout";
import { VerifyEmailButton } from "@/base/components/VerifyEmailButton/VerifyEmailButton";
import { isContactVerified } from "@/base/utils/userUtil";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import { useAppSelector } from "@/store/hooks";
import { selectEmails } from "@/store/user";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  email: {
    paddingRight: spacing.l,
    paddingTop: spacing.xs,
    paddingBottom: spacing.xs,

    "& span p": {
      padding: 0,
    },
  },
}));

const EmailBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emails = useAppSelector(selectEmails);

  const primaryEmail = emails.find((email) => email.isPrimary);
  const secondaryEmails = emails.filter((email) => !email.isPrimary);

  return (
    <>
      <Row noMargin>
        {primaryEmail && (
          <Col col={12} directionColumn>
            <P gray3 xSmall noMargin>
              {t("primary_email")}
            </P>
            <Row noMargin>
              <P
                noMarginTop
                gray1={isContactVerified(primaryEmail.status)}
                gray4={!isContactVerified(primaryEmail.status)}
                className={classes.email}
              >
                {primaryEmail.email}
              </P>
              {!isContactVerified(primaryEmail.status) && (
                <VerifyEmailButton
                  email={primaryEmail}
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.VERIFY_EMAIL_PROFILE
                  }
                />
              )}
            </Row>
          </Col>
        )}

        <Col col={12} directionColumn hide={secondaryEmails.length === 0}>
          <P noMargin gray3 xSmall>
            {t("secondary_emails")}
          </P>
          {secondaryEmails.map((e) => (
            <Row noMargin key={e.id}>
              <P
                noMarginTop
                gray1={isContactVerified(e.status)}
                gray4={!isContactVerified(e.status)}
                className={classes.email}
              >
                {e.email}
              </P>
              {!isContactVerified(e.status) && (
                <VerifyEmailButton
                  email={e}
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.VERIFY_EMAIL_PROFILE
                  }
                />
              )}
            </Row>
          ))}
        </Col>
        <Col col={12} hide={!primaryEmail && secondaryEmails.length === 0}>
          <Link
            brandColor
            navigateTo={NavigationPath.Emails}
            suffixIcon={<EditIcon />}
            data-testid="link-edit-email"
          >
            {t("action_edit")}
          </Link>
        </Col>
        <Col col={12}>
          {emails.length < 4 ? (
            <Link
              brandColor
              navigateTo={NavigationPath.AddEmail}
              data-testid="link-add-email"
            >
              {t("add_email")}
            </Link>
          ) : (
            <P>{t("max_emails_reached")}</P>
          )}
        </Col>
      </Row>
    </>
  );
};

export default EmailBlock;
