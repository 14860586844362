import type React from "react";

import type { IHomeLocation } from "@/api/interfaces/locationsLayout";
import { H5, Icon, Link, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { GENERIC_LOCATION_ICON, SEARCH_ICON } from "@/constants/blobIcons";
import { NavigationPath } from "@/constants/navigation";
import theme from "@/theme/common";

type LocationsBlockProps = {
  block?: IHomeLocation;
};

const LocationsBlock: React.FC<LocationsBlockProps> = ({ block }) => {
  if (!block) return null;

  const { title, description, imageURL, searchDescription } = block;
  const isSvg = imageURL?.includes(".svg");

  return (
    <>
      <Row>
        <Col noMarginBottom>
          <H5 prefixIcon={GENERIC_LOCATION_ICON}>{title}</H5>
        </Col>
      </Row>
      <Row>
        <Col col={12} colMd={8} directionColumn noMarginTop>
          <P cmsContent={description} />
          <Link
            flex
            brandColor
            navigateTo={NavigationPath.Locations}
            prefixIcon={
              <Icon icon={SEARCH_ICON} stroke={theme.color.primaryBrand} />
            }
          >
            {searchDescription}
          </Link>
        </Col>
        {isSvg && (
          <Col alignCenter col={12} colMd={4}>
            <Icon icon={imageURL} width="100%" height="100%" />
          </Col>
        )}
        {!isSvg && (
          <Col alignCenter noMarginRight col={12} colSm={4}>
            <img src={imageURL} width={343} height={343} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default LocationsBlock;
