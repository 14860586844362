import type React from "react";
import { motion, type SVGMotionProps } from "framer-motion";

import commonTheme from "@/theme/common";

import { draw } from "./common";

type SuccessProps = {
  stroke?: SVGMotionProps<unknown>["stroke"];
};

export const Success: React.FC<SuccessProps> = ({
  stroke = commonTheme.color.success,
}) => {
  return (
    <motion.svg
      width={150}
      height={150}
      fill="none"
      viewBox="0 0 150 150"
      initial="hidden"
      animate="visible"
      style={{ width: 150, height: 150 }}
    >
      <motion.circle
        cx={75}
        cy={75}
        r={68.75}
        stroke={stroke}
        strokeWidth={6}
        variants={draw}
        custom={0}
      />
      <motion.path
        d="M56.25 74.6855L69.5083 87.9438L96.0248 61.4273"
        stroke={stroke}
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={draw}
        custom={1}
      />
    </motion.svg>
  );
};
