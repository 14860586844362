import type React from "react";
import { useTranslation } from "react-i18next";

import { Button, GenericModal, H5, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { CHECK_SMALL_ICON } from "@/constants/blobIcons";
import { getTheme } from "@/theme";

import Icon from "../../../base/components/Global/Icon";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  action: () => void;
  usageBarcode: string;
};

const GiftCardAddConfirmModal: React.FC<Props> = ({
  isOpen,
  onClose,
  action,
  usageBarcode,
}) => {
  const { t } = useTranslation();
  const { color } = getTheme();

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <Col col={12}>
        <H5 gray1 noMargin>
          {t("gift_card_add_modal_title")}
        </H5>
      </Col>
      <Col col={12}>
        <P bold>
          {t("gift_card_add_modal_barcode").replace(
            "%usageBarcode%",
            usageBarcode,
          )}
        </P>
      </Col>
      <Col col={12}>
        <P>{t("gift_card_add_modal_content")}</P>
      </Col>
      <Row noMargin>
        <Col col={6}>
          <Button transparentBrand action={onClose}>
            {t("confirmation_cancel")}
          </Button>
        </Col>
        <Col col={6}>
          <Button
            action={action}
            prefixIcon={<Icon icon={CHECK_SMALL_ICON} stroke={color.white} />}
          >
            {t("gift_card_add_modal_confirm_btn")}
          </Button>
        </Col>
      </Row>
    </GenericModal>
  );
};

export default GiftCardAddConfirmModal;
