import type React from "react";

import { P } from "@/base/components/Global";
import { Col } from "@/base/components/Layout";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  groupContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xl,
    width: "100%",
  },
}));

type GroupedFieldsProps = {
  title: string;
  children: React.ReactNode;
};

const GroupedFields: React.FC<GroupedFieldsProps> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <>
      <Col col={12}>
        <P noVerticalMargin black bold>
          {title}
        </P>
      </Col>
      <div className={classes.groupContainer}>{children}</div>
    </>
  );
};

export default GroupedFields;
