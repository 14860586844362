import React from "react";
import { ReactSVG } from "react-svg";
import { isPresent } from "@apl-digital/utils";
import classnames from "classnames";

import { createUseStyles } from "@/theme";

type StyleProps = {
  stroke?: string;
  width?: number | string;
  height?: number | string;
};

const useStyles = createUseStyles<StyleProps>({
  iconContainer: {
    width: ({ width }) => width || "unset",
    height: ({ height }) => height || "unset",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
    lineHeight: 0,
  },
});

const applyStylesToElement = (
  element: Element,
  attributeName: string,
  value: string,
) => {
  let child = element.firstElementChild;
  while (child != null) {
    child.setAttribute(attributeName, value);
    if (child.hasChildNodes()) {
      applyStylesToElement(child, attributeName, value);
    }
    child = child.nextElementSibling;
  }
};

// If element has noFill id, do not apply styles.
// This is an override. Otherwise, all the parents and siblings get a fill/stroke.
const NO_FILL_ID = "NOFILL";

const applyStylesToSVG = (
  svg: SVGSVGElement,
  attributeName: string,
  value: string,
) => {
  svg.setAttribute(attributeName, value);
  let child = svg.firstElementChild;
  while (child != null) {
    child.setAttribute(attributeName, value);
    const id = child.id;
    if (child.hasChildNodes() && id !== NO_FILL_ID) {
      applyStylesToElement(child, attributeName, value);
    }
    child = child.nextElementSibling;
  }
};

type IconProps = StyleProps & {
  className?: string;
  icon?: React.ReactNode;
  stroke?: string;
  fill?: string;
  onClick?: () => void;
};

const Icon: React.FC<IconProps> = ({
  icon,
  stroke,
  fill,
  width,
  height,
  className,
  onClick,
}) => {
  const classes = useStyles({
    stroke,
    width,
    height,
  });

  if (!isPresent(icon)) {
    return null;
  }

  if (typeof icon === "string") {
    return (
      <ReactSVG
        width={width}
        height={height}
        fill={fill}
        stroke={stroke}
        beforeInjection={(svg) => {
          if (stroke) applyStylesToSVG(svg, "stroke", stroke);
          if (fill) applyStylesToSVG(svg, "fill", fill);
          if (width) svg.setAttribute("width", `${width}`);
          if (height) svg.setAttribute("height", `${height}`);
        }}
        className={classnames([classes.iconContainer, className])}
        src={icon}
        useRequestCache={true}
        onClick={onClick}
      />
    );
  }

  return (
    <span
      className={classnames([classes.iconContainer, className])}
      onClick={onClick}
    >
      {icon}
    </span>
  );
};

export default React.memo(Icon);
