import { isBefore } from "date-fns";

import {
  GiftCardStatus,
  type IGiftCard,
} from "@/api/interfaces/giftcardsLayout";

export const shouldGiftCardBeShown = (giftCard: IGiftCard) =>
  giftCard.balance > 0 &&
  giftCard.status === GiftCardStatus.ACTIVE &&
  isBefore(new Date(), new Date(giftCard.validUntil));
