import { combineSlices } from "@reduxjs/toolkit";

import CMSSlice from "./cms/slice";
import giftCardsSlice from "./giftCards/slice";
import languageSlice from "./language/slice";
import perksSlice from "./perks/slice";
import regionSlice from "./region/slice";
import userSlice from "./user/slice";

export const rootReducer = combineSlices({
  perks: perksSlice,
  cms: CMSSlice,
  user: userSlice,
  region: regionSlice,
  language: languageSlice,
  giftCards: giftCardsSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
