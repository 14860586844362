import React from "react";
import classnames from "classnames";

import Checkbox from "@/base/components/Form/v1/Checkbox";
import { Icon, Link, P, type TextStyleProps } from "@/base/components/Global";
import type { StyleProps } from "@/base/components/Global/P";
import { createUseStyles } from "@/theme";

type CheckboxRowStylesType = {
  hasError?: boolean;
  slim?: boolean;
  noBorder?: boolean;
  noMargin?: boolean;
};
const useStyles = createUseStyles<CheckboxRowStylesType>(
  ({ spacing, color }) => ({
    checkBox: {
      padding: ({ noMargin }) => (noMargin ? 0 : [spacing.l, 0]),
      display: "flex",
      flexDirection: "column",
      borderBottom: ({ noBorder }) =>
        noBorder ? "none" : `1px solid ${color.gray6}`,
      cursor: "pointer",
      width: "100%",
    },
    checkboxSlim: {
      padding: ({ noMargin }) => (noMargin ? 0 : [spacing.s, 0]),
      "&:last-child": {
        borderBottom: "none",
      },
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: ({ slim }) => (slim ? 0 : spacing.xs),
    },
    label: {
      marginRight: "auto",
      color: ({ hasError }) => (hasError ? color.error : color.black),
    },
    icon: {
      display: "flex",
      paddingLeft: spacing.l,
      alignItems: "center",
    },
    textSpacing: {
      marginTop: spacing.xs,
      marginBottom: spacing.xs,
    },
    hintText: {
      color: ({ hasError }) => (hasError ? color.error : color.gray3),
    },
  }),
);

type CheckboxRowType = TextStyleProps &
  StyleProps &
  CheckboxRowStylesType & {
    label?: string;
    disabled?: boolean;
    errorText?: string | boolean;
    hintText?: string;
    hintLink?: {
      url: string;
      text: string;
    };
    icon?: React.ReactNode;
    name?: string;
    checked?: boolean;
    defaultChecked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };

const CheckboxRow: React.FC<CheckboxRowType> = React.forwardRef<
  CheckboxRowType,
  CheckboxRowType
>(function CheckboxRow(
  {
    icon,
    label,
    hintText,
    hintLink,
    hasError,
    errorText,
    slim,
    small,
    xSmall,
    name,
    noBorder,
    bold = true,
    ...props
  }: CheckboxRowType,
  ref,
) {
  const classes = useStyles({ hasError, slim, noBorder });

  return (
    <label
      htmlFor={name}
      className={classnames([classes.checkBox, slim && classes.checkboxSlim])}
    >
      <div className={classes.labelContainer}>
        <div>
          <Checkbox
            // @ts-expect-error: ref is not a valid prop for Checkbox, hard to fix
            ref={ref}
            name={name}
            {...props}
          />
        </div>
        {!!label && (
          <P
            bold={bold}
            black
            error={hasError}
            className={classes.label}
            small={small}
            xSmall={xSmall}
          >
            {label}
          </P>
        )}
        {!!icon && <Icon className={classes.icon} icon={icon} />}
      </div>
      {!!hasError && !!errorText && (
        <P xSmall bold={bold} error className={classes.textSpacing}>
          {errorText}
        </P>
      )}
      {!!hintText && (
        <P xSmall bold={bold} className={classes.textSpacing} gray3>
          {hintText}
        </P>
      )}
      {Boolean(hintLink?.url && hintLink?.text) && (
        <Link
          small
          target="_blank"
          className={classes.textSpacing}
          brandColor
          href={hintLink?.url}
        >
          {hintLink?.text}
        </Link>
      )}
    </label>
  );
});

export default CheckboxRow;
