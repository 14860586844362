import type React from "react";
import classnames from "classnames";

import {
  TextComponent,
  type TextStyleProps,
  TextWithIcon,
} from "@/base/components/Global";
import type { MarginProps } from "@/base/components/Layout/constants";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles<MarginProps>(({ color, font, spacing }) => ({
  h2: {
    margin: 0,
    paddingTop: ({ noMargin, noVerticalMargin, noMarginTop }) =>
      noMargin || noVerticalMargin || noMarginTop ? 0 : spacing.s,
    paddingBottom: ({ noMargin, noVerticalMargin, noMarginBottom }) =>
      noMargin || noVerticalMargin || noMarginBottom ? 0 : spacing.s,
    display: "flex",
    fontSize: font.size.x3l,
    fontWeight: font.weight.xxs,
    lineHeight: font.lineHeight.x2l,
    color: color.black,
  },
}));

type H2Props = TextStyleProps &
  MarginProps & {
    children?: React.ReactNode | string;
    className?: string | number | boolean | null;
    prefixIcon?: React.ReactNode;
    suffixIcon?: React.ReactNode;
  };

const H2: React.FC<H2Props> = ({
  children,
  className,
  prefixIcon,
  suffixIcon,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <h2 className={classnames([classes.h2, className])}>
      <TextComponent {...props}>
        <TextWithIcon prefixIcon={prefixIcon} suffixIcon={suffixIcon}>
          {children}
        </TextWithIcon>
      </TextComponent>
    </h2>
  );
};

export default H2;
