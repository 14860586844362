import type React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import PerkController from "@/api/controller/perkController";
import type { IPerk } from "@/api/interfaces/perkLayouts";
import { Loader } from "@/base/components/Global";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import { NavigationPath, PerkGroupName } from "@/constants/navigation";
import PerkGroupDesktopView from "@/pages/PerkPage/components/PerkGroupDesktopView";
import PerkGroupMobileView from "@/pages/PerkPage/components/PerkGroupMobileView";
import { useAppSelector } from "@/store";
import { selectCurrentLanguage } from "@/store/language";

const PerkGroupPage: React.FC = () => {
  const navigate = useNavigate();
  const [{ isMobile, isCurrentSizeMd }] = useMediaQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [perks, setPerks] = useState<IPerk[]>([]);
  const [searchQuery] = useSearchParams();
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  useEffect(() => {
    if (searchQuery.get(PerkGroupName)) {
      void PerkController.fetchPerks().then(({ isResponseOk, response }) => {
        if (isResponseOk && response) {
          const filtered = response.filter(
            (el) => el.name === searchQuery.get(PerkGroupName),
          );
          if (filtered.length === 0) {
            navigate(NavigationPath.GiftCard); // TODO: remove when there's language independent perk group name
          } else {
            setPerks(filtered);
          }
        }
      });
    }
    setIsLoading(false);
  }, [currentLanguage]);

  if (isLoading) return <Loader isMinified />;

  if (isMobile || !isCurrentSizeMd) {
    return <PerkGroupMobileView perks={perks} />;
  }
  return <PerkGroupDesktopView perks={perks} />;
};

export default PerkGroupPage;
