import * as React from "react";
import { memo } from "react";
const SvgMenu = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 48,
    height: 48,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#fff",
      fillRule: "evenodd",
      d: "M13.94 16a1 1 0 0 0 0 2h20.13a1 1 0 1 0 0-2zm-1 8.01a1 1 0 0 1 1-1h20.13a1 1 0 1 1 0 2H13.94a1 1 0 0 1-1-1m0 6.99a1 1 0 0 1 1-1h20.13a1 1 0 1 1 0 2H13.94a1 1 0 0 1-1-1",
      clipRule: "evenodd"
    }
  )
);
const Memo = memo(SvgMenu);
export default Memo;
