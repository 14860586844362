import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import { useScrollDirection } from "react-use-scroll-direction";
import { isPresent } from "@apl-digital/utils";

import { Icon, Link, TextComponent } from "@/base/components/Global";
import { Row } from "@/base/components/Layout";
import { HEADER_HEIGHT, useSharedTopBarStyles } from "@/base/components/TopBar";
import { APOLLO_STAR_ICON, BACKWARDS_ICON } from "@/constants/blobIcons";
import config from "@/constants/config";
import {
  type INavigationPath,
  NavigationPath,
  PageTitle,
} from "@/constants/navigation";
import { useAppSelector } from "@/store";
import { selectUserData } from "@/store/user";

export type TransparentTopBarProps = {
  backPage?: INavigationPath;
  showNavigationToHome?: boolean;
};

const MobileTransparentTopBar: React.FC<TransparentTopBarProps> = ({
  backPage,
  showNavigationToHome,
}) => {
  const classes = useSharedTopBarStyles();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  // todo: search for alternative library with typescript support
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { isScrollingDown } = useScrollDirection();

  const [isScrolled, setIsScrolled] = useState(false);

  const backPageTitle: string =
    isPresent(backPage) && isPresent(PageTitle[backPage])
      ? t(`${PageTitle[backPage]}`, "navigation_back")
      : t("navigation_back");

  useEffect(() => {
    if (!isScrolled && window.scrollY >= HEADER_HEIGHT) {
      setIsScrolled(true);
    }
  }, [isScrollingDown]);

  useEffect(() => {
    if (pathname !== NavigationPath.Home) {
      setIsScrolled(false);
    }
  }, [pathname]);

  return (
    <>
      {backPage && (
        <Row className={classes.topBlock}>
          <Link flex action={() => navigate(NavigationPath[backPage])}>
            <Icon icon={BACKWARDS_ICON} className={classes.backIcon} />
            {backPageTitle}
          </Link>
        </Row>
      )}

      {showNavigationToHome && (
        <Row className={classes.topBlock}>
          <Link gray3 flex action={() => navigate(NavigationPath.Home)}>
            <Icon
              icon={APOLLO_STAR_ICON}
              className={classes.startIcon}
              width={22}
              height={22}
            />
            {t("global_title")}
          </Link>
        </Row>
      )}

      {config.routerConfig.routerMode === "profile" &&
        isAuthenticated &&
        pathname === NavigationPath.Home &&
        userData.status === "succeeded" && (
          <Row className={classes.topBlock}>
            <TextComponent gray3 classNames={classes.userName}>
              <Icon
                icon={APOLLO_STAR_ICON}
                className={classes.startIcon}
                width={22}
                height={22}
              />
              {isScrolled
                ? `${userData.firstName} ${userData.lastName}`
                : t("welcome_msg_with_name", {
                    name: `${userData.firstName} ${userData.lastName}`,
                  })}
            </TextComponent>
          </Row>
        )}
    </>
  );
};

export default MobileTransparentTopBar;
