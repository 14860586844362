import type { IAction, IImage } from "@/api/interfaces/common";
import type { SectionType } from "@/api/interfaces/pageLayout";
import type { ITransaction } from "@/api/interfaces/transactionLayout";

export enum GiftCardType {
  BONUS = "Bonus",
  GIFT_CARD = "GIFT_CARD",
  GIFT_CERTIFICATE = "GIFT_CERTIFICATE",
}

export enum GiftCardStatus {
  EXPIRED = "Expired",
  ACTIVE = "Active",
}

export type IHomeLoyaltyCards = {
  type: SectionType;
  title?: string;
  subTitle?: string;
  imageURL?: string;
  description?: string;
  actions?: IAction[];
};

export type IValidateGiftCardRequest = {
  usageBarcode: string;
  pin: string;
};

export type FetchGiftCardRequest = {
  barcode: string;
};

export type IGiftCard = {
  usageBarcode: string;
  verificationCode: string;
  type: GiftCardType;
  name: string;
  status: GiftCardStatus;
  balance: number;
  validFrom: Date;
  validUntil: Date;
  portraitImage?: IImage;
  landscapeImage?: IImage;
  transactions?: ITransaction[];
};

export type IAddGiftCardToPerson = {
  usageBarcode: string;
  verificationCode: string;
};
