import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import NewsletterController from "@/api/controller/newsletterController";
import type { INewsletter } from "@/api/interfaces/newsLetterLayout";
import type { IUpdateNewsletters } from "@/api/interfaces/userLayouts";
import AccordionItem from "@/base/components/AccordionList/AccordionItem";
import AccordionListProvider from "@/base/components/AccordionList/AccordionListProvider";
import CheckboxRow from "@/base/components/Form/v1/CheckboxRow";
import TextField from "@/base/components/Form/v1/TextField";
import { Button, H5, Link, Loader, P } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import { Col, Row } from "@/base/components/Layout";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import { useUser } from "@/base/components/UserProvider";
import { getPrimaryEmailValue } from "@/base/utils/userUtil";
import { EMAIL_ICON } from "@/constants/blobIcons";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import { findBusinessNewsletter } from "@/pages/Newsletters/helper/newslettersHelper";
import { fetchBusinessNewsletters, fetchNewslettersPage } from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { selectCurrentLanguage } from "@/store/language";
import { selectCurrentRegionPrefix, selectSortedRegions } from "@/store/region";
import { selectNewsletters, selectUserData } from "@/store/user";
import { hasData, shouldLoad } from "@/store/utils";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing, font }) => ({
  titleText: {
    fontSize: font.size.s,
    fontWeight: font.weight.m,
    lineHeight: font.lineHeight.m,
  },
  checkboxes: {
    paddingLeft: spacing.s,
    width: "100%",
  },
  formContainer: {
    width: "100%",
  },
  editEmailLink: {
    paddingBottom: spacing.s,
  },
}));

const NewslettersPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [allNewsletters, setAllNewsletters] = useState<
    Map<string, INewsletter[]>
  >(new Map());
  const userData = useAppSelector(selectUserData);
  const newsletters = useAppSelector(selectNewsletters);
  const [{ updateNewsletters }] = useUser();
  const regions = useAppSelector(selectSortedRegions);
  const currentRegionPrefix = useAppSelector(selectCurrentRegionPrefix);
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const [currentModal, setCurrentModal] = useState<"success" | "error">();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const businessNewsletters = useAppSelector(
    (state) => state.cms.businessNewsletters,
  );
  const newslettersPage = useAppSelector((state) => state.cms.newslettersPage);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<IUpdateNewsletters>({
    mode: "onChange",
  });

  const { fields, append, update, remove } = useFieldArray({
    name: "newsletters",
    control,
  });

  const onSubmit = async (data: IUpdateNewsletters) => {
    setIsSubmitting(true);
    const isUpdateSuccessful = await updateNewsletters(data.newsletters);
    setIsSubmitting(false);
    setCurrentModal(isUpdateSuccessful ? "success" : "error");
  };

  const onCheckChange = (isSelected: boolean, id: number) => {
    const existingField = fields.findIndex((el) => el.key === id);
    if (existingField === -1) {
      append({
        key: id,
        hasAccepted: true,
      });
    } else {
      update(existingField, {
        key: id,
        hasAccepted: isSelected,
      });
    }
  };

  useEffect(() => {
    void NewsletterController.fetchAllNewsletters({
      region: currentRegionPrefix,
      language: currentLanguage,
    }).then(({ isResponseOk, response: newsletters }) => {
      if (isResponseOk && newsletters) {
        const result = new Map<string, INewsletter[]>();
        if (regions) {
          for (const region of regions) {
            result.set(
              region.name,
              newsletters.filter(
                (newsletter) => newsletter.clientTerritoryId === region.id,
              ),
            );
          }
        }
        setAllNewsletters(result);
      }
    });
  }, [regions]);

  useEffect(() => {
    if (shouldLoad(businessNewsletters)) {
      void dispatch(fetchBusinessNewsletters());
    }
  }, [businessNewsletters]);

  useEffect(() => {
    if (userData) {
      remove();
      for (const newsletter of newsletters) {
        append({
          key: newsletter.id,
          hasAccepted: newsletter.hasAccepted,
        });
      }
      setShouldShowLoader(false);
    }
  }, [userData]);

  if (shouldShowLoader) {
    return <Loader isMinified />;
  }

  return (
    <CmsPage
      pageStateMachine={newslettersPage}
      fetchPage={() => dispatch(fetchNewslettersPage())}
    >
      {({ page }) => (
        <Row>
          <Col directionColumn>
            <Col directionColumn noHorizontalMargin>
              <H5 gray1 prefixIcon={EMAIL_ICON}>
                {page.title}
              </H5>
              <P gray3 small noMarginTop>
                {page.subTitle}
              </P>
            </Col>
            <Col hide={!page.description} noHorizontalMargin>
              <P gray1 cmsContent={page.description} />
            </Col>
            <Col col={12} directionColumn fullWidth noHorizontalMargin>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.formContainer}
              >
                <Col directionColumn fullWidth noMarginRight>
                  <AccordionListProvider>
                    {[...allNewsletters.entries()].map(([key, value], idx) => (
                      <div key={key} className={classes.formContainer}>
                        <AccordionItem
                          id={`newsletter-${key}`}
                          slim
                          title={key}
                          titleClassname={classes.titleText}
                          noBorder
                          isActiveByDefault={idx === 0}
                        >
                          <div className={classes.checkboxes}>
                            {value.map((newsletter) => {
                              const businessNewsletter = findBusinessNewsletter(
                                newsletter.externalIdentifier,
                                hasData(businessNewsletters)
                                  ? businessNewsletters.array
                                  : [],
                              );
                              return (
                                <div key={newsletter.id}>
                                  <CheckboxRow
                                    label={
                                      businessNewsletter?.name ??
                                      newsletter.name
                                    }
                                    noBorder
                                    checked={fields.some(
                                      (el) =>
                                        el.key === newsletter.id &&
                                        el.hasAccepted,
                                    )}
                                    onChange={(e) =>
                                      onCheckChange(
                                        e.target.checked,
                                        newsletter.id,
                                      )
                                    }
                                    slim
                                    icon={businessNewsletter?.icon}
                                    hintText={businessNewsletter?.description}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </AccordionItem>
                      </div>
                    ))}
                  </AccordionListProvider>
                </Col>
                <Col col={12} colMd={6} noHorizontalMargin>
                  <Col noHorizontalMargin noMarginBottom>
                    <TextField
                      isDisabled
                      label={t("apollo_club_member_email")}
                      value={
                        userData.status === "succeeded"
                          ? getPrimaryEmailValue(userData)
                          : undefined
                      }
                    />
                  </Col>
                </Col>
                <Col col={12} noMarginTop noHorizontalMargin>
                  <P gray3>{t("newsletters_your_primary_email_description")}</P>
                </Col>
                <Col col={12} noHorizontalMargin>
                  <Link
                    brandColor
                    navigateTo={NavigationPath.Emails}
                    className={classes.editEmailLink}
                  >
                    {t("newsletters_edit_email_link")}
                  </Link>
                </Col>
                <Col col={12} colMd={6} noHorizontalMargin>
                  <Col noHorizontalMargin col={12}>
                    <Button
                      type="submit"
                      id="submit-btn"
                      isLoading={isSubmitting}
                      isDisabled={!isDirty}
                      analyticsAttributes={
                        GOOGLE_ANALYTICS_ATTRIBUTES.NEWSLETTERS_CHANGE
                      }
                    >
                      {t("save_preferences_btn")}
                    </Button>
                  </Col>
                  <SimpleModalWrapper
                    isOpen={currentModal === "success"}
                    onClose={() => setCurrentModal(undefined)}
                    title={t("newsletters_success_update_title")}
                    subTitle={t("newsletters_success_update_subtitle")}
                    content={t("newsletters_success_update_content")}
                    analyticsAttributes={
                      GOOGLE_ANALYTICS_ATTRIBUTES.NEWSLETTERS_CHANGE_SUCCESS
                    }
                  />
                  <SimpleModalWrapper
                    isOpen={currentModal === "error"}
                    onClose={() => {
                      setCurrentModal(undefined);
                    }}
                    title={t("newsletters_error_update_title")}
                    subTitle={t("newsletters_error_update_subtitle")}
                    content={t("newsletters_error_update_content")}
                    analyticsAttributes={
                      GOOGLE_ANALYTICS_ATTRIBUTES.NEWSLETTERS_CHANGE_DECLINE
                    }
                  />
                </Col>
              </form>
            </Col>
          </Col>
        </Row>
      )}
    </CmsPage>
  );
};

export default NewslettersPage;
