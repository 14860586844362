import { isPresent } from "@apl-digital/utils";
import { User } from "oidc-client-ts";

import {
  getGenericAPIError,
  getResponse,
  SUCCESS_STATUSES,
} from "@/api/helpers";
import type {
  FetchApiOptions,
  FetchRequestOptions,
  FetchWithErrorsOptions,
  RequestResponse,
} from "@/api/types";
import { ContentType, RequestHeader, RequestMethod } from "@/api/types";
import { getSessionStorage, SessionStorageKey } from "@/base/utils/storage";
import config from "@/constants/config";

export const getToken = (): string | null => {
  const oidcStorageValue = sessionStorage.getItem(
    `${config.computed.authKeyPrefix}user`,
  );

  if (oidcStorageValue) {
    const oidcUser = User.fromStorageString(oidcStorageValue);
    return oidcUser.access_token;
  }

  return null;
};

const getApiPath = (api: string): string =>
  `${config.apiConfig.loyaltyProxy.url}${api}`;

const fetchApi = async <TSuccess, TError>({
  signal,
  language = getSessionStorage(SessionStorageKey.LANGUAGE_CURRENT),
  region = getSessionStorage(SessionStorageKey.REGION_CURRENT),
  ...options
}: FetchApiOptions): RequestResponse<TSuccess, TError> => {
  const { method, api, shouldParseResponse, body, headers } = options;

  const isFormData = body instanceof FormData;

  const apiPath = getApiPath(api);

  const requestHeaders = new Headers(headers);

  const token = getToken();

  if (isPresent(token)) {
    requestHeaders.append("Authorization", `Bearer ${token}`);
  }

  if (isPresent(language)) {
    requestHeaders.append("Accept-Language", language);
  }

  if (isPresent(region)) {
    requestHeaders.append("region", region);
  }

  if (!isFormData) {
    requestHeaders.append(
      RequestHeader.CONTENT_TYPE,
      ContentType.APPLICATION_JSON,
    );
  }

  const response = await fetch(apiPath, {
    signal,
    credentials: "include",
    method,
    headers: requestHeaders,
    ...([RequestMethod.POST, RequestMethod.PUT].includes(method) && {
      body: isFormData ? body : JSON.stringify(body),
    }),
  });

  let responseData = null;
  if (!SUCCESS_STATUSES.includes(response.status)) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      responseData = await response.json();
      return getGenericAPIError(
        { ...options, language, region },
        response,
        "Error parsing response",
        responseData,
      );
    } catch {
      return getGenericAPIError(
        { ...options, language, region },
        response,
        "Error parsing response",
      );
    }
  }

  if (shouldParseResponse) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      responseData = await response.json();
    } catch {
      return getGenericAPIError(
        { ...options, language, region },
        response,
        "Error parsing response",
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return getResponse<TSuccess>(responseData, response);
};

const fetchWithErrors = async <TSuccess, TError>({
  shouldParseResponse = true,
  canFail = false,
  ...options
}: FetchWithErrorsOptions): RequestResponse<TSuccess, TError> => {
  let signal = options.signal;

  if (!isPresent(options.signal)) {
    const abortController = new AbortController();

    setTimeout(() => abortController.abort(), 10_000);
    signal = abortController.signal;
  }

  const response = await fetchApi<TSuccess, TError>({
    ...options,
    signal,
    shouldParseResponse,
  });

  if (canFail || response.isResponseOk) {
    return response;
  }

  return response;
};

export const request = async <TSuccess, TError = void>(
  options: FetchRequestOptions,
): RequestResponse<TSuccess, TError> =>
  fetchWithErrors({ ...options, method: RequestMethod.GET });

export const post = async <TSuccess, TError = void>(
  options: FetchRequestOptions,
): RequestResponse<TSuccess, TError> =>
  fetchWithErrors({
    ...options,
    method: RequestMethod.POST,
  });

export const put = async <TSuccess, TError = void>(
  options: FetchRequestOptions,
): RequestResponse<TSuccess, TError> =>
  fetchWithErrors({
    ...options,
    method: RequestMethod.PUT,
  });

export const deleteRequest = async <TSuccess, TError = void>(
  options: FetchRequestOptions,
): RequestResponse<TSuccess, TError> =>
  fetchWithErrors({
    ...options,
    method: RequestMethod.DELETE,
  });
