import { useController, useFormContext } from "react-hook-form";
import { isPresent } from "@apl-digital/utils";

import TextField, {
  type TextFieldProps,
} from "@/base/components/Form/v1/TextField";

type TextFieldWithControllerProps = {
  name: string;
  errorText?: string;
} & Omit<TextFieldProps, "helperText" | "hasError">;

const TextFieldWithController = ({
  name,
  errorText,
  ...restProps
}: TextFieldWithControllerProps) => {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const hasError = isPresent(error);

  return (
    <TextField
      {...field}
      {...restProps}
      hasError={hasError}
      helperText={hasError ? errorText : undefined}
      onChange={(e) => {
        field.onChange(e);
        if (restProps.onChange) {
          restProps.onChange(e);
        }
      }}
    />
  );
};

export default TextFieldWithController;
