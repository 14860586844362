import type React from "react";
import classnames from "classnames";

import { Icon } from "@/base/components/Global";
import type { StyleProps } from "@/base/components/Global/P";
import { createUseStyles, getTheme } from "@/theme";

const useStyles = createUseStyles<StyleProps>(({ spacing }) => ({
  suffixIcon: {
    marginLeft: spacing.s,
  },
}));

type SuffixIconProps = {
  className?: string;
  icon?: React.ReactNode;
  isActive?: boolean;
};

const SuffixIcon: React.FC<SuffixIconProps> = ({
  icon,
  className,
  isActive = false,
}) => {
  const classes = useStyles();
  const { color } = getTheme();

  return (
    <Icon
      icon={icon}
      className={classnames([classes.suffixIcon, className])}
      stroke={isActive ? color.primaryBrand : ""}
    />
  );
};

export default SuffixIcon;
