import type React from "react";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import * as yup from "yup";

import { request } from "@/api";
import { validation } from "@/base/components/Form/v1/helpers";
import type { Field, Form } from "@/base/components/FormProvider/types";

type FormState = {
  schema?: yup.AnyObjectSchema | null;
  json?: Form | null;
};
type FormActions = {
  getField: ((fieldName: string) => Field | undefined) | (() => void);
};

type FormProviderProps = {
  children: ReactNode;
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSchema?: Record<string, any> | null;
};

type FormContextProps = [FormState, FormActions];

const FormContext = createContext<FormContextProps>([
  {
    schema: undefined,
    json: undefined,
  },
  {
    getField: () => {
      return;
    },
  },
]);

const useFormProvider = () => useContext(FormContext);

const FormProvider: React.FC<FormProviderProps> = ({
  url,
  children,
  defaultSchema,
}) => {
  const [json, setJson] = useState<Form | null>(null);
  const [schema, setSchema] = useState<yup.AnyObjectSchema | undefined | null>(
    yup.object(defaultSchema || {}),
  );

  useEffect(() => {
    void request<Form>({
      api: url,
      canFail: true,
    }).then(({ isResponseOk, response }) => {
      if (isResponseOk && response) {
        setJson(response);
      }
    });
  }, []);

  useEffect(() => {
    if (json) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newSchema: Record<string, any> = {};
      for (const field of json.fields) {
        if (field.name && field.validation) {
          newSchema[field.name] = validation.jsonValidation(field.validation);
        }
      }

      const newSchemaValue = yup
        .object(defaultSchema ? { ...defaultSchema, ...newSchema } : newSchema)
        .required();

      if (Object.keys(newSchemaValue).length > 0) {
        setSchema(newSchemaValue);
      }
    }
  }, [json]);

  const getField = (fieldName: string): Field | undefined => {
    if (json) return json.fields.find((field) => field.name === fieldName);
  };

  return (
    <FormContext.Provider
      value={[
        {
          schema,
          json,
        },
        {
          getField,
        },
      ]}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
export { useFormProvider };

export { default as FormSize } from "@/base/components/FormProvider/FormSize";
