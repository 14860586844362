const formatMoney = (
  input: number,
  region: string,
  withEuro = false,
): string => {
  return new Intl.NumberFormat(region, {
    style: withEuro ? "currency" : "decimal",
    currency: withEuro ? "EUR" : undefined,
  }).format(input);
};

const formatTime = (input: Date): string => {
  return input.toLocaleTimeString("ee-ET", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

const formatDate = (
  input: string | Date,
  withTime: boolean,
  separator: string,
): string => {
  const date = new Date(input);

  return date
    .toLocaleDateString("et-EE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split(".")
    .join(separator)
    .concat(withTime ? ` ${formatTime(date)}` : "");
};

export { formatDate, formatMoney };
