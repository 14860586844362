import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import VerificationCodeController from "@/api/controller/verificationCodeController";
import {
  VerificationAction,
  VerificationCodeStatus,
} from "@/api/interfaces/verificationCodeLayout";
import Warning from "@/base/components/Error/Warning";
import { validation } from "@/base/components/Form/v1/helpers";
import TextField from "@/base/components/Form/v1/TextField";
import { Button, H4, P } from "@/base/components/Global";
import { Col, Row, RowBreak } from "@/base/components/Layout";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import { useUser } from "@/base/components/UserProvider";
import {
  Action,
  ErrorType,
  NavigationPath,
  VerificationCode,
} from "@/constants/navigation";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  form: {
    width: "100%",
    paddingTop: spacing.x4l,
  },
  warning: {
    margin: [spacing.x4l, 0],
  },
}));

type FormType = {
  email: string;
};

const validationSchema = yup
  .object({
    email: validation.email,
  })
  .required();

const VerificationCodeErrorPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [verificationAction, setVerificationAction] =
    useState<VerificationAction>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentModal, setCurrentModal] = useState<"success" | "error">();
  const isExpirationError = useMemo(
    () => searchParams.get(ErrorType) === VerificationCodeStatus.EXPIRED,
    [searchParams],
  );
  const canGetNewVerificationCode = useMemo(
    () =>
      isExpirationError &&
      verificationAction === VerificationAction.REGISTRATION,
    [isExpirationError, verificationAction],
  );
  const navigate = useNavigate();
  const [{ logIn }] = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<FormType>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const searchAction = searchParams.get(Action);
    if (searchAction) {
      setVerificationAction(searchAction as VerificationAction);
    }
  }, []);

  const hasError = (inputName: keyof FormType) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  const onSubmit = async (data: FormType) => {
    const verificationCode = searchParams.get(VerificationCode);
    if (!verificationAction || !data.email || !verificationCode) {
      return;
    }
    setIsSubmitting(true);
    const requestNewRegistrationCodeCall = async () => {
      const { isResponseOk } =
        await VerificationCodeController.requestNewRegistrationCode({
          body: { code: verificationCode, email: data.email },
        });
      setIsSubmitting(false);
      setCurrentModal(isResponseOk ? "success" : "error");
    };

    await requestNewRegistrationCodeCall().catch((error) =>
      console.error(error),
    );
  };

  const navigateToPrevAction = () => {
    switch (verificationAction) {
      case VerificationAction.PASSWORD_RESET:
        navigate(NavigationPath.ResetPassword);
        break;
      case VerificationAction.CLOSE_ACCOUNT:
        navigate(NavigationPath.CloseAccount);
        break;
      case VerificationAction.VERIFY_EMAIL:
        navigate(NavigationPath.Profile);
        break;
      default:
        navigate(NavigationPath.Home);
    }
  };

  return (
    <>
      <SimpleModalWrapper
        isOpen={currentModal === "success"}
        title={t("verification_code_modal_success_title")}
        content={t("verification_code_modal_success_content")}
        onClose={() => navigate(NavigationPath.Home)}
      />
      <SimpleModalWrapper
        isOpen={currentModal === "error"}
        title={t("verification_code_modal_error_title")}
        content={t("verification_code_modal_error_content")}
        onClose={() => setCurrentModal(undefined)}
      />
      <Row>
        <Col directionColumn alignCenter fullWidth>
          <Col noMargin fullWidth>
            <H4 gray1>{t("verification_page_title")}</H4>
          </Col>
          <Col noMargin fullWidth>
            <Warning
              classNames={classes.warning}
              message={t(
                isExpirationError
                  ? "verification_page_warning_expired"
                  : "verification_page_warning_invalid",
              )}
            />
          </Col>
          <Col noMargin fullWidth directionColumn>
            <P bold>
              {t(
                isExpirationError
                  ? "verification_page_reason_expired_title"
                  : "verification_page_reason_invalid_title",
              )}
            </P>
            <P>
              {t(
                isExpirationError
                  ? "verification_page_reason_expired_content"
                  : "verification_page_reason_invalid_content",
              )}
            </P>
          </Col>
          {canGetNewVerificationCode && (
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <Col col={12} colMd={9} fullWidth noHorizontalMargin>
                <TextField
                  label={t("field_enter_email_label")}
                  id="email-input-bar"
                  autoComplete="email"
                  type="email"
                  submitOnEnter
                  placeholder={t("field_email_placeholder")}
                  hasError={hasError("email")}
                  helperText={hasError("email") && t("field_email_error")}
                  {...register("email")}
                />
              </Col>
              <Col col={12} colMd={9} fullWidth noHorizontalMargin>
                <Button isLoading={isSubmitting} type="submit">
                  {t("verification_page_submit_button")}
                </Button>
              </Col>
            </form>
          )}
          {!canGetNewVerificationCode && (
            <>
              <RowBreak />
              <Col fullWidth noHorizontalMargin directionColumn alignEnd>
                <Col col={12} colMd={5} noHorizontalMargin fullWidth>
                  <Button action={navigateToPrevAction}>
                    {t("verification_page_start_again_button")}
                  </Button>
                </Col>
                {verificationAction === VerificationAction.PASSWORD_RESET && (
                  <>
                    <Col
                      col={12}
                      colMd={5}
                      noHorizontalMargin
                      alignCenter
                      fullWidth
                    >
                      <P xSmall bold>
                        {t("verification_page_sign_in_text")}
                      </P>
                    </Col>
                    <Col fullWidth noHorizontalMargin col={12} colMd={5}>
                      <Button transparentBrand action={logIn}>
                        {t("sign_in_button")}
                      </Button>
                    </Col>
                  </>
                )}
              </Col>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default VerificationCodeErrorPage;
