import type React from "react";

import { PrefixIcon, SuffixIcon } from "@/base/components/Global";

type TextWithIconProps = {
  children?: React.ReactNode;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  isActive?: boolean;
};

const TextWithIcon: React.FC<TextWithIconProps> = ({
  children,
  prefixIcon,
  suffixIcon,
  isActive = false,
}) => (
  <>
    <PrefixIcon icon={prefixIcon} isActive={isActive} />
    {children}
    <SuffixIcon icon={suffixIcon} isActive={isActive} />
  </>
);

export default TextWithIcon;
