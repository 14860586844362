import type React from "react";
import classnames from "classnames";

import { VisibilityBlock } from "@/base/components/Layout";
import {
  BREAK_ROW,
  BREAK_ROW_MOBILE,
  VERTICAL_GUTTER,
} from "@/base/components/Layout/constants";
import type { VisibilityProps } from "@/base/components/Layout/VisibilityBlock";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ sizes }) => ({
  rowBreak: {
    width: "100%",
    height: BREAK_ROW_MOBILE - VERTICAL_GUTTER,
    display: "flex",

    [sizes.md]: {
      height: BREAK_ROW - VERTICAL_GUTTER,
    },
  },
}));

type RowBreakProps = VisibilityProps & {
  className?: string | number | boolean | null;
};

const RowBreak: React.FC<RowBreakProps> = ({
  className,
  hide,
  belowSm,
  belowMd,
  belowLg,
  aboveSm,
  aboveMd,
  aboveLg,
  aboveXl,
}) => {
  const classes = useStyles();

  return (
    <VisibilityBlock
      hide={hide}
      belowSm={belowSm}
      belowMd={belowMd}
      belowLg={belowLg}
      aboveSm={aboveSm}
      aboveMd={aboveMd}
      aboveLg={aboveLg}
      aboveXl={aboveXl}
    >
      <div className={classnames([classes.rowBreak, className])} />
    </VisibilityBlock>
  );
};

export default RowBreak;
