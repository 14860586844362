import { initReactI18next } from "react-i18next";
import { isPresent } from "@apl-digital/utils";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";

import translations from "@/assets/locales/dev/translations.json";
import zodTranslations from "@/assets/locales/dev/zod.json";
import { getSessionStorage, SessionStorageKey } from "@/base/utils/storage";
import config from "@/constants/config";
import { getDomainLanguage } from "@/store/language";

const currentLanguage = isPresent(
  config.assetConfig.translations.overrideI18nLanguage,
)
  ? config.assetConfig.translations.overrideI18nLanguage
  : getSessionStorage(
      SessionStorageKey.LANGUAGE_CURRENT,
      getDomainLanguage() ?? undefined,
    );

export const defaultNS = "translations";
export const resources = {
  dev: {
    translations,
    zod: zodTranslations,
  },
} as const;

void i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: `${config.assetConfig.translations.url}/{{lng}}/{{ns}}.json`,
      crossDomain: true,
      allowMultiLoading: true,
    },
    ns: Object.keys(resources.dev),
    defaultNS,
    lng: currentLanguage,
    fallbackLng: "en",
    returnEmptyString: process.env.NODE_ENV !== "development",
    debug: config.devFlags.translationsDebug,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    partialBundledLanguages: true,
    resources: process.env.NODE_ENV === "development" ? resources : undefined,
  });

z.setErrorMap(zodI18nMap);

export { default } from "i18next";
