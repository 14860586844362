import type React from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@/assets/svg/close-sm.svg?react";
import { Button, GenericModal, H5, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import type { GenericModalProps } from "@/base/components/Modal/GenericModal";
import type { AnalyticsAttributes } from "@/constants/googleAnalytics";

type SuccessModalProps = GenericModalProps & {
  title?: string;
  subTitle?: string;
  content?: string;
  isClose?: boolean;
  buttonFullWidth?: boolean;
  buttonTransparent?: boolean;
  analyticsAttributes?: AnalyticsAttributes;
};

function getColWidth(isClose: boolean, isFullWidth: boolean): number {
  if (isFullWidth) return 12;
  return isClose ? 5 : 3;
}

// Modal with one action
const SimpleModalWrapper: React.FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  title,
  subTitle,
  content,
  isClose = false,
  buttonFullWidth = true,
  buttonTransparent = false,
  analyticsAttributes,
}) => {
  const { t } = useTranslation();

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      analyticsAttributes={analyticsAttributes}
    >
      {!!title && (
        <Col col={12}>
          <H5 gray1 noMargin>
            {title}
          </H5>
        </Col>
      )}
      {!!subTitle && (
        <Col col={12} noVerticalMargin>
          <P bold>{subTitle}</P>
        </Col>
      )}
      {!!content && (
        <Col noVerticalMargin>
          <P>{content}</P>
        </Col>
      )}
      <Row noMargin>
        <Col col={12} alignEnd={!buttonFullWidth}>
          <Col col={getColWidth(isClose, buttonFullWidth)} noMargin>
            <Button
              action={onClose}
              transparentBrand={isClose || buttonTransparent}
              prefixIcon={isClose && <CloseIcon />}
              data-testid="modal-primary-button"
            >
              {isClose ? t("confirmation_close") : t("confirmation_ok")}
            </Button>
          </Col>
        </Col>
      </Row>
    </GenericModal>
  );
};

export default SimpleModalWrapper;
