import { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";

import { Icon } from "@/base/components/Global";
import { HIDE_ICON, SHOW_ICON } from "@/constants/blobIcons";

import { FormTextField, type TextFieldProps } from "./FormTextField";

export const FormPasswordField: React.FC<TextFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormTextField
      autoComplete="password"
      type={showPassword ? "text" : "password"}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label={
              showPassword ? "hide the password" : "display the password"
            }
            onClick={handleClickShowPassword}
            edge="end"
          >
            <Icon icon={showPassword ? SHOW_ICON : HIDE_ICON} />
          </IconButton>
        </InputAdornment>
      }
      {...props}
    />
  );
};
