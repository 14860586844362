import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { DEFAULT_TERRITORY_ID } from "@/api/constants";
import type { IRegion } from "@/api/interfaces/countriesLayout";
import type { IMyCollectionInfo } from "@/api/interfaces/myCollectionInfoLayout";
import { SectionType } from "@/api/interfaces/pageLayout";
import { BenefitType } from "@/api/interfaces/userBenefitLayout";
import { H5, Link, Loader, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { getBusinessByExtID } from "@/base/utils/businessHelper";
import { isRegionEnabled, RegionId } from "@/base/utils/featureFlagUtil";
import { APOLLO_STAR_ICON } from "@/constants/blobIcons";
import BrandProgressBar from "@/pages/GiftCardPage/components/BrandProgressBar";
import { sortBenefits } from "@/pages/HomePage/components/helper/benefitHelper";
import { fetchBusinesses } from "@/store/cms";
import {
  fetchGiftCards,
  selectBonusTypeGiftCardsCount,
  selectGiftCards,
} from "@/store/giftCards";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { selectGroupedPerks } from "@/store/perks";
import { selectSortedRegions } from "@/store/region";
import { fetchBenefits } from "@/store/user";
import { hasData, shouldLoad } from "@/store/utils";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ color, font, spacing }) => ({
  territories: {
    display: "flex",
    fontWeight: font.weight.m,
    width: "100%",
    color: color.gray2,
    borderBottom: `1px solid ${color.gray5}`,
    paddingBottom: spacing.s,
    "& .isActive": {
      "&:before": {
        position: "absolute",
        content: "''",
        bottom: -9,
        left: 0,
        width: "100%",
        height: 4,
        backgroundColor: color.primaryBrand,
      },
    },
    "& a": {
      padding: [spacing.s, spacing.s],
      position: "relative",
    },
  },
  link: {
    width: "100%",
    textAlign: "center",
  },
  myCollectionsWrapper: {
    flexDirection: "column",
    rowGap: spacing.xl,
  },
  benefits: {
    rowGap: spacing.l + spacing.xs,
  },
}));

type Props = {
  block: IMyCollectionInfo;
};

const MyCollectionBlock: React.FC<Props> = ({ block }) => {
  const classes = useStyles();
  const benefits = useAppSelector((state) => state.user.benefits);
  const businesses = useAppSelector((state) => state.cms.businesses);
  const regions = useAppSelector(selectSortedRegions);
  const giftCards = useAppSelector(selectGiftCards);
  const [activeRegion, setActiveRegion] = useState<IRegion>();
  const [activeDescription, setActiveDescription] = useState<string>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const groupedPerks = useAppSelector(selectGroupedPerks);
  const bonusGiftCardsCount = useAppSelector(selectBonusTypeGiftCardsCount);

  useEffect(() => {
    if (regions) {
      setActiveRegion(regions[0]);
    }
  }, [regions]);

  useEffect(() => {
    if (shouldLoad(benefits)) {
      void dispatch(fetchBenefits());
    }

    if (shouldLoad(businesses)) {
      void dispatch(fetchBusinesses());
    }

    if (shouldLoad(giftCards)) {
      void dispatch(fetchGiftCards());
    }
  }, [benefits, businesses, giftCards]);

  useEffect(() => {
    if (activeRegion) {
      const description = block.descriptions.find(
        (d) => d.region === activeRegion.prefix,
      );
      setActiveDescription(description?.content);
    }
  }, [block, activeRegion]);

  return (
    <Row className={classes.myCollectionsWrapper}>
      <Col col={12} directionColumn noVerticalMargin>
        <H5
          prefixIcon={APOLLO_STAR_ICON}
          gray1
          noMarginBottom={!!block.subTitle}
        >
          {block.title}
        </H5>
        {block.subTitle && (
          <P gray3 small noMarginBottom>
            {block.subTitle}
          </P>
        )}
      </Col>
      <Col col={12} className={classes.territories} noVerticalMargin>
        {regions?.map((region) => (
          <Col col={12 / regions.length} alignCenter noMargin key={region.id}>
            <Link
              gray2
              block
              className={classNames(
                classes.link,
                activeRegion?.id === region.id ? "isActive" : "",
              )}
              action={() => setActiveRegion(region)}
            >
              {region.name}
            </Link>
          </Col>
        ))}
      </Col>
      <Col
        col={12}
        noVerticalMargin
        directionColumn
        className={classes.benefits}
      >
        {benefits.status === "pending" && <Loader showLoader isMinified />}
        {hasData(benefits) &&
          benefits.array
            .filter(
              (benefit) =>
                benefit.territoryId === activeRegion?.id ||
                (benefit.territoryId === DEFAULT_TERRITORY_ID &&
                  activeRegion &&
                  isRegionEnabled(activeRegion.id)),
            )
            .sort(sortBenefits)
            .map((benefit) => {
              const business =
                hasData(businesses) &&
                getBusinessByExtID(
                  businesses.array,
                  benefit.externalIdentifier,
                );

              if (!business) return;

              let vouchersCount =
                groupedPerks?.find((perk) => benefit.name === perk.name)
                  ?.count || 0;

              if (benefit.externalIdentifier.endsWith(SectionType.BKK5)) {
                if (activeRegion?.id !== RegionId.ESTONIAN_REGION_ID) return; // TODO: remove when BKK5 enabled for other regions

                vouchersCount += bonusGiftCardsCount;
              }
              return (
                <BrandProgressBar
                  key={benefit.externalIdentifier + activeRegion?.name}
                  brandColor={business.primaryColor}
                  brandLogoURL={business.logoURL}
                  brandIconURL={business.iconURL}
                  total={benefit.targetValue}
                  completed={benefit.currentValue}
                  message={t("start_collecting")}
                  isMoneyBased={benefit.type === BenefitType.MONEY_BASED}
                  vouchersCount={vouchersCount}
                />
              );
            })}
      </Col>
      <Col col={12} hide={!activeDescription} noVerticalMargin>
        <Row noMargin>
          <P cmsContent={activeDescription} />
        </Row>
      </Col>
    </Row>
  );
};

export default MyCollectionBlock;
