import type React from "react";
import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useClickAway } from "react-use";
import { zodResolver } from "@hookform/resolvers/zod";
import { InputAdornment } from "@mui/material";
import classNames from "classnames";
import { z } from "zod";

import { ContactStatus, type IEmail } from "@/api/interfaces/userLayouts";
import CheckboxRow from "@/base/components/Form/v1/CheckboxRow";
import TextFieldWithController from "@/base/components/Form/v1/Controller/TextFieldWithController";
import { Button, P } from "@/base/components/Global";
import { Col } from "@/base/components/Layout";
import Row from "@/base/components/Layout/Row";
import ConfirmationModal from "@/base/components/Modal/ConfirmationModal";
import { useUser } from "@/base/components/UserProvider";
import { VerifyEmailButton } from "@/base/components/VerifyEmailButton/VerifyEmailButton";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing, color }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  notVerifiedField: {
    "& .MuiInputBase-input": {
      color: `${color.gray3} !important`,
    },
    "& .MuiFormHelperText-root": {
      color: `${color.gray4} !important`,
    },
  },
}));

type EditEmailProps = {
  setModal: (modal: string | null) => void;
} & IEmail;

const EmailSchema = z
  .object({
    currentEmail: z.string().email(),
    confirmEmail: z.string(),
  })
  .refine(({ currentEmail, confirmEmail }) =>
    confirmEmail ? currentEmail === confirmEmail : true,
  );

type EmailData = z.infer<typeof EmailSchema>;

const EditEmail: React.FC<EditEmailProps> = ({
  id,
  email,
  status,
  isPrimary,
  setModal,
}) => {
  const methods = useForm({
    defaultValues: {
      currentEmail: email,
      confirmEmail: "",
    },
    mode: "onSubmit",
    resolver: zodResolver(EmailSchema),
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid },
  } = methods;
  const { t } = useTranslation();
  const classes = useStyles();
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [shouldShowDeleteModal, setShowDeleteModal] = useState(false);
  const [updateToBePrimary, setUpdateToBePrimary] = useState(false);

  const [{ removeEmail, updateEmail, setPrimaryEmail }] = useUser();

  const onSubmit = async ({ currentEmail }: EmailData) => {
    const newValue: IEmail = {
      id,
      email: currentEmail,
      isPrimary: updateToBePrimary,
    };
    const isSuccess = await updateEmail(newValue);
    const isSuccessPrimary = updateToBePrimary
      ? await setPrimaryEmail(id)
      : true;
    setIsActive(false);
    if (isSuccess && isSuccessPrimary) {
      setModal(updateToBePrimary ? "primarySuccessModal" : "editSuccessModal");
    } else {
      setModal("errorModal");
    }
  };

  const deleteEmail = async () => {
    const isSuccess = await removeEmail(id);
    if (isSuccess) {
      setModal("removeSuccessModal");
    } else {
      setModal("removeErrorModal");
    }
  };

  useClickAway(inputRef, () => {
    setIsActive(false);
  });

  const shouldShowControls =
    !isPrimary && (isActive || isDirty || updateToBePrimary);

  const isVerified = status === ContactStatus.ACTIVE;

  const canVerifyEmail = !isVerified && !isDirty && isValid;

  const endAdornment = canVerifyEmail ? (
    <InputAdornment position="end">
      <VerifyEmailButton
        email={{
          id,
          email,
          status,
          isPrimary,
        }}
        analyticsAttributes={
          GOOGLE_ANALYTICS_ATTRIBUTES.VERIFY_EMAIL_EMAILS_LIST
        }
      />
    </InputAdornment>
  ) : null;

  return (
    <FormProvider {...methods}>
      <ConfirmationModal
        isOpen={shouldShowDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t("email_delete_confirmation_title")}
        content={t("email_delete_confirmation_content")}
        primaryAction={deleteEmail}
        primaryTitle={t("email_delete_button")}
      />
      <div
        onClick={() => setIsActive(true)}
        ref={inputRef}
        className={classes.container}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <TextFieldWithController
              label={
                isPrimary
                  ? t("field_edit_primary_email_label")
                  : t("field_edit_secondary_email_label")
              }
              id="email-input"
              autoComplete="email"
              isDisabled={isPrimary}
              isReadOnly={isVerified}
              placeholder={t("field_email_placeholder")}
              errorText={t("email_not_valid")}
              endAdornment={endAdornment}
              className={classNames([!isVerified && classes.notVerifiedField])}
              name="currentEmail"
            />
          </Row>
          {isDirty && (
            <Row>
              <TextFieldWithController
                label={t("field_edit_repeat_email_label")}
                id="confirm-email-input"
                autoComplete="email"
                placeholder={t("field_email_placeholder")}
                errorText={t("emails_dont_match")}
                className={classNames([isVerified && classes.notVerifiedField])}
                name="repeatEmail"
              />
            </Row>
          )}
          {shouldShowControls && !isDirty && isVerified && (
            <Col col={12} directionColumn noVerticalMargin>
              <CheckboxRow
                label={t("checkbox_email_use_as_primary")}
                bold={false}
                slim
                small
                noBorder
                checked={updateToBePrimary}
                onChange={() => setUpdateToBePrimary(!updateToBePrimary)}
              />
              <P xxSmall gray3 noMargin>
                {t("checkbox_email_helper_text")}
              </P>
            </Col>
          )}
          {shouldShowControls && (
            <Row noMargin>
              <Col col={6}>
                <Button
                  isDisabled={isSubmitting}
                  transparentBrand
                  action={() => setShowDeleteModal(true)}
                >
                  {t("delete_email_btn")}
                </Button>
              </Col>
              <Col col={6}>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  isDisabled={!isValid || (!isDirty && !updateToBePrimary)}
                  analyticsAttributes={GOOGLE_ANALYTICS_ATTRIBUTES.EMAIL_CHANGE}
                >
                  {t("action_save")}
                </Button>
              </Col>
            </Row>
          )}
        </form>
      </div>
    </FormProvider>
  );
};

export default EditEmail;
