import type React from "react";

import type { IPerk } from "@/api/interfaces/perkLayouts";
import { Carousel } from "@/base/components/Carousel";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";

import { PerkCard } from "./PerkCard";

type PerkGroupMobileViewProps = {
  perks: IPerk[];
};

// Displays Perks Carousel for mobile view
const PerkGroupMobileView: React.FC<PerkGroupMobileViewProps> = ({ perks }) => {
  const [{ isCurrentSizeSm }] = useMediaQuery();
  const defaultSelectedIndex = isCurrentSizeSm && perks.length > 1 ? 1 : 0;

  return (
    <Carousel items={perks} defaultSelectedIndex={defaultSelectedIndex}>
      {({ index, item, isSelected }) => (
        <PerkCard key={index} perk={item} isSelected={isSelected} />
      )}
    </Carousel>
  );
};

export default PerkGroupMobileView;
