import {
  type FieldPath,
  type FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  ThemeProvider,
} from "@mui/material";

import CheckedCheckboxIcon from "@/assets/svg/checked_checkbox.svg?react";
import EmptyCheckboxIcon from "@/assets/svg/empty_checkbox.svg?react";
import { muiTheme } from "@/theme";

export type CheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<React.ComponentProps<typeof Checkbox>, "variant" | "ref" | "name"> & {
  id: string;
  name: TName;
  label: string;
};

export const FormCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  id,
  name,
  label,
  ...props
}: CheckboxProps<TFieldValues, TName>) => {
  const { control } = useFormContext<TFieldValues>();

  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    name,
    control,
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <FormControl error={!!error}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                id={id}
                inputRef={field.ref}
                icon={<EmptyCheckboxIcon />}
                checkedIcon={<CheckedCheckboxIcon />}
                disabled={field.disabled}
                checked={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                inputProps={{ "aria-label": "controlled" }}
                {...props}
              />
            }
            label={label}
            labelPlacement="end"
          />
        </FormGroup>
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </ThemeProvider>
  );
};

FormCheckbox.displayName = "FormCheckbox";
