import type React from "react";
import classnames from "classnames";

import { VisibilityBlock } from "@/base/components/Layout";
import {
  type BlockProps,
  COL_NUMBER,
  HORIZONTAL_GUTTER,
  type MarginProps,
  VERTICAL_GUTTER,
} from "@/base/components/Layout/constants";
import type { VisibilityProps } from "@/base/components/Layout/VisibilityBlock";
import { createUseStyles } from "@/theme";

type StyleProps = MarginProps &
  VisibilityProps & {
    order?: number;
    orderSm?: number;
    orderMd?: number;
    orderLg?: number;
    orderXl?: number;

    col?: number;
    colSm?: number;
    colMd?: number;
    colLg?: number;
    colXl?: number;

    alignStart?: boolean;
    alignEnd?: boolean;
    alignCenter?: boolean;

    directionColumn?: boolean;
    justifyCenter?: boolean;

    fullWidth?: boolean;
  };

export type ColProps = StyleProps & BlockProps;

const useStyles = createUseStyles<StyleProps>(({ sizes }) => ({
  col: {
    display: "flex",
    order: ({ order }) => order,
    flexGrow: 1,
    flexDirection: ({ directionColumn }) =>
      directionColumn ? "column" : "row",
    alignItems: ({ directionColumn, alignStart, alignEnd, alignCenter }) => {
      if (directionColumn) {
        if (alignCenter) return "center";
        if (alignEnd) return "flex-end";
        if (alignStart) return "flex-start";
        return "flex-start";
      }
      return "center";
    },
    paddingTop: ({ noMargin, noVerticalMargin, noMarginTop }) =>
      noMargin || noVerticalMargin || noMarginTop ? 0 : VERTICAL_GUTTER / 2,
    paddingBottom: ({ noMargin, noVerticalMargin, noMarginBottom }) =>
      noMargin || noVerticalMargin || noMarginBottom ? 0 : VERTICAL_GUTTER / 2,
    paddingLeft: ({ noMargin, noHorizontalMargin, noMarginLeft }) =>
      noMargin || noHorizontalMargin || noMarginLeft
        ? 0
        : HORIZONTAL_GUTTER / 2,
    paddingRight: ({ noMargin, noHorizontalMargin, noMarginRight }) =>
      noMargin || noHorizontalMargin || noMarginRight
        ? 0
        : HORIZONTAL_GUTTER / 2,
    justifyContent: ({
      alignEnd,
      alignCenter,
      directionColumn,
      justifyCenter,
    }) => {
      if (justifyCenter) return "center";
      if (directionColumn) return;
      if (alignCenter) return "center";
      if (alignEnd) return "flex-end";
      return;
    },
    width: ({ fullWidth }) => (fullWidth ? "100%" : "unset"),
    maxWidth: ({ col }) => (col ? `${(100 / COL_NUMBER) * col}%` : "100%"),
    flexBasis: ({ col }) => (col ? `${(100 / COL_NUMBER) * col}%` : 0),

    [sizes.sm]: {
      order: ({ orderSm }) => orderSm,
      maxWidth: ({ colSm }) =>
        colSm ? `${(100 / COL_NUMBER) * colSm}%` : undefined,
      flexBasis: ({ colSm }) =>
        colSm ? `${(100 / COL_NUMBER) * colSm}%` : undefined,
    },
    [sizes.md]: {
      order: ({ orderMd }) => orderMd,
      maxWidth: ({ colMd }) =>
        colMd ? `${(100 / COL_NUMBER) * colMd}%` : undefined,
      flexBasis: ({ colMd }) =>
        colMd ? `${(100 / COL_NUMBER) * colMd}%` : undefined,
    },
    [sizes.lg]: {
      order: ({ orderLg }) => orderLg,
      maxWidth: ({ colLg }) =>
        colLg ? `${(100 / COL_NUMBER) * colLg}%` : undefined,
      flexBasis: ({ colLg }) =>
        colLg ? `${(100 / COL_NUMBER) * colLg}%` : undefined,
    },
    [sizes.xl]: {
      order: ({ orderXl }) => orderXl,
      maxWidth: ({ colXl }) =>
        colXl ? `${(100 / COL_NUMBER) * colXl}%` : undefined,
      flexBasis: ({ colXl }) =>
        colXl ? `${(100 / COL_NUMBER) * colXl}%` : undefined,
    },
  },
}));

const Col: React.FC<ColProps> = ({
  id,
  children,
  className,
  style,
  onClick,
  hide,
  belowSm,
  belowMd,
  belowLg,
  aboveSm,
  aboveMd,
  aboveLg,
  aboveXl,
  analyticsAttributes,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <VisibilityBlock
      hide={hide}
      belowSm={belowSm}
      belowMd={belowMd}
      belowLg={belowLg}
      aboveSm={aboveSm}
      aboveMd={aboveMd}
      aboveLg={aboveLg}
      aboveXl={aboveXl}
    >
      <div
        id={id}
        onClick={onClick}
        className={classnames([classes.col, className])}
        style={style}
        {...analyticsAttributes}
      >
        {children}
      </div>
    </VisibilityBlock>
  );
};

export default Col;
