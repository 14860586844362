import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import AddFormPage from "@/base/components/Form/v1/EditFormPage/AddFormPage";
import RepeatFieldForm from "@/base/components/Form/v1/EditFormPage/RepeatFieldForm";
import { validation } from "@/base/components/Form/v1/helpers";
import TextField from "@/base/components/Form/v1/TextField";
import ModalArray from "@/base/components/Modal/ModalArray";
import { useUser } from "@/base/components/UserProvider";
import { EMAIL_ICON } from "@/constants/blobIcons";
import config from "@/constants/config";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { useAppSelector } from "@/store/hooks";
import { selectEmails } from "@/store/user";

const validationSchema = yup
  .object({
    email: validation.email,
    repeatEmail: validation.repeat("email"),
  })
  .required();

const AddEmailPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const emails = useAppSelector(selectEmails);
  const [{ addEmail }] = useUser();
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  const onSubmit = async (data: Record<string, string>) => {
    const email = {
      email: data.email,
    };
    const isSuccess = await addEmail(email);
    if (isSuccess) {
      setCurrentModal("successModal");
    } else {
      setCurrentModal("errorModal");
    }
  };

  return (
    <AddFormPage
      didReachMaxRow={Boolean(emails.length >= config.appConfig.maxEmailCount)}
      form={{
        headingIconUrl: EMAIL_ICON,
        headingTitle: t("add_email_header"),
        headingSubTitle: t("add_email_subtitle"),
        content: t("add_email_helper_text"),
      }}
    >
      <ModalArray
        modals={{
          successModal: {
            title: t("modal_email_added_title"),
            subTitle: t("modal_email_added_subtitle"),
            content: t("modal_email_added_content"),
            analyticsAttributes: GOOGLE_ANALYTICS_ATTRIBUTES.ADD_EMAIL_SUCCESS,
          },
          errorModal: {
            title: t("modal_email_error_title"),
            subTitle: t("modal_email_error_subtitle"),
            content: t("modal_email_error_content"),
            analyticsAttributes: GOOGLE_ANALYTICS_ATTRIBUTES.ADD_EMAIL_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={() => {
          setCurrentModal(null);
          navigate(-1);
        }}
      />
      <RepeatFieldForm
        onSubmit={onSubmit}
        fieldKey="email"
        repeatFieldKey="repeatEmail"
        validationSchema={validationSchema}
        fieldComponent={(props) => (
          <TextField autoComplete="email" type="email" {...props} />
        )}
        fieldsText={{
          fieldLabel: t("field_enter_email_label"),
          fieldPlaceholder: t("field_email_placeholder"),
          fieldError: t("field_email_error"),
          fieldRepeatLabel: t("field_enter_repeat_email_label"),
          fieldRepeatPlaceholder: t("field_email_placeholder"),
          fieldRepeatError: t("emails_dont_match"),
          addRowText: t("field_add_email_button"),
        }}
        analyticsAttributes={GOOGLE_ANALYTICS_ATTRIBUTES.ADD_EMAIL}
      />
    </AddFormPage>
  );
};

export default AddEmailPage;
