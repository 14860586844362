import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import QrScanner from "qr-scanner";

import { Icon } from "@/base/components/Global";
import { INFO_ICON } from "@/constants/blobIcons";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ color, spacing }) => ({
  videoWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .scan-region-highlight-svg, & .code-outline-highlight": {
      // unfortunately, the library does not allow to style the svg in another way :(
      stroke: `${color.primaryBrand} !important`,
    },
  },
  qrVideo: {
    objectFit: "cover",
    width: "100%",
    aspectRatio: 1,
    backgroundColor: color.gray5,
  },
  qrError: {
    position: "absolute",
    paddingLeft: spacing.l,
    paddingRight: spacing.s,
    top: "calc(50% - 50px)",
    display: "flex",
    alignItems: "center",
  },
  errorMessage: {
    marginLeft: spacing.l,
  },
}));

type ScannerProps = {
  onScan: (result: string) => void;
};

const Scanner = ({ onScan }: ScannerProps) => {
  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const [qrOn, setQrOn] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (!videoElementRef.current) return;
    const video: HTMLVideoElement = videoElementRef.current;
    const qrScanner = new QrScanner(
      video,
      (result) => {
        onScan(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      },
    );
    qrScanner
      .start()
      .then(() => setQrOn(true))
      .catch(() => {
        setQrOn(false);
      });

    return () => {
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);

  return (
    <div className={classes.videoWrapper}>
      <video className={classes.qrVideo} ref={videoElementRef} muted />
      {!qrOn && (
        <div className={classes.qrError}>
          <Icon height={70} width={70} icon={INFO_ICON} />
          <p className={classes.errorMessage}>
            {t("gift_card_status_page_scan_error_message")}
          </p>
        </div>
      )}
    </div>
  );
};

export default Scanner;
