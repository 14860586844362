import * as React from "react";
import { memo } from "react";
const SvgPasswordIcon = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "#fff",
      strokeLinecap: "round",
      strokeWidth: 2,
      d: "M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#fff",
      d: "M12 12a2.19 2.19 0 1 0 0-4.38A2.19 2.19 0 0 0 12 12M12 9.08v7.29z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "#fff",
      strokeLinecap: "round",
      strokeWidth: 2,
      d: "M12 9.08v7.29"
    }
  )
);
const Memo = memo(SvgPasswordIcon);
export default Memo;
