import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { type IPageSection, SectionType } from "@/api/interfaces/pageLayout";
import PasswordIcon from "@/assets/svg/password_gray.svg?react";
import { Icon, Link } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import Section from "@/base/components/Global/Section";
import { BlockBreak, Col, Row } from "@/base/components/Layout";
import {
  ADDRESS_ICON,
  DELETE_ICON,
  EMAIL_ICON,
  PHONE_ICON,
  PROFILE_ICON,
} from "@/constants/blobIcons";
import { NavigationPath } from "@/constants/navigation";
import AddressBlock from "@/pages/ProfilePage/components/AddressBlock";
import EmailBlock from "@/pages/ProfilePage/components/EmailBlock";
import MyPersonalDataBlock from "@/pages/ProfilePage/components/MyPersonalDataBlock";
import PasswordBlock from "@/pages/ProfilePage/components/PasswordBlock";
import PhoneBlock from "@/pages/ProfilePage/components/PhoneBlock";
import { fetchProfilePage } from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { createUseStyles, getTheme } from "@/theme";

type SectionValues = {
  children?: JSX.Element;
  icon?: React.ReactNode;
};

const getSectionValues = (section: IPageSection): SectionValues | undefined => {
  const result: SectionValues = {};
  switch (section.type) {
    case SectionType.PERSONAL_DATA:
      result.icon = PROFILE_ICON;
      result.children = <MyPersonalDataBlock />;
      break;

    case SectionType.PASSWORD:
      result.icon = <PasswordIcon />;
      result.children = <PasswordBlock />;
      break;

    case SectionType.ADDRESS:
      result.children = <AddressBlock />;
      result.icon = ADDRESS_ICON;
      break;

    case SectionType.EMAIL:
      result.children = <EmailBlock />;
      result.icon = EMAIL_ICON;
      break;

    case SectionType.PHONE:
      result.children = <PhoneBlock />;
      result.icon = PHONE_ICON;
      break;

    default:
      return undefined;
  }

  return result;
};

const useStyles = createUseStyles(({ color, spacing }) => ({
  deleteAccount: {
    color: color.error,
    paddingLeft: spacing.s,
  },
}));

const ProfilePage: React.FC = () => {
  const profilePage = useAppSelector((state) => state.cms.profilePage);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { color } = getTheme();
  const classes = useStyles();

  return (
    <CmsPage
      pageStateMachine={profilePage}
      fetchPage={() => dispatch(fetchProfilePage())}
    >
      {({ page }) => (
        <Row>
          {page.array.map((c, idx) => {
            const sectionValues = getSectionValues(c);
            if (!sectionValues) return;

            return (
              <React.Fragment key={c.title}>
                <Col noMargin col={12} colMd={6} directionColumn>
                  <Section
                    title={c.title}
                    subTitle={c.subTitle}
                    icon={sectionValues.icon}
                    extraPadding={false}
                  >
                    {sectionValues.children}
                  </Section>
                </Col>
                <BlockBreak belowSm={Boolean(idx % 2)} />
              </React.Fragment>
            );
          })}
          <Col noVerticalMargin>
            <Icon icon={DELETE_ICON} stroke={color.error} />
            <Link
              action={() => navigate(NavigationPath.CloseAccount)}
              className={classes.deleteAccount}
            >
              {t("link_delete_my_account_text")}
            </Link>
          </Col>
        </Row>
      )}
    </CmsPage>
  );
};

export default ProfilePage;
