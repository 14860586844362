import type React from "react";
import { Suspense, useMemo } from "react";
import { AuthProvider, type AuthProviderProps } from "react-oidc-context";
import { RouterProvider } from "react-router-dom";
import { Log } from "oidc-client-ts";

import { router } from "@/base/components/AppRouter";
import { Loader } from "@/base/components/Global";
import MetaTags from "@/base/components/MetaTags";
import useLocaleOverride from "@/base/hooks/useLocaleOverride";
import { getSSOClientId } from "@/base/utils/authUtils";
import { isInIframe } from "@/base/utils/iframeUtils";
import LoyaltyWebStorageStateStore from "@/base/utils/LoyaltyWebStorageStateStore";
import config from "@/constants/config";
import { NavigationPath } from "@/constants/navigation";
import ErrorPage from "@/pages/ErrorPage/ErrorPage";
import { useAppSelector } from "@/store/hooks";
import { selectCurrentLanguage } from "@/store/language";
import { selectCurrentRegionPrefix } from "@/store/region";

if (process.env.NODE_ENV === "development") {
  Log.setLogger(console);
}

const App: React.FC = () => {
  const currentRegionPrefix = useAppSelector(selectCurrentRegionPrefix);
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  const oidcSettings: AuthProviderProps = useMemo(
    () => ({
      authority: config.ssoConfig.authorityUrl,
      client_id: getSSOClientId(currentRegionPrefix),
      redirect_uri: `${globalThis.origin}/auth`,
      response_type: "code",
      scope:
        "openid profile customer.api.read customer.api.write offline_access",
      post_logout_redirect_uri: globalThis.origin,
      automaticSilentRenew: true,
      redirectMethod: "replace",
      acr_values: `culture:${currentLanguage}`,
      userStore: new LoyaltyWebStorageStateStore({
        prefix: config.computed.authKeyPrefix,
        store: globalThis.sessionStorage,
      }),
    }),
    [currentRegionPrefix, currentLanguage],
  );

  return (
    <AuthProvider {...oidcSettings}>
      <RouterProvider fallbackElement={<ErrorPage />} router={router} />
    </AuthProvider>
  );
};

// We need to load the language and region overrides before we initialize the AuthProvider, otherwise we won't load the correct region client ID
const AppLoader: React.FC = () => {
  const { isLocaleLoaded } = useLocaleOverride();

  if (!isLocaleLoaded) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      {!isInIframe() && location.pathname !== NavigationPath.AuthComplete && (
        <MetaTags />
      )}
      <App />
    </Suspense>
  );
};

export default AppLoader;
