import * as React from "react";
import { memo } from "react";
const SvgUpArrow = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 8,
    height: 6,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#fff",
      d: "M4.42.62a.5.5 0 0 0-.84 0L.52 5.22A.5.5 0 0 0 .93 6h6.14a.5.5 0 0 0 .41-.78z"
    }
  )
);
const Memo = memo(SvgUpArrow);
export default Memo;
