import type React from "react";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import type { IGiftCard } from "@/api/interfaces/giftcardsLayout";
import type { IPerk } from "@/api/interfaces/perkLayouts";
import type { CardSideProps } from "@/base/components/FlippableCard/FlippableCard";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import {
  fetchPerkByType,
  getBarcodeField,
} from "@/pages/PerkPage/helpers/perkHelper";
import { createUseStyles } from "@/theme";

import PerkDetails from "../PerkDetails";

type UseStyleOptions = Omit<CardSideProps, "isVisible">;

const useStyles = createUseStyles(({ spacing, borderRadius, color }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
    height: "100%",
    backgroundColor: color.white,
    borderRadius: borderRadius.m,
  },
  imgCard: {
    maxWidth: ({ maxWidth }: UseStyleOptions) => maxWidth,
    width: ({ width }: UseStyleOptions) => width,
    minWidth: ({ minWidth }: UseStyleOptions) => minWidth,

    objectFit: "contain",

    boxShadow: "0px 0px 15px rgba(130, 130, 130, 0.5)",
    borderRadius: borderRadius.m,
  },
  scrollContainer: {
    display: "flex",
    overflowY: "scroll",
    padding: spacing.s,
  },
}));

type CardBackSideProps = CardSideProps & {
  perk: IPerk;
  onClick?: () => void;
};

export const CardBackSide: React.FC<CardBackSideProps> = ({
  perk,
  onClick,
  isVisible,
  ...props
}) => {
  const [{ isMobile }] = useMediaQuery();
  const classes = useStyles(props);
  const [perkDetails, setPerkDetails] = useState<IPerk | IGiftCard>();
  const barcode = getBarcodeField(perk);

  useEffect(() => {
    if (!perkDetails && isVisible) {
      void fetchPerkByType(perk.type, barcode, perk.centralCrmCode).then(
        (response) => {
          if (response) {
            setPerkDetails(response);
          }
        },
      );
    }
  }, [perk, perkDetails, isVisible, barcode]);

  // Todo: Implement loading and error states
  if (!perkDetails) return <div>Failed to load info</div>;

  return (
    <div className={classes.container}>
      <motion.div whileHover={isMobile ? undefined : { scale: 1.1 }}>
        <img
          src={perk.landscapeImage?.url}
          className={classes.imgCard}
          onClick={() => onClick?.()}
          draggable={false}
          alt="gift card"
        />
      </motion.div>
      <div className={classes.scrollContainer}>
        <PerkDetails
          perk={perkDetails}
          perkType={perk.type}
          barcode={barcode}
          isCardMode
        />
      </div>
    </div>
  );
};
