import type React from "react";
import {
  type FieldPath,
  type FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";
import { TextField as MaterialTextField, ThemeProvider } from "@mui/material";

import { muiTheme } from "@/theme";

export type TextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<
  React.ComponentProps<typeof MaterialTextField>,
  "variant" | "ref" | "name" | "value" | "error" | "onChange" | "onBlur"
> & {
  id: string;
  name: TName;
  endAdornment?: React.ReactNode;
};

export const FormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  children,
  id,
  name,
  label,
  placeholder,
  disabled,
  helperText,
  endAdornment,
  ...props
}: TextFieldProps<TFieldValues, TName>) => {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <MaterialTextField
        inputRef={field.ref}
        id={id}
        label={label}
        placeholder={placeholder}
        value={field.value ?? ""}
        error={!!error}
        helperText={error ? error.message : helperText}
        disabled={field.disabled || disabled}
        onChange={field.onChange}
        onBlur={field.onBlur}
        variant="outlined"
        slotProps={{
          input: {
            endAdornment,
          },
        }}
        {...props}
      >
        {children}
      </MaterialTextField>
    </ThemeProvider>
  );
};

FormTextField.displayName = "FormTextField";
