import { isPresent } from "@apl-digital/utils";
import { createSelector } from "@reduxjs/toolkit";

import type { IRegion, IRegionLocale } from "@/api/interfaces/countriesLayout";
import { isRegionEnabled } from "@/base/utils/featureFlagUtil";
import config from "@/constants/config";
import type { RootState } from "@/store/slices";
import { hasData } from "@/store/utils";

import type { RegionState } from "./types";

const selectRegion = (state: RootState): RegionState => state.region;

export const selectRegions = (state: RootState): IRegion[] | null => {
  const regions = selectRegion(state).regions;

  if (hasData(regions)) {
    return regions.array;
  }

  return null;
};

export const selectEnabledRegions = createSelector(
  selectRegions,
  (regions): IRegion[] | null => {
    if (regions) {
      return regions.filter((region) => isRegionEnabled(region.id));
    }

    return null;
  },
);

const selectCurrentRegionByHost = (state: RootState): IRegion | null => {
  const regionPrefix = config.routerConfig.regionPrefix;

  if (!isPresent(regionPrefix)) {
    return null;
  }

  const regions = selectRegions(state);

  if (regions) {
    return regions.find((region) => region.prefix === regionPrefix) ?? null;
  }

  return null;
};

export const selectCurrentRegionByUser = (state: RootState): IRegion | null => {
  const userData = state.user.userData;
  const regionId =
    userData.status === "succeeded" ? userData.regionId : undefined;

  if (!isPresent(userData)) {
    return null;
  }

  const regions = selectRegions(state);

  if (isPresent(regions)) {
    return regions.find((region) => region.id === regionId) ?? null;
  }

  return null;
};

const selectOverrideRegion = (state: RootState): IRegion | null => {
  const regionOverride = selectRegion(state).regionOverride;

  if (isPresent(regionOverride)) {
    const regions = selectRegions(state);

    if (isPresent(regions)) {
      return regions.find((region) => region.prefix === regionOverride) ?? null;
    }
  }

  return null;
};

const selectSelectedRegion = (state: RootState): IRegion | null => {
  const selectedRegion = selectRegion(state).selectedRegion;

  if (isPresent(selectedRegion)) {
    const regions = selectRegions(state);

    if (isPresent(regions)) {
      return regions.find((region) => region.prefix === selectedRegion) ?? null;
    }
  }

  return null;
};

export const selectCurrentRegionPrefix = (state: RootState): string | null => {
  const regionOverride = selectRegion(state).regionOverride;

  if (isPresent(regionOverride)) {
    return regionOverride;
  }

  const selectedRegion = selectRegion(state).selectedRegion;

  if (isPresent(selectedRegion)) {
    return selectedRegion;
  }

  const currentRegionByUser = selectCurrentRegionByUser(state);

  if (isPresent(currentRegionByUser)) {
    return currentRegionByUser.prefix;
  }

  const currentRegionByHost = config.routerConfig.regionPrefix;

  if (isPresent(currentRegionByHost)) {
    return currentRegionByHost;
  }

  return null;
};

export const selectCurrentRegion = (state: RootState): IRegion | null => {
  const regionOverride = selectOverrideRegion(state);

  if (isPresent(regionOverride)) {
    return regionOverride;
  }

  const selectedRegion = selectSelectedRegion(state);

  if (isPresent(selectedRegion)) {
    return selectedRegion;
  }

  const currentRegionByUser = selectCurrentRegionByUser(state);

  if (isPresent(currentRegionByUser)) {
    return currentRegionByUser;
  }

  const currentRegionByHost = selectCurrentRegionByHost(state);

  if (isPresent(currentRegionByHost)) {
    return currentRegionByHost;
  }

  return null;
};

export const selectHasPersonalCodeCategories = (state: RootState): boolean => {
  const currentRegion = selectCurrentRegion(state);

  if (isPresent(currentRegion)) {
    return (
      isPresent(currentRegion.personalCodeCategories) &&
      currentRegion.personalCodeCategories.length > 0
    );
  }

  return false;
};

export const selectAllLocales = createSelector(
  selectRegions,
  (regions): IRegionLocale[] | null => {
    if (regions) {
      return regions
        .flatMap((region) => region.locales)
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.code === value.code),
        )
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    return null;
  },
);

export const selectCurrentLocales = createSelector(
  selectCurrentRegion,
  (region): IRegionLocale[] | null => {
    if (isPresent(region)) {
      return region.locales;
    }

    return null;
  },
);

export const selectSortedRegions = createSelector(
  selectRegions,
  selectCurrentRegion,
  (regions, currentRegion): IRegion[] | null => {
    if (isPresent(regions)) {
      return [...regions].sort((a) => {
        if (a.id === currentRegion?.id) {
          return -1;
        }

        return 0;
      });
    }

    return null;
  },
);
