import type React from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

import { Badge, Icon, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { addLocaleParam, isExternalURI } from "@/base/utils/uriHelper";
import { useAppSelector } from "@/store/hooks";
import { selectCurrentLanguage } from "@/store/language";
import { selectCurrentRegionPrefix } from "@/store/region";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ color, spacing, opacity }) => ({
  menuButton: {
    display: "flex",
    width: "100%",
    padding: spacing.s,
    cursor: "pointer",
    color: color.black, // for SVG icons
  },
  menuButtonBorder: {
    borderBottom: `1px solid ${color.gray5}`,
  },
  colIcon: {
    maxWidth: 40,
  },
  icon: {
    display: "flex",
  },
  disabled: {
    opacity: opacity.m,
    cursor: "default",
  },
  menuButtonTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

type MenuItemType = {
  navigateTo?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  closeMenu?: () => void;
  num?: number | null;
  border?: boolean;
  isDisabled?: boolean;
};

const MenuItem: React.FC<MenuItemType> = ({
  navigateTo,
  children,
  icon,
  num,
  onClick,
  closeMenu,
  border,
  isDisabled = false,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const currentRegionPrefix = useAppSelector(selectCurrentRegionPrefix);

  const onItemClick = () => {
    if (isDisabled) return;
    if (onClick) {
      onClick();
    } else if (navigateTo) {
      const isExternalUrl = isExternalURI(navigateTo);
      if (isExternalUrl) {
        window.open(
          addLocaleParam(navigateTo, currentLanguage, currentRegionPrefix),
          "_blank",
        );
      } else navigate(navigateTo);
    }

    if (closeMenu) {
      closeMenu();
    }
  };

  return (
    <Row
      className={classnames(
        classes.menuButton,
        border && classes.menuButtonBorder,
        isDisabled && classes.disabled,
      )}
      onClick={onItemClick}
      noMargin
    >
      <Col className={classes.colIcon}>
        <Icon icon={icon} className={classes.icon} />
      </Col>
      <Col className={classes.menuButtonTitle}>
        <P black noMargin>
          {children}
        </P>
        <Badge>{num}</Badge>
      </Col>
    </Row>
  );
};

export default MenuItem;
