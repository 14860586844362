import type React from "react";

import { H5 } from "@/base/components/Global";
import P, { type StyleProps } from "@/base/components/Global/P";
import { Col } from "@/base/components/Layout";
import Row from "@/base/components/Layout/Row";
import { createUseStyles } from "@/theme";

type SectionStyleProps = {
  extraPadding?: boolean;
};

const useStyles = createUseStyles<StyleProps & SectionStyleProps>(
  ({ spacing }) => ({
    section: {
      padding: ({ extraPadding }) => [0, extraPadding ? spacing.xxl : 0],
    },
    subtitle: {
      "& p": {
        paddingTop: spacing.xs,
      },
    },
  }),
);

type SectionProps = {
  children?: JSX.Element;
  title?: string;
  subTitle?: string;
  icon?: React.ReactNode;
  noAdditionalMargin?: boolean;
} & SectionStyleProps;

const Section: React.FC<SectionProps> = ({
  children,
  title,
  subTitle,
  icon,
  noAdditionalMargin,
  extraPadding = true,
}) => {
  const classes = useStyles({ extraPadding });

  return (
    <Row noMargin>
      {title && (
        <Col noHorizontalMargin col={12} noMarginBottom={!!subTitle}>
          <H5 gray1 prefixIcon={icon} noMarginBottom>
            {title}
          </H5>
        </Col>
      )}
      {subTitle && (
        <Col noHorizontalMargin col={12} noMarginTop>
          <P gray3 small className={classes.subtitle}>
            {subTitle}
          </P>
        </Col>
      )}
      <Row noMargin={noAdditionalMargin} className={classes.section}>
        {children}
      </Row>
    </Row>
  );
};

export default Section;
