import {
  type FieldValues,
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
} from "react-hook-form";

export type FormProps<
  TFieldValues extends FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
> = React.ComponentProps<
  typeof FormProvider<TFieldValues, TContext, TTransformedValues>
> & {
  onSubmitValid: TTransformedValues extends undefined
    ? SubmitHandler<TFieldValues>
    : TTransformedValues extends FieldValues
      ? SubmitHandler<TTransformedValues>
      : never;
  onSubmitInvalid?: SubmitErrorHandler<TFieldValues>;
};

export const Form = <
  TFieldValues extends FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  children,
  onSubmitValid,
  onSubmitInvalid,
  ...props
}: FormProps<TFieldValues, TContext, TTransformedValues>) => {
  return (
    <FormProvider {...props}>
      <form onSubmit={props.handleSubmit(onSubmitValid, onSubmitInvalid)}>
        {children}
      </form>
    </FormProvider>
  );
};
