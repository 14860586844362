import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  type CountryData,
  type CountryIso2,
  parseCountry,
} from "react-international-phone";
import { useClickAway } from "react-use";
import { isPresent } from "@apl-digital/utils";
import { useVirtualizer } from "@tanstack/react-virtual";

import DropdownItem from "@/base/components/Form/v1/PhoneField/DropdownItem";
import { getCountrySearchResult } from "@/base/components/Form/v1/PhoneField/getCountrySearchResult";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(
  ({ color, zIndex, spacing, borderRadius }) => ({
    container: {
      display: "flex",
      position: "relative",
    },
    countryContainer: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      width: 50,
    },
    svg: {
      height: "100%",
      border: `1px solid ${color.gray3}`,
    },
    arrow: {
      width: 0,
      height: 0,
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
    },
    arrowUp: {
      borderBottom: `5px solid ${color.black}`,
    },
    arrowDown: {
      borderTop: `5px solid ${color.black}`,
    },
    dropdown: {
      zIndex: zIndex.dropdown,
      position: "absolute",
      top: "calc(100% - 6px)",
      width: "100%",
      // height: 300,
      // maxHeight: "25vh",
      backgroundColor: color.white,
      border: `2px solid ${color.primaryBrand}`,
      borderTop: "none",
      overflow: "hidden",
      borderBottomLeftRadius: borderRadius.s,
      borderBottomRightRadius: borderRadius.s,
    },
    searchInput: {
      border: "none",
      borderTop: `1px solid ${color.primaryBrand}`,
      borderBottom: `1px solid ${color.primaryBrand}`,
      marginTop: spacing.xs,
      marginBottom: spacing.xs,
      width: "100%",
      paddingTop: spacing.l,
      paddingBottom: spacing.l,
      paddingLeft: spacing.m,
      paddingRight: spacing.m,
      outline: "none",
    },
  }),
);

type DropdownBlockProps = {
  countries: CountryData[];
  setCountry: (country: CountryIso2) => void;
  setOpen: (isOpen: boolean) => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

const DropdownBlock: React.FC<DropdownBlockProps> = ({
  countries,
  setCountry,
  setOpen,
  buttonRef,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const parentRef = React.useRef<HTMLDivElement>(null);

  useClickAway(dropdownRef, (e) => {
    if (buttonRef.current?.contains(e.target as Node)) return;
    setOpen(false);
  });

  const [search, setSearch] = useState("");

  const filteredCountryCodes = useMemo(() => {
    return getCountrySearchResult(search, countries);
  }, [search]);

  useEffect(() => {
    if (filteredCountryCodes.length === 1) {
      const { iso2 } = parseCountry(filteredCountryCodes[0]);
      setCountry(iso2);
      setOpen(false);
    }
  }, [filteredCountryCodes]);

  const rowVirtualizer = useVirtualizer({
    count: filteredCountryCodes.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 36,

    overscan: 5,
  });

  return (
    <div className={classes.dropdown} ref={dropdownRef}>
      <input
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        type="text"
        value={search}
        autoFocus
        placeholder={t("field_country_code_search")}
        className={classes.searchInput}
      />
      <div
        ref={parentRef}
        style={{
          height: `200px`,
          // width: `400px`,
          overflow: "auto",
        }}
      >
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const country = filteredCountryCodes.at(virtualRow.index);

            if (!isPresent(country)) {
              return null;
            }

            return (
              <div
                key={virtualRow.index}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <DropdownItem country={country} setCountry={setCountry} />
              </div>
            );
          })}
        </div>
        {/* // {filteredCountryCodes.map((country) => (
          //   <DropdownItem
          //     key={country[1]}
          //     country={country}
          //     setCountry={setCountry}
          //   />
          // ))} */}
      </div>
    </div>
  );
};

export default DropdownBlock;
