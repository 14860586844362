import type React from "react";

import type { IBenefitCard } from "@/api/interfaces/benefitLayouts";
import { H3, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing, borderRadius, card }) => ({
  block: {
    paddingTop: spacing.s,
  },
  cardContainer: {
    width: "100%",
    border: "1px solid",
    borderRadius: borderRadius.s,
    padding: spacing.l,
    aspectRatio: card.ratio,
    minWidth: card.minWidth,
    marginRight: spacing.s,
    marginLeft: spacing.s,
    justifyContent: "space-evenly",
  },
  slogan: {
    maxWidth: 300,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

type CardBlockProps = {
  card?: IBenefitCard;
};

const CardBlock: React.FC<CardBlockProps> = ({ card }) => {
  const classes = useStyles();
  if (!card || Object.keys(card).length === 0) return null;

  const { primaryColor, logoURL, slogan, rule } = card;

  return (
    <Row className={classes.block}>
      <Col
        col={12}
        className={classes.cardContainer}
        style={{ borderColor: `#${primaryColor}` }}
        directionColumn
        alignCenter
        justifyCenter
      >
        {logoURL && <img src={logoURL} />}
        {slogan && (
          <H3 gray3 noMargin>
            <span className={classes.slogan}>{slogan}</span>
          </H3>
        )}
        {rule && (
          <P small noMargin style={{ color: `#${primaryColor}` }}>
            {rule}
          </P>
        )}
      </Col>
    </Row>
  );
};

export default CardBlock;
