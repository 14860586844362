import type React from "react";
import { useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import classnames from "classnames";

import { H5 } from "@/base/components/Global";
import { createUseStyles } from "@/theme";

const TRANSITION_PERIOD = 250;
const MENU_MARGIN_TOP = 56; // header height
const MENU_PADDING_LEFT = 48;
const MENU_MAX_WIDTH = 400;
const MEDIA_MENU = `@media screen and (min-width: ${
  MENU_MAX_WIDTH + MENU_PADDING_LEFT
}px)`;

type StyleProps = {
  isMenu?: boolean;
  isTransparent?: boolean;
  customZIndex?: number;
};

const useStyles = createUseStyles<StyleProps>(
  ({ sizes, spacing, color, zIndex }) => ({
    outerContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: ({ isTransparent }) =>
        isTransparent ? "rgba(0,0,0,0.6)" : "rgba(0,0,0,0.4)",
      zIndex: ({ customZIndex }) => customZIndex || zIndex.modal,
      overflowY: "hidden",
      transition: `${TRANSITION_PERIOD}ms`,
      opacity: 0,
      paddingLeft: ({ isMenu }) => (isMenu ? MENU_PADDING_LEFT : "unset"),

      [sizes.md]: {
        marginTop: MENU_MARGIN_TOP,
        paddingTop: ({ isMenu }) => (isMenu ? "unset" : 48),
        height: `calc(100vh - ${MENU_MARGIN_TOP}px)`,
      },
    },
    outerContainerOpening: {
      opacity: 1,
    },
    outerContainerOpened: {
      opacity: 1,
    },
    container: {
      background: ({ isTransparent }) =>
        isTransparent ? "transparent" : color.white,
      display: "flex",
      flexDirection: "column",
      height: ({ isTransparent }) => (isTransparent ? "unset" : "100%"),
      width: "100%",
      bottom: 0,
      right: 0,
      overflowY: "hidden",
      position: "relative",
      transition: `${TRANSITION_PERIOD}ms`,
      top: ({ isMenu }) => (isMenu ? "unset" : "100%"),
      left: ({ isMenu }) => (isMenu ? "100vw" : "unset"),
      maxWidth: ({ isMenu }) => (isMenu ? MENU_MAX_WIDTH : undefined),
    },
    transitionDisabled: {
      transition: "none !important",
    },
    containerOpening: {
      top: ({ isMenu }) => (isMenu ? "unset" : "0%"),
      left: ({ isMenu }) => (isMenu ? "100vw" : "unset"),
    },
    containerOpened: {
      top: ({ isMenu }) => (isMenu ? "unset" : "0%"),
      left: ({ isMenu }) => (isMenu ? "0%" : "unset"),
      [MEDIA_MENU]: {
        left: ({ isMenu }) =>
          isMenu
            ? `calc(100vw - ${MENU_MAX_WIDTH + MENU_PADDING_LEFT}px)`
            : "unset",
      },
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingTop: spacing.m,
      paddingBottom: spacing.m,
      paddingLeft: spacing.l,
      paddingRight: spacing.l,
      alignItems: "center",
    },
    content: {
      height: "100%",
    },
  }),
);

type GenericModalProps = StyleProps & {
  id?: string;
  title?: string;
  isOpen?: boolean;
  showHeader?: boolean;
  closeModal?: () => void;
  children: React.ReactNode;
};

const GenericModal: React.FC<GenericModalProps> = ({
  id,
  title,
  isOpen,
  isMenu,
  isTransparent,
  showHeader = true,
  closeModal,
  children,
  customZIndex,
}) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [isOpening, setIsOpening] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isTransitionDisabled, setIsTransitionDisabled] =
    useState<boolean>(false);
  const [top, setTop] = useState<number>(0);
  const classes = useStyles({ isMenu, customZIndex, isTransparent });
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    const body = document.querySelector("body");

    if (body) {
      if (isOpen) {
        const bodyRect = document.body.getBoundingClientRect();
        if (bodyRect && top === 0) {
          setTop(-bodyRect.top);
        }
        body.style.overflow = "hidden";
        document.body.style.top = `-${window.scrollY}px`;
      } else {
        body.style.overflow = "auto";
        const topScroll = -Number(document.body.style.top.slice(0, -2));
        window.scrollTo({ top: topScroll });
        document.body.style.top = "unset";
      }
    }

    if (isOpen) {
      setIsOpening(true);
      setIsClosing(false);
      timeout = setTimeout(() => {
        setIsOpening(false);
        setIsOpened(true);
        setIsClosing(false);
      }, TRANSITION_PERIOD);
    } else {
      setIsOpening(false);
      setIsOpened(false);
      setIsClosing(true);
      window.scrollTo(0, top);
      timeout = setTimeout(() => {
        setIsOpening(false);
        setIsOpened(false);
        setIsClosing(false);
        setTop(0);
      }, TRANSITION_PERIOD);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        setIsOpening(false);
        setIsOpened(false);
        setIsClosing(true);
        if (body) {
          body.style.overflow = "auto";
        }
      }
    };
  }, [isOpen]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (isOpened) {
      timeout = setTimeout(() => {
        setIsTransitionDisabled(true);
      }, TRANSITION_PERIOD);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        setIsTransitionDisabled(false);
      }
    };
  }, [isOpened]);

  const onClickOuterContainer = () => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "auto";
    }
    if (closeModal) closeModal();
  };

  useClickAway(modalRef, (event: Event) => {
    event.preventDefault();
    setTimeout(onClickOuterContainer, 10);
  });

  if (!isOpen && !(isOpened || isClosing)) {
    return null;
  }

  return (
    <div
      ref={modalRef}
      className={classnames([
        classes.outerContainer,
        isOpening && classes.outerContainerOpening,
        isOpened && classes.outerContainerOpened,
      ])}
      onClick={onClickOuterContainer}
      id={id}
    >
      <div
        className={classnames([
          classes.container,
          isOpening && classes.containerOpening,
          isOpened && classes.containerOpened,
          isTransitionDisabled && classes.transitionDisabled,
        ])}
        onClick={(e) => e.stopPropagation()}
      >
        {showHeader && !isMenu && !isTransparent && (
          <div className={classes.header}>
            <H5>{title}</H5>
            <span onClick={closeModal}>X</span>
          </div>
        )}
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default GenericModal;
