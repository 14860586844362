import type React from "react";

import type { IHomeLoyaltyCards } from "@/api/interfaces/giftcardsLayout";
import GiftCardActions from "@/base/blocks/PerkBlock/GiftCardActions";
import { PERKS_BLOCK_ID } from "@/base/blocks/PerkBlock/PerkBlock";
import { H5, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import { GIFT_CARD_ICON } from "@/constants/blobIcons";

type Props = {
  block: IHomeLoyaltyCards;
};

const NoPerksBlock: React.FC<Props> = ({ block }) => {
  const [{ isCurrentSizeSm }] = useMediaQuery();

  if (!block) return null;

  const { title, subTitle, imageURL, description, actions } = block;

  return (
    <Row noMargin>
      <Col col={12} directionColumn id={PERKS_BLOCK_ID}>
        <H5 prefixIcon={GIFT_CARD_ICON} gray1>
          {title}
        </H5>
        <P gray3 small noMarginTop>
          {subTitle}
        </P>
      </Col>
      <Row noMargin>
        <Col directionColumn alignCenter col={12} colMd={6}>
          <Col>
            <img src={imageURL} width={324} height={250} />
          </Col>
        </Col>
        <Col alignCenter col={12} colMd={6} noMargin={!isCurrentSizeSm}>
          <Row>
            <P cmsContent={description} />
          </Row>
        </Col>
      </Row>
      <GiftCardActions actions={actions} />
    </Row>
  );
};

export default NoPerksBlock;
