import * as React from "react";
import { memo } from "react";
const SvgLoader = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 158,
    height: 158,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "circle",
    {
      cx: 79,
      cy: 79,
      r: 75,
      stroke: "#F68A1F",
      strokeDasharray: "4 33",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 8
    }
  )
);
const Memo = memo(SvgLoader);
export default Memo;
