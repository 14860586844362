import { request } from "@/api";
import type { ICountry, IRegion } from "@/api/interfaces/countriesLayout";
import { ApiPath } from "@/api/paths";
import type { ApiRequest, RequestResponse } from "@/api/types";

const getRegions: ApiRequest<RequestResponse<IRegion[]>, void> = async (
  options,
) => request({ ...options, api: ApiPath.Regions.base });

const getClientCountries: ApiRequest<
  RequestResponse<ICountry[]>,
  void
> = async (options) =>
  request({
    ...options,
    api: ApiPath.Form.countries,
    canFail: false,
    shouldParseResponse: true,
  });

const TerritoryController = {
  getRegions,
  getClientCountries,
};

export default TerritoryController;
