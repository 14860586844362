import type { Middleware } from "@reduxjs/toolkit";
import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";

import listenerMiddleware from "./listenerMiddleware";
import { rootReducer } from "./slices";

export const rtkMiddlewareErrorLogger: Middleware =
  // eslint-disable-next-line unicorn/consistent-function-scoping
  () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.warn(
        `We got a rejected action: ${action.type}`,
        action.payload,
        action.error,
      );
    }
    return next(action);
  };

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rtkMiddlewareErrorLogger, listenerMiddleware), // eslint-disable-line unicorn/prefer-spread
});

export type AppDispatch = typeof store.dispatch;
