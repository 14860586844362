import { createSelector } from "@reduxjs/toolkit";

import { GiftCardType } from "@/api/interfaces/giftcardsLayout";
import { shouldGiftCardBeShown } from "@/store/giftCards/utils";
import type { RootState } from "@/store/slices";
import { hasData } from "@/store/utils";

const selectGiftCardsState = (state: RootState) => state.giftCards;

export const selectGiftCards = (state: RootState) => {
  const giftCardsState = selectGiftCardsState(state);

  return giftCardsState.giftCards;
};

export const selectBonusTypeGiftCardsCount = createSelector(
  selectGiftCardsState,
  (state) =>
    hasData(state.giftCards)
      ? state.giftCards.array.filter(
          (giftCard) =>
            giftCard.type === GiftCardType.BONUS &&
            shouldGiftCardBeShown(giftCard),
        ).length
      : 0,
);
