import * as React from "react";
import { memo } from "react";
const SvgFullArrowDown = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 11,
    height: 8,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#101010",
      d: "M6.42 7c-.5.54-1.34.54-1.84 0L.51 2.6C-.23 1.8.34.5 1.43.5h8.14c1.1 0 1.66 1.3.92 2.1z"
    }
  )
);
const Memo = memo(SvgFullArrowDown);
export default Memo;
