import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { HttpStatus } from "@/api/constants";
import GiftCardController from "@/api/controller/giftCardController";
import PerkController from "@/api/controller/perkController";
import type { IGiftCard } from "@/api/interfaces/giftcardsLayout";
import { PerkType } from "@/api/interfaces/perkLayouts";
import { H5, Icon, Loader, P } from "@/base/components/Global";
import Button from "@/base/components/Global/Button";
import { Col, Row, RowBreak } from "@/base/components/Layout";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import { useUser } from "@/base/components/UserProvider";
import { isEnabledForGiftCardSoftLive } from "@/base/utils/featureFlagUtil";
import { formatDate, formatMoney } from "@/base/utils/formatting";
import { scrollToWithOffset } from "@/base/utils/scrollHelpers";
import { SessionStorageKey, setSessionStorage } from "@/base/utils/storage";
import {
  COPY_SMALL_ICON,
  GIFT_CARD_ICON,
  HIDE_ICON,
  SHOW_ICON,
  SIGN_IN_ICON,
  TRANSACTION_ICON,
} from "@/constants/blobIcons";
import config from "@/constants/config";
import {
  Action,
  GiftCardID,
  GiftCardPIN,
  NavigationPath,
} from "@/constants/navigation";
import GiftCardAddConfirmModal from "@/pages/GiftCardStatusPage/components/GiftCardAddConfirmModal";
import PerkTransactions from "@/pages/PerkPage/components/PerkTransactions";
import { getPerkNumberTranslationByType } from "@/pages/PerkPage/helpers/perkHelper";
import { useAppSelector } from "@/store/hooks";
import { selectCurrentLanguage } from "@/store/language/selectors";
import { createUseStyles, getTheme } from "@/theme";

type LocationProps = {
  validationCard: IGiftCard;
};

const TRANSACTIONS_ID = "gift-card-transactions";
const ADD_AUTOMATICALLY_ACTION = "add";

const useStyles = createUseStyles(({ spacing, color }) => ({
  valueRow: {
    borderBottom: `1px solid ${color.gray5}`,
  },
  valueBlock: {
    width: "100%",
    padding: [spacing.xl, 0],
  },
  rowAction: {
    gap: spacing.s,
  },
  iconAction: {
    cursor: "pointer",
  },
  amount: {
    "& span": {
      "& p": {
        whiteSpace: "nowrap",
      },
    },
  },
}));

const GiftCardStatusDetailsPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const [giftCard, setGiftCard] = useState<IGiftCard>();
  const { color } = getTheme();
  const [{ logIn }] = useUser();
  const [isRevealed, setIsRevealed] = useState(false);
  const [{ isCurrentSizeMd }] = useMediaQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCardAlreadyAdded, setIsCardAlreadyAdded] = useState(false);
  const [didAddCardSuccessfully, setDidAddCardSuccessfully] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentModal, setCurrentModal] = useState<
    "success" | "error" | "confirm"
  >();
  const [errorMessage, setErrorMessage] = useState(
    "gift_card_add_error_modal_content",
  );
  const navigate = useNavigate();
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  const fetchGiftCard = async (
    usageBarcode: string,
    pin: string,
  ): Promise<boolean> => {
    setIsLoading(true);
    const { isResponseOk, response } =
      await GiftCardController.validateGiftCard({
        body: { usageBarcode, pin },
      });
    if (isResponseOk) {
      setGiftCard(response);
    }
    setIsLoading(false);
    return isResponseOk;
  };

  useEffect(() => {
    const pin = searchParams.get(GiftCardPIN);
    const usageBarcode = searchParams.get(GiftCardID);
    if (location?.state) {
      const prevStateGiftCard = (location.state as LocationProps)
        .validationCard;
      void fetchGiftCard(
        prevStateGiftCard.usageBarcode,
        prevStateGiftCard.verificationCode,
      );
    } else if (pin && usageBarcode) {
      void fetchGiftCard(usageBarcode, pin).then((isSuccess) => {
        if (
          isSuccess &&
          searchParams.get(Action) === ADD_AUTOMATICALLY_ACTION
        ) {
          searchParams.delete(Action);
          setSearchParams(searchParams);
          setCurrentModal("confirm");
        }
      });
    } else {
      navigate(NavigationPath.GiftCardStatus);
    }
  }, [currentLanguage]);

  useEffect(() => {
    void (async () => {
      if (giftCard && isAuthenticated) {
        const { isResponseOk, response } = await PerkController.fetchPerks();
        if (isResponseOk && response) {
          const isExistingCard = response.some(
            (perk) => perk.usageBarcode === giftCard.usageBarcode,
          );
          setIsCardAlreadyAdded(isExistingCard);
        }
      }
    })();
  }, [isAuthenticated, giftCard]);

  if (!giftCard) return <Loader isMinified />;

  const scrollToTransactions = () => {
    if (giftCard.transactions && giftCard.transactions.length > 0) {
      scrollToWithOffset(TRANSACTIONS_ID);
    }
  };

  const signIn = (): void => {
    setSessionStorage(
      SessionStorageKey.AUTH_PREV_PAGE,
      `${NavigationPath.GiftCardStatusDetails}?${GiftCardID}=${giftCard.usageBarcode}&${GiftCardPIN}=${giftCard.verificationCode}&${Action}=${ADD_AUTOMATICALLY_ACTION}`,
    );
    logIn();
  };

  const addGiftCard = async () => {
    const { isResponseOk, responseStatus } =
      await GiftCardController.addGiftCardToPerson({
        body: {
          usageBarcode: giftCard.usageBarcode,
          verificationCode: giftCard.verificationCode,
        },
      });
    if (isResponseOk) {
      setIsCardAlreadyAdded(true);
      setDidAddCardSuccessfully(true);
      setCurrentModal("success");
    } else {
      if (responseStatus === HttpStatus.CONFLICT) {
        setErrorMessage("gift_card_add_error_modal_already_in_use_content");
      }
      setCurrentModal("error");
    }
  };

  const closeModal = () => setCurrentModal(undefined);

  if (isLoading) return <Loader showLoader />;

  return (
    <>
      <SimpleModalWrapper
        isOpen={currentModal === "success"}
        onClose={closeModal}
        title={t("gift_card_active_modal_title")}
        content={t("gift_card_active_modal_content")}
      />
      <SimpleModalWrapper
        isOpen={currentModal === "error"}
        onClose={closeModal}
        title={t("gift_card_add_error_modal_title")}
        // @ts-expect-error: waits for translations to accept dynamic keys
        content={t(errorMessage)}
        buttonTransparent
      />
      <GiftCardAddConfirmModal
        isOpen={currentModal === "confirm"}
        onClose={closeModal}
        action={addGiftCard}
        usageBarcode={giftCard.usageBarcode}
      />
      <Row>
        <Col
          col={12}
          alignCenter
          noVerticalMargin
          noHorizontalMargin={!isCurrentSizeMd}
          directionColumn={!isCurrentSizeMd}
          fullWidth
        >
          <Col col={12} hide={!giftCard.landscapeImage?.url} belowSm>
            <img
              src={giftCard.landscapeImage?.url}
              width={311}
              height={195}
              key={`landscape-${giftCard?.usageBarcode}-${currentLanguage}`}
            />
          </Col>
          <Col col={6} hide={!giftCard.portraitImage?.url} aboveMd alignCenter>
            <img
              src={giftCard.portraitImage?.url}
              width={300}
              height={500}
              key={`portrait-${giftCard?.usageBarcode}-${currentLanguage}`}
            />
          </Col>
          <Col col={12} colMd={6} directionColumn fullWidth alignCenter>
            <Col col={12} noMarginBottom>
              <H5 gray1>{giftCard.name}</H5>
            </Col>
            <Col col={12} noMarginTop>
              <P gray1>
                {t("gift_card_valid")
                  .replace(
                    "%start%",
                    formatDate(giftCard.validFrom, false, "."),
                  )
                  .replace(
                    "%end%",
                    formatDate(giftCard.validUntil, false, "."),
                  )}
              </P>
            </Col>
            <Col
              col={12}
              noMargin
              className={classes.valueBlock}
              directionColumn
              fullWidth
            >
              <Row noMargin>
                <Col col={9} noMargin>
                  <P gray2>{t("amount")}</P>
                </Col>
                <Col col={3} noMargin alignEnd className={classes.rowAction}>
                  <P bold gray1 className={classes.amount}>
                    {formatMoney(giftCard.balance, currentLanguage, true)}
                  </P>
                  <Icon
                    icon={TRANSACTION_ICON}
                    width={24}
                    height={24}
                    stroke={color.primaryBrand}
                    className={classes.iconAction}
                    onClick={scrollToTransactions}
                  />
                </Col>
              </Row>
              <Row noMargin className={classes.valueRow} />
              <Row noMargin>
                <Col col={6} noMargin>
                  <P gray2>
                    {/* @ts-expect-error: waits for translations to accept dynamic keys */}
                    {t(getPerkNumberTranslationByType(PerkType.GIFT_CARD))}
                  </P>
                </Col>
                <Col col={6} alignEnd noMargin className={classes.rowAction}>
                  <P bold gray1>
                    {giftCard.usageBarcode}
                  </P>
                  <Icon
                    icon={COPY_SMALL_ICON}
                    width={24}
                    height={24}
                    stroke={color.primaryBrand}
                    onClick={() =>
                      navigator.clipboard.writeText(giftCard?.usageBarcode)
                    }
                    className={classes.iconAction}
                  />
                </Col>
              </Row>
              <>
                <Row noMargin className={classes.valueRow} />
                <Row noMargin>
                  <Col col={6} noMargin>
                    <P gray2>{t("gift_card_verificationcode")}</P>
                  </Col>
                  <Col col={6} alignEnd noMargin className={classes.rowAction}>
                    <P bold gray1>
                      {isRevealed ? giftCard.verificationCode : "******"}
                    </P>
                    <Icon
                      icon={isRevealed ? SHOW_ICON : HIDE_ICON}
                      width={24}
                      height={24}
                      stroke={color.primaryBrand}
                      onClick={() => setIsRevealed(!isRevealed)}
                      className={classes.iconAction}
                    />
                  </Col>
                </Row>
              </>
            </Col>
            {!isAuthenticated && isEnabledForGiftCardSoftLive && (
              <>
                <Col col={12}>
                  <P small gray3>
                    {t("gift_card_details_sign_in")}
                  </P>
                </Col>
                <Col col={12} fullWidth>
                  <Button
                    prefixIcon={
                      <Icon icon={SIGN_IN_ICON} stroke={color.white} />
                    }
                    action={signIn}
                  >
                    {t("action_sign_in_and_add_gift_card")}
                  </Button>
                </Col>
              </>
            )}
            {isAuthenticated && !isCardAlreadyAdded && (
              <>
                <Col col={12} fullWidth>
                  <Button
                    transparentBrand
                    prefixIcon={GIFT_CARD_ICON}
                    isActive
                    action={() => setCurrentModal("confirm")}
                  >
                    {t("add_gift_card_to_your_account_button")}
                  </Button>
                </Col>
                <RowBreak />
              </>
            )}
            {isAuthenticated &&
              (didAddCardSuccessfully || isCardAlreadyAdded) && (
                <>
                  <Col col={12} fullWidth>
                    <Button
                      action={() =>
                        window.open(
                          config.routerConfig.loyaltyProfileUrl,
                          "_blank",
                        )
                      }
                    >
                      {t("gift_card_go_to_account_button")}
                    </Button>
                  </Col>
                  <Col col={12} fullWidth>
                    <Button
                      transparentBrand
                      action={() => navigate(NavigationPath.GiftCardStatus)}
                    >
                      {t("add_another_gift_card_button")}
                    </Button>
                  </Col>
                  <RowBreak />
                </>
              )}
            {giftCard.transactions && giftCard.transactions.length > 0 && (
              <PerkTransactions
                transactions={giftCard.transactions}
                id={TRANSACTIONS_ID}
              />
            )}
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default GiftCardStatusDetailsPage;
