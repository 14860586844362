import type React from "react";

import { P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";

type DescriptionBlockProps = {
  description?: string;
};

const DescriptionBlock: React.FC<DescriptionBlockProps> = ({ description }) => {
  if (!description) return null;

  return (
    <Row belowSm>
      <Col col={12}>
        <P cmsContent={description} />
      </Col>
    </Row>
  );
};

export default DescriptionBlock;
