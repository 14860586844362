import * as React from "react";
import { memo } from "react";
const SvgGrayAvatarCircleL = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 110,
    height: 111,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      width: 109.71,
      height: 109.71,
      x: 0.14,
      y: 0.69,
      fill: "#E0E0E0",
      rx: 54.86
    }
  )
);
const Memo = memo(SvgGrayAvatarCircleL);
export default Memo;
