import * as React from "react";
import { memo } from "react";
const SvgCloseBtn = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement("circle", { cx: 12, cy: 12, r: 11, stroke: "#BDBDBD", strokeWidth: 2 }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "#BDBDBD",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2,
      d: "m9 9 6 6M9 15l6-6"
    }
  )
);
const Memo = memo(SvgCloseBtn);
export default Memo;
