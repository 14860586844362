import type React from "react";

import CmsRowBreak from "@/base/components/Global/cmsRowBreak";

type CMSBlockProps = {
  children: React.ReactNode;
  addBreak: boolean;
  hide?: boolean;
  isSmall?: boolean;
};

const CmsBlock: React.FC<CMSBlockProps> = ({
  children,
  addBreak,
  hide,
  isSmall,
}) => {
  if (hide) return null;

  return (
    <>
      {children}
      {addBreak && <CmsRowBreak isSmall={isSmall} />}
    </>
  );
};

export default CmsBlock;
