export enum SectionType {
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",
  ADDRESS = "ADDRESS",
  INFO = "INFO",
  PHONE = "PHONE",
  LOYALTY_CARD = "LOYALTY_CARD",
  BENEFITS = "BENEFITS",
  LOCATION = "LOCATION",
  BENEFITS_DETAILS = "BENEFITS_DETAILS",
  CLUB_CARD = "CLUB_CARD",
  GIFT_CARDS = "GIFT_CARDS",
  PERSONAL_DATA = "PERSONAL_DATA",
  MY_COLLECTION = "MY_COLLECTION",
  TRANSACTIONS = "TRANSACTIONS",
  BKK5 = "BKK5",
}

export type IPageSection = {
  type: SectionType;
  title?: string;
  subTitle?: string;
};
