import {
  APOLLO_CLUB_TEXT_WHITE_EN_ICON,
  APOLLO_CLUB_TEXT_WHITE_ET_ICON,
  APOLLO_CLUB_TEXT_WHITE_LT_ICON,
  APOLLO_CLUB_TEXT_WHITE_LV_ICON,
  APOLLO_CLUB_WITH_STAR_EN_ICON,
  APOLLO_CLUB_WITH_STAR_ET_ICON,
  APOLLO_CLUB_WITH_STAR_LT_ICON,
  APOLLO_CLUB_WITH_STAR_LV_ICON,
} from "@/constants/blobIcons";

const getApolloLogoURLByLanguage = (language: string): string => {
  if (language === "et") return APOLLO_CLUB_TEXT_WHITE_ET_ICON;
  if (language === "lt") return APOLLO_CLUB_TEXT_WHITE_LT_ICON;
  if (language === "lv") return APOLLO_CLUB_TEXT_WHITE_LV_ICON;
  return APOLLO_CLUB_TEXT_WHITE_EN_ICON;
};

const getApolloLogoWithStartURLByLanguage = (language: string): string => {
  if (language === "et") return APOLLO_CLUB_WITH_STAR_ET_ICON;
  if (language === "lt") return APOLLO_CLUB_WITH_STAR_LT_ICON;
  if (language === "lv") return APOLLO_CLUB_WITH_STAR_LV_ICON;
  return APOLLO_CLUB_WITH_STAR_EN_ICON;
};

export { getApolloLogoURLByLanguage, getApolloLogoWithStartURLByLanguage };
