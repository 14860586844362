export const sortOrderCompare = (
  a: number | undefined,
  b: number | undefined,
) => {
  if (a === undefined || a === 0) {
    return 1;
  }

  if (b === undefined || b === 0) {
    return -1;
  }

  return a - b;
};
