const BASE_PATH = "https://assets.markus.live/01-loyalty-portal/icons/tabbar";

export const TAB_BAR_ICONS = {
  APOLLO_CLUB: {
    defaultIcon: `${BASE_PATH}/Home_default.svg`,
    activeIcon: `${BASE_PATH}/Home_selected.svg`,
  },
  GIFT_CARDS: {
    defaultIcon: `${BASE_PATH}/GiftCard_default.svg`,
    activeIcon: `${BASE_PATH}/GiftCard_selected.svg`,
  },
  PROFILE: {
    defaultIcon: `${BASE_PATH}/Profile_default.svg`,
    activeIcon: `${BASE_PATH}/Profile_selected.svg`,
  },
  BURGER_MENU: {
    defaultIcon: `${BASE_PATH}/More_default.svg`,
  },
};
