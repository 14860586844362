import type React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import type { IPerk } from "@/api/interfaces/perkLayouts";
import { NavigationPath, PerkGroupName } from "@/constants/navigation";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ card }) => ({
  perk: {
    height: 7,
    overflow: "visible",
    transition: "height 0.5s ease",
    minWidth: card.minWidth,
    "&$isLastGroup$isLastInGroup": {
      height: "auto",
    },
  },
  image: {
    width: "100%",
  },
  isLastInGroup: {},
  isLastGroup: {},
  isOpen: {
    "&:not($isLastInGroup)": {
      height: 6,
    },

    "&$isLastInGroup": {
      height: 46,
    },
  },
}));

type Props = {
  perk: IPerk;
  isOpen: boolean;
  isLastGroup: boolean;
};

const AccordionPerk: React.FC<Props> = ({ perk, isOpen, isLastGroup }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateToDetailView = () => {
    if (isOpen) {
      navigate({
        pathname: NavigationPath.PerkGroupPage,
        search: `?${PerkGroupName}=${perk.name}`,
      });
    }
  };

  return (
    <>
      {Array.from({ length: Math.min(perk.count, 3) }, (_x, i) => i).map(
        (_i, idx, arr) => (
          <div
            className={classNames(
              classes.perk,
              idx === arr.length - 1 && classes.isLastInGroup,
              isLastGroup && classes.isLastGroup,
              isOpen && classes.isOpen,
            )}
            id={`perk-${perk.usageBarcode}`}
            onClick={navigateToDetailView}
            key={idx}
          >
            <img src={perk.landscapeImage.url} className={classes.image} />
          </div>
        ),
      )}
    </>
  );
};

export default AccordionPerk;
