import { languages, type TLanguageCode } from "countries-list";
import { array, boolean, type InferType, mixed, object, string } from "yup";

import { PersonalCodeCategory } from "./userLayouts";

export const LanguageSchema = mixed<TLanguageCode | "dev">().oneOf([
  ...Object.keys(languages),
  "dev",
] as TLanguageCode[]);
export type Language = InferType<typeof LanguageSchema>;

export type ICountry = {
  id: string;
  name: string;
};

export const IRegionPersonalCodeCategorySchema = object({
  id: string().required(),
  personalCodeCategory: mixed<PersonalCodeCategory>()
    .oneOf(Object.values(PersonalCodeCategory))
    .required(),
  personalCodeCategoryDisplayName: string().required(),
  sortOrder: string().required(),
});
export type IRegionPersonalCodeCategory = InferType<
  typeof IRegionPersonalCodeCategorySchema
>;

export const IRegionLocaleSchema = object({
  id: string().required(),
  name: string().required(),
  code: LanguageSchema.required(),
});
export type IRegionLocale = InferType<typeof IRegionLocaleSchema>;

export const IRegionSchema = object({
  id: string().required(),
  name: string().required(),
  prefix: string().required(),
  domainName: string().required(),
  locales: array(IRegionLocaleSchema).required(),
  isPersonalCodeSelectionEnabled: boolean().required(),
  personalCodeCategories: array(IRegionPersonalCodeCategorySchema).nullable(),
});
export type IRegion = InferType<typeof IRegionSchema>;
