export type AnalyticsAttributes = {
  "data-gtm-id"?: string;
  "data-gtm-label"?: string;
};

// these tags are needed for functioning of Google Analytics
export const GOOGLE_ANALYTICS_ATTRIBUTES = {
  REGISTER_BUTTON_START: {
    "data-gtm-id": "register-btn",
    "data-gtm-label": "signupstart",
  },
  SIGN_IN_BUTTON_START: {
    "data-gtm-id": "signin-btn",
    "data-gtm-label": "signupstart",
  },
  REGISTER_BUTTON_COMPLETE: {
    "data-gtm-id": "register-btn",
    "data-gtm-label": "complete",
  },
  SIGN_IN_BUTTON_COMPLETE: {
    "data-gtm-id": "signin-btn",
    "data-gtm-label": "complete",
  },
  QR_CORE: {
    "data-gtm-id": "qrcode",
    "data-gtm-label": "profile",
  },
  PERSONAL_DATA_CHANGE: {
    "data-gtm-id": "save-change",
    "data-gtm-label": "personal",
  },
  PERSONAL_DATA_CHANGE_SUCCESS: {
    "data-gtm-id": "confirm-change",
    "data-gtm-label": "personal",
  },
  PERSONAL_DATA_CHANGE_DECLINE: {
    "data-gtm-id": "error-change",
    "data-gtm-label": "personal",
  },
  PASSWORD_CHANGE: {
    "data-gtm-id": "save-change",
    "data-gtm-label": "password",
  },
  PASSWORD_CHANGE_SUCCESS: {
    "data-gtm-id": "confirm-change",
    "data-gtm-label": "password",
  },
  PASSWORD_CHANGE_DECLINE: {
    "data-gtm-id": "error-change",
    "data-gtm-label": "password",
  },
  EMAIL_CHANGE: {
    "data-gtm-id": "save-change",
    "data-gtm-label": "emails",
  },
  EMAIL_CHANGE_SUCCESS: {
    "data-gtm-id": "confirm-change",
    "data-gtm-label": "emails",
  },
  EMAIL_PRIMARY_SUCCESS: {
    "data-gtm-id": "primary-change",
    "data-gtm-label": "emails",
  },
  EMAIL_CHANGE_DECLINE: {
    "data-gtm-id": "error-change",
    "data-gtm-label": "emails",
  },
  ADD_EMAIL: {
    "data-gtm-id": "save-add",
    "data-gtm-label": "emailsadd",
  },
  ADD_EMAIL_SUCCESS: {
    "data-gtm-id": "confirm-add",
    "data-gtm-label": "emailsadd",
  },
  ADD_EMAIL_DECLINE: {
    "data-gtm-id": "error-add",
    "data-gtm-label": "emailsadd",
  },
  DELETE_EMAIL: {
    "data-gtm-id": "delete",
    "data-gtm-label": "emails",
  },
  DELETE_EMAIL_SUCCESS: {
    "data-gtm-id": "delete-done",
    "data-gtm-label": "emails",
  },
  DELETE_EMAIL_DECLINE: {
    "data-gtm-id": "delete-error",
    "data-gtm-label": "emails",
  },
  VERIFY_EMAIL_PROFILE: {
    "data-gtm-id": "verify-e-mail",
    "data-gtm-label": "profile",
  },
  VERIFY_EMAIL_EMAILS_LIST: {
    "data-gtm-id": "verify-e-mail",
    "data-gtm-label": "emails",
  },
  PHONE_CHANGE: {
    "data-gtm-id": "save-change",
    "data-gtm-label": "phones",
  },
  PHONE_CHANGE_SUCCESS: {
    "data-gtm-id": "confirm-change",
    "data-gtm-label": "phones",
  },
  PHONE_PRIMARY_SUCCESS: {
    "data-gtm-id": "primary-change",
    "data-gtm-label": "phones",
  },
  PHONE_CHANGE_DECLINE: {
    "data-gtm-id": "error-change",
    "data-gtm-label": "phones",
  },
  ADD_PHONE: {
    "data-gtm-id": "save-change",
    "data-gtm-label": "phonesadd",
  },
  ADD_PHONE_SUCCESS: {
    "data-gtm-id": "confirm-change",
    "data-gtm-label": "phonesadd",
  },
  ADD_PHONE_DECLINE: {
    "data-gtm-id": "error-change",
    "data-gtm-label": "phonesadd",
  },
  DELETE_PHONE: {
    "data-gtm-id": "delete",
    "data-gtm-label": "phones",
  },
  DELETE_PHONE_SUCCESS: {
    "data-gtm-id": "delete-done",
    "data-gtm-label": "phones",
  },
  DELETE_PHONE_DECLINE: {
    "data-gtm-id": "delete-error",
    "data-gtm-label": "phones",
  },
  ADDRESS_CHANGE: {
    "data-gtm-id": "save-change",
    "data-gtm-label": "addresses",
  },
  ADDRESS_CHANGE_SUCCESS: {
    "data-gtm-id": "confirm-change",
    "data-gtm-label": "addresses",
  },
  ADDRESS_CHANGE_DECLINE: {
    "data-gtm-id": "error-change",
    "data-gtm-label": "addresses",
  },
  ADD_ADDRESS: {
    "data-gtm-id": "save-change",
    "data-gtm-label": "addressesadd",
  },
  ADD_ADDRESS_SUCCESS: {
    "data-gtm-id": "confirm-change",
    "data-gtm-label": "addressesadd",
  },
  ADD_ADDRESS_DECLINE: {
    "data-gtm-id": "error-change",
    "data-gtm-label": "addressesadd",
  },
  DELETE_ADDRESS: {
    "data-gtm-id": "delete",
    "data-gtm-label": "addresses",
  },
  DELETE_ADDRESS_SUCCESS: {
    "data-gtm-id": "delete-done",
    "data-gtm-label": "addresses",
  },
  DELETE_ADDRESS_DECLINE: {
    "data-gtm-id": "delete-error",
    "data-gtm-label": "addresses",
  },
  NEWSLETTERS_CHANGE: {
    "data-gtm-id": "save-change",
    "data-gtm-label": "newsletters",
  },
  NEWSLETTERS_CHANGE_SUCCESS: {
    "data-gtm-id": "confirm-change",
    "data-gtm-label": "newsletters",
  },
  NEWSLETTERS_CHANGE_DECLINE: {
    "data-gtm-id": "error-change",
    "data-gtm-label": "newsletters",
  },
};
