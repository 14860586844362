import * as React from "react";
import { memo } from "react";
const SvgEmptyCheckboxSm = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 16,
    height: 16,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      width: 15,
      height: 15,
      x: 0.5,
      y: 0.5,
      fill: "#fff",
      stroke: "#828282",
      rx: 1.5
    }
  )
);
const Memo = memo(SvgEmptyCheckboxSm);
export default Memo;
