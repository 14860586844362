import { post, request } from "@/api";
import type {
  FetchGiftCardRequest,
  IAddGiftCardToPerson,
  IGiftCard,
  IValidateGiftCardRequest,
} from "@/api/interfaces/giftcardsLayout";
import { ApiPath } from "@/api/paths";
import type { ApiRequest, BodyWrapper, RequestResponse } from "@/api/types";

const validateGiftCard: ApiRequest<
  RequestResponse<IGiftCard>,
  BodyWrapper<IValidateGiftCardRequest>
> = async (options) =>
  post({ ...options, api: ApiPath.GiftCard.validate, canFail: true });

const fetchGiftCard: ApiRequest<
  RequestResponse<IGiftCard>,
  FetchGiftCardRequest
> = async ({ barcode, ...options }) =>
  request({ ...options, api: `${ApiPath.GiftCard.base}/${barcode}` });

const addGiftCardToPerson: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IAddGiftCardToPerson>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.GiftCard.add,
    canFail: true,
    shouldParseResponse: false,
  });

const fetchGiftCards: ApiRequest<
  RequestResponse<IGiftCard[]>,
  void
> = async () => request({ api: ApiPath.User.giftCards, canFail: true });

const GiftCardController = {
  validateGiftCard,
  fetchGiftCard,
  addGiftCardToPerson,
  fetchGiftCards,
};

export default GiftCardController;
