export enum AddressType {
  MAILING = "MAILING",
  SHIPPING = "SHIPPING",
  BILLING = "BILLING",
}

export enum ContactType {
  UNKNOWN = "UNKNOWN",
  EMAIL = "EMAIL",
  MOBILE = "MOBILE",
}

export enum ContactStatus {
  UNSPECIFIED = "UNSPECIFIED",
  AWAITING_VERIFICATION = "AWAITING_VERIFICATION",
  ACTIVE = "ACTIVE",
  NOT_WORKING = "NOT_WORKING",
  DISABLED = "DISABLED",
  DELETED = "DELETED",
}

export enum TermsOfServiceStatus {
  NOT_DETERMINED = "NOT_DETERMINED",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export enum PersonalCodeCategory {
  EstonianIdCode = "ESTONIAN_ID_CODE",
  IcelandicSocialSecurityNumber = "ICELANDIC_SOCIAL_SECURITY_NUMBER",
  MalteseIdCode = "MALTESE_ID_CODE",
  None = "NONE",
  Unknown = "UNKNOWN",
}

export type IAddressType = {
  isPrimary: boolean;
  type: AddressType;
};

export type IAddress = {
  id?: number;
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  region: string;
  postalCode: string;
  country: string;
  countryId?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  cityDistrict?: string;
  types: IAddressType[];
  alias?: string;
};

export type IPhone = {
  id: number;
  isPrimary: boolean;
  number: string;
  status?: ContactStatus;
};

export type INewPhone = {
  number: string;
};

export type INewEmail = {
  email: string;
};

export type IEmail = {
  id: number;
  isPrimary: boolean;
  email: string;
  status?: ContactStatus;
};

export type IUserBasicData = {
  firstName: string;
  lastName: string;
  birthDay: string | null;
  personalCode: string;
  personalCodeCategoryId: string;
  regionId: string;
  localeId: string;
  termsOfServiceStatus: TermsOfServiceStatus;
};

export type IUpdatePersonalData = {
  firstName?: string;
  lastName?: string;
  birthDay?: string;
  personalCode?: string;
  personalCodeCategory?: PersonalCodeCategory;
  regionId?: string;
  localeId?: string;
};

export type IUserNewsletter = {
  id: number;
  name: string;
  hasAccepted: boolean;
};

export type IUserData = IUserBasicData & {
  guid: string;
  addresses: IAddress[];
  phones: IPhone[];
  emails: IEmail[];
  newsletters: IUserNewsletter[];
};

export type IPrimaryContact = {
  id: number;
  primaryContactTypes?: ContactType[];
};

export type IPrimaryAddress = {
  id: number;
  types: IAddressType[];
};

export type IUserPasswordChangeRequest = {
  newPassword: string;
  newPasswordVerify: string;
  oldPassword: string;
};

export type IKeyword = {
  key: string;
};

export type IBeginUserRegistration = {
  firstName: string;
  lastName: string;
  email: string;
  agreeTerms: boolean;
};

export type ICompleteUserRegistration = {
  birthDay?: string;
  keywords: IKeyword[];
  password: string;
  personalCode?: string;
  personalCodeCategory: PersonalCodeCategory;
  phoneNumber?: string;
  preferredClientTerritoryId?: string;
  preferredLanguageId?: string;
  verificationCode: string;
};

export type ICompleteUserRegistrationErrorResponse = {
  code: string;
  message: string;
  validationErrors: {
    parameterName: string;
    message: string;
    code: string;
  }[];
};

export type IUpdateNewsletter = {
  key: number;
  hasAccepted: boolean;
};

export type IUpdateNewsletters = {
  newsletters: IUpdateNewsletter[];
};

export type IAcceptTermsRequest = {
  hasAccepted: boolean;
};

export type IRequestPasswordReset = {
  email: string;
};

export type IResetPasswordRequest = {
  verificationCode: string;
  password: string;
};

export type IActivatePersonContactRequest = {
  verificationCode: string;
};

export type DeleteEmailRequest = {
  id: number;
};

export type DeleteAddressRequest = {
  id: number;
};

export type DeletePhoneRequest = {
  id: number;
};

export type IClosePersonAccountRequest = {
  id: string;
};
