import type React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import config from "@/constants/config";
import { NavigationPath } from "@/constants/navigation";

const EmptyPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (config.routerConfig.routerMode === "giftCard") {
      navigate(NavigationPath.GiftCardStatus, { replace: true });
    } else {
      navigate(NavigationPath.Home, { replace: true });
    }
  });

  return null;
};

export default EmptyPage;
