import type React from "react";
import { isPresent } from "@apl-digital/utils";

import type { IMyCollectionInfo } from "@/api/interfaces/myCollectionInfoLayout";
import { SectionType } from "@/api/interfaces/pageLayout";
import PerkBlock from "@/base/blocks/PerkBlock/PerkBlock";
import { CmsBlocks } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import config from "@/constants/config";
import {
  getBlock,
  isLastBlock,
} from "@/pages/HomePage/components/helper/cmsHelper";
import IntroductionBlocks from "@/pages/HomePage/components/IntroductionBlocks";
import LocationsBlock from "@/pages/HomePage/components/LocationsBlock";
import MyCollectionBlock from "@/pages/HomePage/components/MyCollectionBlock";
import MyInfoBlock from "@/pages/ProfilePage/components/MyInfoBlock";
import TransactionsBlock from "@/pages/Transactions/components/TransactionsBlock";
import { fetchPrivateHomePage } from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { selectCurrentRegionPrefix } from "@/store/region";

const PrivateHomePage: React.FC = () => {
  const [{ isCurrentSizeMd }] = useMediaQuery();
  const privateHomePage = useAppSelector((state) => state.cms.privateHomePage);
  const currentRegionPrefix = useAppSelector(selectCurrentRegionPrefix);
  const dispatch = useAppDispatch();

  return (
    <CmsPage
      pageStateMachine={privateHomePage}
      fetchPage={() => dispatch(fetchPrivateHomePage())}
    >
      {({ page }) => (
        <CmsBlocks>
          {page.components.map((c, idx, arr) => {
            switch (c.type) {
              case SectionType.LOYALTY_CARD:
                return getBlock(
                  <MyInfoBlock showCard minimal />,
                  idx,
                  isLastBlock(arr.length, idx),
                );
              case SectionType.BENEFITS_DETAILS:
                return getBlock(
                  <IntroductionBlocks block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                );
              case SectionType.LOCATION:
                return getBlock(
                  <LocationsBlock block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                );
              case SectionType.MY_COLLECTION:
                return getBlock(
                  <MyCollectionBlock block={c as IMyCollectionInfo} />,
                  idx,
                  isLastBlock(arr.length, idx),
                );
              case SectionType.GIFT_CARDS:
                return getBlock(
                  <PerkBlock
                    block={c}
                    showTable={isCurrentSizeMd}
                    showDescription={isCurrentSizeMd}
                  />,
                  idx,
                  isLastBlock(arr.length, idx),
                );
              case SectionType.TRANSACTIONS:
                if (
                  isPresent(currentRegionPrefix) &&
                  !config.featureFlags.enableTransactions.includes(
                    currentRegionPrefix,
                  )
                ) {
                  return null;
                }

                return getBlock(
                  <TransactionsBlock block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                );

              default:
                return null;
            }
          })}
        </CmsBlocks>
      )}
    </CmsPage>
  );
};

export default PrivateHomePage;
