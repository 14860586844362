import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AddressType, type IAddress } from "@/api/interfaces/userLayouts";
import { H5, Loader, P } from "@/base/components/Global";
import Button from "@/base/components/Global/Button";
import { Col, Row, RowBreak } from "@/base/components/Layout";
import {
  getBillingAddress,
  getOtherAddresses,
  getShippingAddress,
} from "@/base/utils/userUtil";
import { ADD_ICON, ADDRESS_ICON } from "@/constants/blobIcons";
import { NavigationPath } from "@/constants/navigation";
import RenderAddress from "@/pages/Address/RenderAddress";
import { useAppSelector } from "@/store/hooks";
import { selectUserData } from "@/store/user";

const AddressesPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  const [isLoading, setIsLoading] = useState(true);
  const [billingAddress, setBillingAddress] = useState<IAddress>();
  const [shippingAddress, setShippingAddress] = useState<IAddress>();
  const [otherAddresses, setOtherAddresses] = useState<IAddress[]>();

  useEffect(() => {
    if (userData.status === "succeeded") {
      setBillingAddress(getBillingAddress(userData));
      setShippingAddress(getShippingAddress(userData));
      setOtherAddresses(getOtherAddresses(userData));
      setIsLoading(false);
    }
  }, [userData]);

  if (isLoading) return <Loader isMinified />;

  const hasAddresses =
    !!billingAddress || !!shippingAddress || !!otherAddresses;

  return (
    <Row>
      <Row noMargin hide={hasAddresses}>
        <Col col={12} directionColumn>
          <H5 gray1 prefixIcon={ADDRESS_ICON}>
            {t("my_address_page_title")}
          </H5>
          <P gray3 small noMarginTop>
            {t("my_address_page_subtitle")}
          </P>
        </Col>
      </Row>
      {billingAddress && (
        <RenderAddress type={AddressType.BILLING} address={billingAddress} />
      )}
      {shippingAddress && (
        <RenderAddress type={AddressType.SHIPPING} address={shippingAddress} />
      )}
      {otherAddresses?.map((address) => (
        <RenderAddress
          type={AddressType.MAILING}
          address={address}
          key={address.id}
        />
      ))}
      <Row noMargin>
        <RowBreak />
        <Col col={12} colMd={4}>
          <Button
            action={() => navigate(NavigationPath.AddAddress)}
            transparentBrand
            prefixIcon={ADD_ICON}
            isActive
          >
            {t("add_address")}
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default AddressesPage;
