import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import type { GiftCardType, IGiftCard } from "@/api/interfaces/giftcardsLayout";
import type { IPerk, PerkType } from "@/api/interfaces/perkLayouts";
import { H5, Icon, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { formatDate, formatMoney } from "@/base/utils/formatting";
import { scrollToWithOffset } from "@/base/utils/scrollHelpers";
import {
  COPY_SMALL_ICON,
  SHOW_ICON,
  TRANSACTION_ICON,
} from "@/constants/blobIcons";
import PerkTransactions from "@/pages/PerkPage/components/PerkTransactions";
import {
  getPerkInfoTranslationByType,
  getPerkNumberTranslationByType,
  shouldShowBalance,
  shouldShowPin,
  shouldShowTransactions,
} from "@/pages/PerkPage/helpers/perkHelper";
import { useAppSelector } from "@/store";
import { selectCurrentLanguage } from "@/store/language";
import { createUseStyles, getTheme } from "@/theme";

const TRANSACTIONS_ID = "gift-card-transactions";

const useStyles = createUseStyles(({ spacing, color }) => ({
  valueRow: {
    borderBottom: `1px solid ${color.gray5}`,
  },
  infoBlock: {
    paddingBottom: spacing.xl,
  },
  valueBlock: {
    width: "100%",
    padding: [spacing.xl, 0],
  },
  rowAction: {
    gap: spacing.s,
  },
  iconAction: {
    cursor: "pointer",
  },
  amount: {
    "& span": {
      "& p": {
        whiteSpace: "nowrap",
      },
    },
  },
}));

type Props = {
  perk: IGiftCard | IPerk;
  perkType?: PerkType | GiftCardType;
  hideImage?: boolean;
  isCardMode?: boolean;
  barcode: string;
};

const PerkDetails: React.FC<Props> = ({
  perk,
  perkType,
  hideImage = false,
  isCardMode = false,
  barcode,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { color } = getTheme();
  const [isRevealed, setIsRevealed] = useState(false);
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  const scrollToTransactions = () => {
    if (perk.transactions && perk.transactions.length > 0) {
      scrollToWithOffset(TRANSACTIONS_ID);
    }
  };

  return (
    <Col col={12} noMargin={isCardMode} directionColumn alignCenter>
      {!isCardMode && (
        <Col
          col={120}
          noMargin={isCardMode}
          hide={hideImage || !perk.landscapeImage?.url}
        >
          <img src={perk.landscapeImage?.url} style={{ width: "32cqb" }} />
        </Col>
      )}

      <Col col={12} noMarginBottom>
        <H5 gray1>{perk.name}</H5>
      </Col>
      <Col col={12} noMarginTop>
        <P gray1>
          {t("gift_card_valid")
            .replace("%start%", formatDate(perk.validFrom, false, "."))
            .replace("%end%", formatDate(perk.validUntil, false, "."))}
        </P>
      </Col>
      <Col col={12} noMargin className={classes.valueBlock} directionColumn>
        {shouldShowBalance(perkType) && (
          <>
            <Row noMargin>
              <Col col={9} noMargin>
                <P gray2>{t("amount")}</P>
              </Col>
              <Col col={3} noMargin alignEnd className={classes.rowAction}>
                <P bold gray1 className={classes.amount}>
                  {formatMoney(perk.balance, currentLanguage, true)}
                </P>
                <Icon
                  icon={TRANSACTION_ICON}
                  width={24}
                  height={24}
                  stroke={color.primaryBrand}
                  className={classes.iconAction}
                  onClick={scrollToTransactions}
                />
              </Col>
            </Row>
            <Row noMargin className={classes.valueRow} />
          </>
        )}
        <Row noMargin>
          <Col col={6} noMargin>
            {/* @ts-expect-error: waits for translations to accept dynamic keys */}
            <P gray2>{t(getPerkNumberTranslationByType(perkType))}</P>
          </Col>
          <Col col={6} alignEnd noMargin className={classes.rowAction}>
            <P bold gray1>
              {barcode}
            </P>
            <Icon
              icon={COPY_SMALL_ICON}
              width={24}
              height={24}
              stroke={color.primaryBrand}
              onClick={() => navigator.clipboard.writeText(barcode)}
              className={classes.iconAction}
            />
          </Col>
        </Row>
        {shouldShowPin(perkType) && "verificationCode" in perk && (
          <>
            <Row noMargin className={classes.valueRow} />
            {isAuthenticated && (
              <Row noMargin>
                <Col col={6} noMargin>
                  <P gray2>{t("gift_card_verificationcode")}</P>
                </Col>
                <Col col={6} alignEnd noMargin className={classes.rowAction}>
                  <P bold gray1>
                    {isRevealed ? perk.verificationCode : "******"}
                  </P>
                  <Icon
                    icon={SHOW_ICON}
                    width={24}
                    height={24}
                    stroke={color.primaryBrand}
                    onClick={() => setIsRevealed(!isRevealed)}
                    className={classes.iconAction}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
      <Col col={12} noHorizontalMargin className={classes.infoBlock}>
        <P small gray1>
          {/* @ts-expect-error: waits for translations to accept dynamic keys */}
          {t(getPerkInfoTranslationByType(perkType))}
        </P>
      </Col>
      {shouldShowTransactions(perkType) &&
        perk.transactions &&
        perk.transactions.length > 0 && (
          <PerkTransactions
            transactions={perk.transactions}
            id={TRANSACTIONS_ID}
          />
        )}
    </Col>
  );
};

export default PerkDetails;
