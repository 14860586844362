import type React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";

import UserController from "@/api/controller/userController";
import VerificationCodeController from "@/api/controller/verificationCodeController";
import {
  AddressType,
  ContactType,
  type IActivatePersonContactRequest,
  type IAddress,
  type IEmail,
  type INewEmail,
  type INewPhone,
  type IPhone,
  type IUpdateNewsletter,
  type IUpdatePersonalData,
  type IUserPasswordChangeRequest,
} from "@/api/interfaces/userLayouts";
import type {
  UserContextProps,
  UserContextState,
  UserProviderProps,
} from "@/base/components/UserProvider/types";
import { isInIframe } from "@/base/utils/iframeUtils";
import { addLocaleParam } from "@/base/utils/uriHelper";
import { hasAcceptedTerms } from "@/base/utils/userUtil";
import config from "@/constants/config";
import { NavigationPath } from "@/constants/navigation";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { selectCurrentLanguage } from "@/store/language/selectors";
import {
  selectCurrentRegion,
  selectCurrentRegionPrefix,
} from "@/store/region/selectors";
import { clearUser, fetchUserData, selectUserData } from "@/store/user";

const DEFAULT_STATE: UserContextState = {
  hasTriedSilentSignin: false,
};

const UserContext = createContext<UserContextProps | undefined>(undefined);

const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const userData = useAppSelector(selectUserData);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const currentRegion = useAppSelector(selectCurrentRegion);
  const currentRegionPrefix = useAppSelector(selectCurrentRegionPrefix);
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const [hasTriedSilentSignin, setHasTriedSilentSignin] = useState(
    DEFAULT_STATE.hasTriedSilentSignin,
  );
  useEffect(() => {
    if (
      userData.status !== "succeeded" ||
      (userData.status === "succeeded" &&
        hasAcceptedTerms(userData.termsOfServiceStatus))
    ) {
      return;
    }
    if (config.routerConfig.routerMode === "giftCard") {
      window.open(
        `${config.routerConfig.loyaltyProfileUrl}${NavigationPath.AcceptTermsAndConditionsPage}`,
        "_self",
      );
    } else {
      navigate(NavigationPath.AcceptTermsAndConditionsPage);
    }
  }, [location.pathname, userData]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      void dispatch(fetchUserData());
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (
      !isInIframe() &&
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSilentSignin &&
      (config.routerConfig.routerMode === "profile" ||
        config.routerConfig.routerMode === "giftCard") &&
      location.pathname !== NavigationPath.AuthComplete
    ) {
      void auth.signinSilent();
      setHasTriedSilentSignin(true);
    }
  }, [auth, hasTriedSilentSignin]);

  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      void auth.removeUser();
    });
  }, [auth.events]);

  const logIn = (): void => {
    if (config.routerConfig.routerMode === "giftCard") {
      window.open(
        addLocaleParam(
          `${globalThis.origin}${NavigationPath.AuthStart}`,
          currentLanguage,
          currentRegionPrefix,
        ),
        "_self",
      );
    } else if (config.routerConfig.routerMode === "profile") {
      navigate(NavigationPath.AuthStart);
    } else {
      window.open(
        addLocaleParam(
          `${config.routerConfig.loyaltyProfileUrl}${NavigationPath.AuthStart}`,
          currentLanguage,
          currentRegionPrefix,
        ),
        "_self",
      );
    }
  };

  const logOut = async (redirect?: string): Promise<void> => {
    const postLogoutDefaultRedirectUri =
      config.routerConfig.routerMode === "profile"
        ? (currentRegion?.domainName ?? config.routerConfig.loyaltyHubUrl)
        : globalThis.origin;

    dispatch(clearUser());

    await auth.signoutRedirect({
      post_logout_redirect_uri: redirect || postLogoutDefaultRedirectUri,
    });
  };

  const changePassword = async (
    body: IUserPasswordChangeRequest,
  ): Promise<boolean> => {
    const { isResponseOk } = await UserController.changePassword({
      body,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const addEmail = async (email: INewEmail): Promise<boolean> => {
    const { isResponseOk } = await UserController.addEmail({
      body: email,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const removeEmail = async (id: number): Promise<boolean> => {
    const { isResponseOk } = await UserController.deleteEmail({
      id,
      language: currentLanguage,
      region: currentRegionPrefix,
    });

    return isResponseOk;
  };

  const updateEmail = async (email: IEmail): Promise<boolean> => {
    const { isResponseOk } = await UserController.updateEmail({
      body: email,
      language: currentLanguage,
      region: currentRegionPrefix,
    });

    return isResponseOk;
  };

  const addPhone = async (phone: INewPhone): Promise<boolean> => {
    const { isResponseOk } = await UserController.addPhone({
      body: phone,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const removePhone = async (id: number): Promise<boolean> => {
    const { isResponseOk } = await UserController.deletePhone({
      id,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) await dispatch(fetchUserData());

    return isResponseOk;
  };

  const updatePhone = async (phone: IPhone): Promise<boolean> => {
    const { isResponseOk } = await UserController.updatePhone({
      body: phone,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const addAddress = async (address: IAddress): Promise<boolean> => {
    const { isResponseOk } = await UserController.addAddress({
      body: address,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const removeAddress = async (id: number): Promise<boolean> => {
    const { isResponseOk } = await UserController.deleteAddress({
      id,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const updateAddress = async (address: IAddress): Promise<boolean> => {
    const { isResponseOk } = await UserController.updateAddress({
      body: address,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const updateProfile = async (user: IUpdatePersonalData): Promise<boolean> => {
    const { isResponseOk } = await UserController.updateProfile({
      body: user,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const setPrimaryEmail = async (id: number): Promise<boolean> => {
    const { isResponseOk } = await UserController.setPrimaryContact({
      body: { id, primaryContactTypes: [ContactType.EMAIL] },
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const setPrimaryAddress = async (
    address: IAddress,
    type: AddressType,
  ): Promise<boolean> => {
    const { isResponseOk } = await UserController.setPrimaryAddress({
      body: {
        id: address.id ?? 0,
        types: [
          {
            isPrimary: true,
            type: type,
          },
        ],
      },
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const setAddressPrimaryBilling = async (
    address: IAddress,
  ): Promise<boolean> => setPrimaryAddress(address, AddressType.BILLING);
  const setAddressPrimaryShipping = async (
    address: IAddress,
  ): Promise<boolean> => setPrimaryAddress(address, AddressType.SHIPPING);
  const setAddressPrimaryMailing = async (
    address: IAddress,
  ): Promise<boolean> => setPrimaryAddress(address, AddressType.MAILING);

  const setPrimaryPhone = async (id: number): Promise<boolean> => {
    const { isResponseOk } = await UserController.setPrimaryContact({
      body: { id, primaryContactTypes: [ContactType.MOBILE] },
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const updateNewsletters = async (
    newsletters: IUpdateNewsletter[],
  ): Promise<boolean> => {
    const { isResponseOk } = await UserController.updateNewsletters({
      body: newsletters,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) void dispatch(fetchUserData());

    return isResponseOk;
  };

  const acceptTerms = async (hasAccepted: boolean) => {
    const { isResponseOk } = await UserController.acceptTerms({
      body: { hasAccepted },
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) {
      await dispatch(fetchUserData()).unwrap();
      return true;
    }
    return false;
  };

  const activateContact = async (
    body: IActivatePersonContactRequest,
  ): Promise<boolean> => {
    const { isResponseOk } = await UserController.activateContact({
      body,
      language: currentLanguage,
      region: currentRegionPrefix,
    });
    if (isResponseOk) {
      void dispatch(fetchUserData());
      await VerificationCodeController.expireCode({
        body: { code: body.verificationCode },
        language: currentLanguage,
        region: currentRegionPrefix,
      });
    }
    return isResponseOk;
  };

  return (
    <UserContext.Provider
      value={[
        {
          logIn,
          logOut,
          changePassword,
          addEmail,
          removeEmail,
          updateEmail,
          addPhone,
          updatePhone,
          removePhone,
          updateProfile,
          addAddress,
          updateAddress,
          removeAddress,
          setPrimaryEmail,
          setPrimaryPhone,
          setAddressPrimaryBilling,
          setAddressPrimaryShipping,
          setAddressPrimaryMailing,
          updateNewsletters,
          acceptTerms,
          activateContact,
        },
        {
          hasTriedSilentSignin,
        },
      ]}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
export { useUser };
