import { AddressType, type IAddress } from "@/api/interfaces/userLayouts";
import { isAddressOfType } from "@/base/utils/userUtil";
import {
  ADDRESS_BILLING_ICON,
  ADDRESS_DELIVERY_ICON,
  ADDRESS_ICON,
} from "@/constants/blobIcons";

export type AddressLabels = {
  title: string;
  subTitle: string;
  iconUrl: string;
};

export const getAddressLabels = (
  type: AddressType,
  address?: IAddress,
): AddressLabels => {
  let title = "other_address_title";
  let subTitle = "other_address_subtitle";
  let iconUrl = ADDRESS_ICON;

  if (address?.types && address.types.length > 0) {
    if (
      type === AddressType.SHIPPING &&
      isAddressOfType(AddressType.SHIPPING, address)
    ) {
      title = "shipping_address_title";
      subTitle = "shipping_address_subtitle";
      iconUrl = ADDRESS_DELIVERY_ICON;
    } else if (
      type === AddressType.BILLING &&
      isAddressOfType(AddressType.BILLING, address)
    ) {
      title = "billing_address_title";
      subTitle = "billing_address_subtitle";
      iconUrl = ADDRESS_BILLING_ICON;
    } else if (isAddressOfType(AddressType.MAILING, address)) {
      title = "mailing_address_title";
      subTitle = "mailing_address_subtitle";
    }
  }

  return {
    title,
    subTitle,
    iconUrl,
  };
};
