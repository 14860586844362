export enum BenefitType {
  ADMISSION_BASED = "ADMISSION_BASED",
  PRODUCT_BASED = "PRODUCT_BASED",
  MONEY_BASED = "MONEY_BASED",
}

export type IUserBenefit = {
  type: BenefitType;
  name: string;
  targetValue: number;
  currentValue: number;
  externalIdentifier: string;
  territoryId: string;
};
