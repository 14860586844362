import {
  AddressType,
  ContactStatus,
  type IAddress,
  type IEmail,
  type IUserData,
  TermsOfServiceStatus,
} from "@/api/interfaces/userLayouts";
import config from "@/constants/config";

export const getPrimaryEmail = (user: IUserData | null): IEmail | undefined => {
  return user?.emails && user.emails.length > 0
    ? user.emails.find((e) => e.isPrimary)
    : undefined;
};

export const getPrimaryEmailValue = (
  user: IUserData | null,
): string | undefined =>
  user?.emails && user.emails.length > 0
    ? user.emails.find((c) => c.isPrimary)?.email
    : undefined;

export const isContactVerified = (status?: ContactStatus): boolean =>
  status === ContactStatus.ACTIVE;

export const getPrimaryPhone = (user: IUserData | null): string | undefined =>
  user?.phones && user.phones.length > 0
    ? user?.phones.find((c) => c.isPrimary)?.number
    : undefined;

export const getAddressesByType = (
  user: IUserData | null,
  type: AddressType,
): IAddress[] => {
  if (user?.addresses) {
    return user?.addresses.filter((c) =>
      c.types?.some((el) => el.type === type && el.isPrimary),
    );
  }
  return [];
};

export const getBillingAddress = (
  user: IUserData | null,
): IAddress | undefined => {
  const billingAddresses = getAddressesByType(user, AddressType.BILLING);
  return billingAddresses.length > 0 ? billingAddresses[0] : undefined;
};

export const getShippingAddress = (
  user: IUserData | null,
): IAddress | undefined => {
  const shippingAddresses = getAddressesByType(user, AddressType.SHIPPING);
  return shippingAddresses.length > 0 ? shippingAddresses[0] : undefined;
};

export const getOtherAddresses = (
  user: IUserData | null,
): IAddress[] | undefined => {
  const otherAddresses = user?.addresses.filter(
    (address) =>
      address.types.some(
        (el) => !el.isPrimary || el.type === AddressType.MAILING,
      ) || address.types.length === 0,
  );

  return otherAddresses && otherAddresses.length > 0
    ? otherAddresses
    : undefined;
};

export const isAddressOfType = (
  type: AddressType,
  address?: IAddress,
): boolean => {
  if (!address) return false;
  return (
    address?.types.filter((el) => el.type === type && el.isPrimary)?.length > 0
  );
};

export const validateEmail = (email?: string): boolean => {
  if (!email) return false;
  return Boolean(
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/.test(
      email,
    ),
  );
};

export const validatePhone = (phone: string) => phone && phone.length > 5;

export const getQRCodeURL = (width: number, guid?: string) =>
  `${config.assetConfig.images.url}?width=${width}&qrpayload=${guid}`;

export const hasAcceptedTerms = (termsStatus?: TermsOfServiceStatus): boolean =>
  termsStatus === TermsOfServiceStatus.ACCEPTED;
