import type React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import ApolloCardSmall from "@/assets/svg/ApolloClubCardSmall.svg?react";
import { Button, H1, Icon, Link, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { useUser } from "@/base/components/UserProvider";
import { LOGIN_ICON } from "@/constants/blobIcons";
import { NavigationPath } from "@/constants/navigation";
import { getTheme } from "@/theme";

const SignInBlock: React.FC = () => {
  const { t } = useTranslation();
  const [{ logIn }] = useUser();
  const { isAuthenticated } = useAuth();
  const { color } = getTheme();

  if (isAuthenticated) {
    // Authenticated User should not have any visible block
    return null;
  }

  return (
    <>
      <Row aboveMd>
        <Col col={6} directionColumn noMarginLeft>
          <Row noMarginLeft>
            <Col col={12} noMarginBottom noMarginLeft>
              <H1 noMargin>{t("welcome_title")}</H1>
            </Col>
          </Row>
          <Row noMarginLeft>
            <Col col={12} noMarginLeft>
              <P black noMargin noMarginLeft>
                {t("sign_in_or_register_message")}
              </P>
            </Col>
          </Row>
          <Row noMarginLeft>
            <Col col={12} noMarginLeft>
              <Link brandColor navigateTo={NavigationPath.SignUpStart}>
                {t("register_user")}
              </Link>
            </Col>
          </Row>
        </Col>
        <Col col={6} directionColumn noHorizontalMargin>
          <Row>
            <Col col={8}>
              <Link flex action={logIn}>
                <ApolloCardSmall />
              </Link>
              <Row noMargin style={{ alignSelf: "flex-end" }}>
                <Col noVerticalMargin directionColumn>
                  <Link bold action={logIn}>
                    {t("sign_in_button")}
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col
              alignEnd
              directionColumn
              justifyCenter
              style={{ alignSelf: "flex-end" }}
              noHorizontalMargin
            >
              <P xSmall noMargin>
                {t("new_user")}
              </P>
              <Link underline navigateTo={NavigationPath.SignUpStart} xSmall>
                {t("create_account")}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col col={12} noMarginRight>
              <Button
                data-testid="sign-in-button"
                prefixIcon={<Icon icon={LOGIN_ICON} stroke={color.white} />}
                action={logIn}
              >
                {t("sign_in_button")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row belowSm>
        <Col col={8}>
          <Link flex action={logIn}>
            <ApolloCardSmall />
          </Link>
          <Row>
            <Col noMargin directionColumn>
              <Link bold action={logIn}>
                {t("sign_in_button")}
              </Link>
              <P xSmall noMargin>
                {t("sign_in_or_register_message")}
              </P>
            </Col>
          </Row>
        </Col>
        <Col alignEnd directionColumn justifyCenter>
          <P xSmall noMargin>
            {t("new_user")}
          </P>
          <Link underline navigateTo={NavigationPath.SignUpStart} xSmall>
            {t("create_account")}
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default SignInBlock;
