import type React from "react";
import { useTranslation } from "react-i18next";

import { H5, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { GENERIC_LOCATION_ICON } from "@/constants/blobIcons";

const LocationTitleBlock: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row id="apollo-locations">
      <Col noMarginBottom noHorizontalMargin col={12} directionColumn>
        <H5 gray1 prefixIcon={GENERIC_LOCATION_ICON}>
          {t("apollo_club_locations")}
        </H5>
        <P gray3>{t("club_location_preference_msg")}</P>
      </Col>
    </Row>
  );
};

export default LocationTitleBlock;
