export enum VerificationCodeStatus {
  UNKNOWN = "UNKNOWN",
  VALID = "VALID",
  INVALID_ARGUMENTS = "INVALID_ARGUMENTS",
  ALREADY_VALIDATING = "ALREADY_VALIDATING",
  ALREADY_VALIDATED = "ALREADY_VALIDATED",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
  VERIFICATION_CODE_MISMATCH = "VERIFICATION_CODE_MISMATCH",
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
  ERROR = "ERROR",
}

export enum VerificationAction {
  REGISTRATION = "registration",
  PASSWORD_RESET = "password-reset",
  CLOSE_ACCOUNT = "close-account",
  VERIFY_EMAIL = "verify-email",
}

export type IValidateVerificationCodeResponse = {
  status: VerificationCodeStatus;
};

export type IVerifyVerificationCode = {
  code: string;
};

export type IRequestNewVerificationCodeRequest = {
  email: string;
  code: string;
};

export type ValidateCodeRequest = {
  code: string;
};
