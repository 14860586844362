import type React from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

import { Icon, Link } from "@/base/components/Global";
import type { StyleProps } from "@/base/components/Global/P";
import { NavigationPath } from "@/constants/navigation";
import { createUseStyles, getTheme } from "@/theme";

const useStyles = createUseStyles<StyleProps>(
  ({ font, color, spacing, borderRadius }) => ({
    container: {
      backgroundColor: color.black,
      position: "fixed",
      bottom: 0,
    },
    link: {
      width: "100%",
      justifyContent: "center",
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
      flexDirection: "column",
      fontSize: font.size.x3s,
    },
    iconWrapper: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
    notificationsCount: {
      height: 16,
      position: "absolute",
      top: -spacing.xxs,
      right: -spacing.m,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      fontSize: font.size.xxs,
      backgroundColor: color.primaryBrand,
      borderRadius: borderRadius.s,
      color: color.black,
      paddingRight: spacing.xs,
      paddingLeft: spacing.xs,
    },
    hasNotifications: {
      "&:after": {
        content: "''",
        position: "absolute",
        top: -spacing.xxs,
        right: -spacing.s + spacing.xs,
        width: 12,
        height: 12,
        borderRadius: "50%",
        backgroundColor: color.primaryBrand,
      },
    },
  }),
);

type Props = {
  pathname?: string;
  action?: () => void;
  icon: string;
  activeIcon?: string;
  notificationsCount?: number;
  hasNotifications?: boolean;
  caption: string;
};

const TabBarButton: React.FC<Props> = ({
  pathname,
  icon,
  action,
  notificationsCount,
  hasNotifications,
  activeIcon,
  caption,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const theme = getTheme();
  const isActive = Boolean(
    location.pathname === pathname ||
      (pathname &&
        pathname !== NavigationPath.Home &&
        location.pathname.includes(pathname)),
  );

  return (
    <Link
      flex
      action={action}
      navigateTo={pathname}
      className={classes.link}
      linkColor={isActive ? theme.color.primaryBrand : theme.color.gray4}
      isActive={isActive}
      x3Small
    >
      <div
        className={classnames([
          classes.iconWrapper,
          hasNotifications && classes.hasNotifications,
        ])}
      >
        {Boolean(notificationsCount) && (
          <div className={classes.notificationsCount}>{notificationsCount}</div>
        )}
        <Icon icon={isActive && activeIcon ? activeIcon : icon} />
      </div>
      {caption}
    </Link>
  );
};

export default TabBarButton;
