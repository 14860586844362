import type React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import UserController from "@/api/controller/userController";
import type { IBeginUserRegistration } from "@/api/interfaces/userLayouts";
import { ApiPath } from "@/api/paths";
import CheckboxRow from "@/base/components/Form/v1/CheckboxRow";
import TextField from "@/base/components/Form/v1/TextField";
import FormProvider, {
  FormSize,
  useFormProvider,
} from "@/base/components/FormProvider";
import { Button, H5, Link, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import { useUser } from "@/base/components/UserProvider";
import { LocalStorageKey, setStorage } from "@/base/utils/storage";
import { TERMS_AND_CONDITIONS_ICON } from "@/constants/blobIcons";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";

const BeginUserRegistration = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentModal, setCurrentModal] = useState<"success" | "error">();
  const [{ logIn }] = useUser();
  const [{ schema }, { getField }] = useFormProvider();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resolver =
    schema && Object.keys(schema).length > 0 ? yupResolver(schema) : undefined;

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid, isSubmitted },
  } = useForm<IBeginUserRegistration>({
    mode: "onChange",
    resolver,
  });

  const onSubmit = async (body: IBeginUserRegistration) => {
    // Temp solution until email registration starts working again
    setStorage(LocalStorageKey.REGISTRATION_FIRST_NAME, body.firstName);
    setIsSubmitting(true);
    const { isResponseOk } = await UserController.registerStart({ body });
    setIsSubmitting(false);
    setCurrentModal(isResponseOk ? "success" : "error");
  };

  const hasError = (inputName: keyof IBeginUserRegistration) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  const firstNameField = getField("firstName");
  const lastNameField = getField("lastName");
  const emailField = getField("email");
  const agreeTermsField = getField("agreeTerms");

  return (
    <Row noMargin>
      <Col aboveMd colMd={3} />
      <Col col={12} colMd={6} noMargin directionColumn>
        <Row>
          <Col col={12} directionColumn>
            <H5>{t("welcome_msg")}</H5>
            <P>{t("apollo_benefits_register_msg")}</P>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {firstNameField?.visible && (
              <FormSize size={firstNameField.size}>
                <TextField
                  label={t("field_firstName_label")}
                  id="first-name-input"
                  autoComplete="name"
                  autoFocus
                  onEnter={{ trigger, name: "lastName" }}
                  placeholder={t("field_firstName_placeholder")}
                  helperText={
                    hasError("firstName") && t("field_firstName_error")
                  }
                  hasError={hasError("firstName")}
                  {...register("firstName")}
                />
              </FormSize>
            )}
            {lastNameField?.visible && (
              <FormSize size={lastNameField.size}>
                <TextField
                  label={t("field_lastName_label")}
                  id="last-name-input"
                  autoComplete="name"
                  onEnter={{ trigger, name: "email" }}
                  placeholder={t("field_lastName_placeholder")}
                  helperText={hasError("lastName") && t("field_lastName_error")}
                  hasError={hasError("lastName")}
                  {...register("lastName")}
                />
              </FormSize>
            )}
            {emailField?.visible && (
              <FormSize size={emailField.size}>
                <TextField
                  label={t("field_enter_email_label")}
                  id="email-input-bar"
                  autoComplete="email"
                  type="email"
                  submitOnEnter
                  placeholder={t("field_email_placeholder")}
                  helperText={hasError("email") && t("field_email_error")}
                  hasError={hasError("email")}
                  {...register("email")}
                />
              </FormSize>
            )}
            {agreeTermsField?.visible && (
              <FormSize size={agreeTermsField.size}>
                <CheckboxRow
                  {...register("agreeTerms")}
                  icon={TERMS_AND_CONDITIONS_ICON}
                  label={t("privacy_policy_agree_check")}
                  hasError={hasError("agreeTerms")}
                  errorText={
                    hasError("agreeTerms") && t("privacy_policy_agree_error")
                  }
                  hintText={t("privacy_policy_agree_message")}
                  hintLink={{
                    url: t("terms_conditions_checkbox_link_url"),
                    text: t("terms_conditions_checkbox_link_text"),
                  }}
                />
              </FormSize>
            )}
            <Col col={12}>
              <P xSmall bold>
                {t("signup_conditions_text")}{" "}
                <Link xSmall bold href={t("signup_conditions_link_url")}>
                  {t("signup_conditions_link_text")}
                </Link>
              </P>
            </Col>
            <Col col={12}>
              <Button
                type="submit"
                isDisabled={!isValid}
                id="submit-btn"
                isLoading={isSubmitting}
                analyticsAttributes={
                  GOOGLE_ANALYTICS_ATTRIBUTES.REGISTER_BUTTON_START
                }
              >
                {t("register_user")}
              </Button>
              <SimpleModalWrapper
                isOpen={currentModal === "success"}
                onClose={() => {
                  navigate(NavigationPath.Home);
                }}
                title={t("registration_step1_success_modal_title")}
                subTitle={t("registration_step1_success_modal_subtitle")}
                content={t("registration_step1_success_modal_content")}
              />
              <SimpleModalWrapper
                isOpen={currentModal === "error"}
                onClose={() => {
                  setCurrentModal(undefined);
                }}
                title={t("registration_step1_error_modal_title")}
                subTitle={t("registration_step1_error_modal_subtitle")}
                content={t("registration_step1_error_modal_content")}
              />
            </Col>
          </Row>
        </form>
        <Row>
          <Col col={12} alignCenter>
            <P xSmall bold>
              {t("account_exists_message")}
            </P>
          </Col>
          <Col col={12}>
            <Button
              transparentBrand
              action={logIn}
              id="sign-in-btn"
              analyticsAttributes={
                GOOGLE_ANALYTICS_ATTRIBUTES.SIGN_IN_BUTTON_START
              }
            >
              {t("sign_in_button")}
            </Button>
          </Col>
        </Row>
      </Col>

      <Col aboveMd colMd={3} />
    </Row>
  );
};

const BeginUserRegistrationPage: React.FC = () => {
  return (
    <FormProvider url={ApiPath.Form.beginRegistration}>
      <BeginUserRegistration />
    </FormProvider>
  );
};

export default BeginUserRegistrationPage;
