import type React from "react";
import { useTranslation } from "react-i18next";

import ConfirmationModal from "./ConfirmationModal";

type GenericErrorModalProps = {
  cancelAction: () => void;
  retryAction: () => void;
  isOpen: boolean;
};

const GenericErrorModal: React.FC<GenericErrorModalProps> = ({
  cancelAction,
  retryAction,
  isOpen,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={t("genericError.title")}
      content={t("genericError.description")}
      primaryAction={retryAction}
      secondaryAction={cancelAction}
      primaryTitle={t("genericError.tryAgainButton")}
      secondaryTitle={t("genericError.cancelButton")}
      onClose={cancelAction}
    />
  );
};

export default GenericErrorModal;
