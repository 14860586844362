import { useEffect, useState } from "react";

import config from "@/constants/config";
import { LanguageQP } from "@/constants/navigation";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { overrideLanguage, selectHasLanguageOverride } from "@/store/language";
import { isLanguageValid } from "@/store/language/utils";
import { overrideRegion } from "@/store/region";

type UseLocaleOverrideResults = {
  isLocaleLoaded: boolean;
};

const useLocaleOverride = (): UseLocaleOverrideResults => {
  const searchParameters = new URLSearchParams(globalThis.location.search);
  const [isLocaleLoaded, setIsLocaleLoaded] = useState(false);
  const dispatch = useAppDispatch();
  const hasLanguageOverride = useAppSelector(selectHasLanguageOverride);

  // General domain has no region identifier in its origin (e.g. ".ee" or ".fi")
  const isGeneralDomain = config.routerConfig.regionPrefix === null;

  const handleSearchParameterLanguage = () => {
    const parameterLanguage = searchParameters.get(LanguageQP);

    if (!parameterLanguage) return;

    const languageRegion = parameterLanguage.split("-");

    if (languageRegion.length > 1) {
      if (isLanguageValid(languageRegion[0])) {
        dispatch(overrideLanguage(languageRegion[0]));
      }

      dispatch(overrideRegion(languageRegion[1].toLowerCase()));
    } else {
      if (isLanguageValid(parameterLanguage)) {
        dispatch(overrideLanguage(parameterLanguage));
      }

      // When scanning a gift card QR code, we only get the language, so we need to guess the region based on that.
      switch (parameterLanguage) {
        case "fi":
          dispatch(overrideRegion("fi"));
          break;

        case "et":
          dispatch(overrideRegion("ee"));
          break;

        case "lv":
          dispatch(overrideRegion("lv"));
          break;

        case "lt":
          dispatch(overrideRegion("lt"));
          break;
      }
    }

    searchParameters.delete(LanguageQP);
    globalThis.history.replaceState(
      null,
      "",
      searchParameters.size > 0
        ? `${globalThis.location.pathname}?${searchParameters.toString()}`
        : globalThis.location.pathname,
    );
  };

  useEffect(() => {
    const lng = globalThis.navigator.language.split("-");
    const locale = lng.at(0);
    if (searchParameters.has(LanguageQP)) {
      handleSearchParameterLanguage();
    } else if (
      isGeneralDomain &&
      !hasLanguageOverride &&
      locale &&
      isLanguageValid(locale)
    ) {
      dispatch(overrideLanguage(locale));
    }

    setIsLocaleLoaded(true);
  }, []);

  return {
    isLocaleLoaded,
  };
};
export default useLocaleOverride;
