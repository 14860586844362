import * as React from "react";
import { memo } from "react";
const SvgWarning = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#F68A1F",
      fillRule: "evenodd",
      stroke: "#F68A1F",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 0.5,
      d: "M12 9c.41 0 .75.34.75.75v3.75a.75.75 0 0 1-1.5 0V9.75c0-.41.34-.75.75-.75",
      clipRule: "evenodd"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#F68A1F",
      fillRule: "evenodd",
      stroke: "#F68A1F",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 0.5,
      d: "M10.88 2.55a2.24 2.24 0 0 1 3.06.83q0 0 0 0l8.25 14.25A2.25 2.25 0 0 1 20.25 21h0-16.5 0a2.25 2.25 0 0 1-1.94-3.37l8.24-14.25q0 0 0 0 .3-.53.83-.83m-.17 1.2.64.38-8.24 14.25q0 0 0 0a.75.75 0 0 0 .64 1.12h16.5a.75.75 0 0 0 .64-1.12L12.64 4.13h0a.74.74 0 0 0-1.28 0z",
      clipRule: "evenodd"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#F68A1F",
      stroke: "#F68A1F",
      strokeWidth: 0.5,
      d: "M12.88 16.88a.88.88 0 1 1-1.76 0 .88.88 0 0 1 1.76 0Z"
    }
  )
);
const Memo = memo(SvgWarning);
export default Memo;
