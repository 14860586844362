import type React from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@/assets/svg/close-btn.svg?react";
import LocationIcon from "@/assets/svg/location-with-star.svg?react";
import { GenericModal, H5, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";

import LocationInBuildingImage from "./LocationInBuildingImage";

type LocationInBuildingProps = {
  floorImageData?: string | null;
  floorInfo?: string | null;
  isOpen: boolean;
  onClose: () => void;
};

const LocationInBuildingMobile: React.FC<LocationInBuildingProps> = ({
  floorImageData,
  floorInfo,
  onClose,
  isOpen = false,
}) => {
  const { t } = useTranslation();

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <Row noMargin>
        <Col col={10}>
          <H5 prefixIcon={<LocationIcon />}>{t("location_in_building")}</H5>
        </Col>
        <Col alignEnd col={2}>
          <CloseIcon onClick={onClose} />
        </Col>
      </Row>
      <Row id="location-in-building">
        <LocationInBuildingImage floorImageData={floorImageData} />
        <Col col={12} noMargin>
          <P gray3 thin small>
            {floorInfo}
          </P>
        </Col>
      </Row>
    </GenericModal>
  );
};

export default LocationInBuildingMobile;
