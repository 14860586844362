import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { isPresent } from "@apl-digital/utils";

import EditIcon from "@/assets/svg/edit.svg?react";
import { H5, Icon, Link } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import {
  ADDRESS_ICON,
  APOLLO_CLUB_ICON,
  EMAIL_ICON,
  GIFT_CARD_ICON,
  PROFILE_ICON,
  QR_ICON,
  TRANSACTION_ICON,
} from "@/constants/blobIcons";
import config from "@/constants/config";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import Avatar from "@/pages/ProfilePage/components/Avatar";
import LoyaltyPrograms from "@/pages/ProfilePage/components/LoyaltyPrograms";
import { useMyInfoBlockStyles } from "@/pages/ProfilePage/components/MyInfoBlock/index";
import { useAppSelector } from "@/store/hooks";
import { selectCurrentRegionPrefix } from "@/store/region";
import { selectUserData } from "@/store/user";
import { getTheme } from "@/theme";

type NavLink = {
  iconURL: string;
  name: string;
  path: string;
};

const Links: NavLink[] = [
  {
    iconURL: APOLLO_CLUB_ICON,
    name: "link_club_offers",
    path: NavigationPath.MyApolloClub,
  },
  {
    iconURL: GIFT_CARD_ICON,
    name: "link_my_gift_cards",
    path: NavigationPath.GiftCard,
  },
  {
    iconURL: PROFILE_ICON,
    name: "link_my_profile",
    path: NavigationPath.Profile,
  },
  {
    iconURL: TRANSACTION_ICON,
    name: "link_my_transaction_history",
    path: NavigationPath.Transactions,
  },
  {
    iconURL: ADDRESS_ICON,
    name: "link_my_addresses",
    path: NavigationPath.Addresses,
  },
  {
    iconURL: EMAIL_ICON,
    name: "link_my_newsletters",
    path: NavigationPath.Newsletter,
  },
];

type Props = {
  hasEdit?: boolean;
  openCardModal: () => void;
};

const MyInfoBlockDesktop: React.FC<Props> = ({
  hasEdit = true,
  openCardModal,
}) => {
  const classes = useMyInfoBlockStyles();
  const { color } = getTheme();
  const userData = useAppSelector(selectUserData);
  const currentRegionPrefix = useAppSelector(selectCurrentRegionPrefix);
  const { t } = useTranslation();
  const location = useLocation();

  const links = useMemo(() => {
    if (
      isPresent(currentRegionPrefix) &&
      !config.featureFlags.enableTransactions.includes(currentRegionPrefix)
    ) {
      return Links.filter(
        (link) => link.name !== "link_my_transaction_history",
      );
    }

    return Links;
  }, [currentRegionPrefix]);

  return (
    <Row className={classes.wrapperDesktop}>
      <Col noMargin col={6} className={classes.infoCol}>
        <Row noMargin>
          <Col col={7} directionColumn noMargin>
            <Col
              col={12}
              directionColumn
              noMargin
              className={classes.nameWrapper}
            >
              {userData.status === "succeeded" && (
                <H5 gray2 noMargin>
                  {userData?.firstName} {userData?.lastName}
                </H5>
              )}
              {hasEdit && (
                <Link
                  brandColor
                  navigateTo={NavigationPath.EditProfile}
                  suffixIcon={<EditIcon />}
                >
                  {t("action_edit")}
                </Link>
              )}
            </Col>
            <LoyaltyPrograms />
            <Col noMargin col={12}>
              <div
                className={classes.qrImageContainer}
                onClick={openCardModal}
                {...GOOGLE_ANALYTICS_ATTRIBUTES.QR_CORE}
              >
                <Icon
                  icon={QR_ICON}
                  width={60}
                  height={60}
                  stroke={color.gray2}
                />
              </div>
            </Col>
          </Col>
          <Avatar />
        </Row>
      </Col>
      <Col noMargin col={6} directionColumn>
        <Row noMargin className={classes.linksContainer}>
          {links.map((link) => (
            <Col col={12} key={link.name} className={classes.links} noMargin>
              <Link
                navigateTo={link.path}
                prefixIcon={link.iconURL}
                flex
                data-testid={link.name}
                isActive={link.path === location.pathname}
              >
                {/* @ts-expect-error: waits for translations to accept dynamic keys */}
                {t(link.name)}
              </Link>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default MyInfoBlockDesktop;
