import type React from "react";
import { useNavigate } from "react-router-dom";

import type { IBenefitApolloClub } from "@/api/interfaces/benefitLayouts";
import AddIcon from "@/assets/svg/addItem_icon.svg?react";
import { Button, H1, H2, H5, P } from "@/base/components/Global";
import { Col } from "@/base/components/Layout";
import { NavigationPath } from "@/constants/navigation";

type ApolloClubBlockProps = {
  apolloClub?: IBenefitApolloClub;
};

const ApolloClubBlock: React.FC<ApolloClubBlockProps> = ({ apolloClub }) => {
  const navigate = useNavigate();

  if (!apolloClub) return null;

  const { topTitle, title, subTitle, content, cta } = apolloClub;
  return (
    <Col directionColumn>
      <Col col={12} directionColumn>
        <H2 noMargin>{topTitle}</H2>
        <H1 noMargin>{title}</H1>
        <H5 thin brandColor>
          {subTitle}
        </H5>

        <P cmsContent={content} />
      </Col>
      {cta?.label && (
        <Col col={12} fullWidth directionColumn>
          <Button
            action={() => navigate(NavigationPath.SignUpStart)}
            transparentBrand
            prefixIcon={<AddIcon />}
          >
            {cta?.label}
          </Button>
        </Col>
      )}
    </Col>
  );
};

export default ApolloClubBlock;
