import type React from "react";
import { useMemo, useRef } from "react";

import { createUseStyles } from "@/theme";

import { useMediaQuery } from "../MediaQueryProvider";

import { CarouselItem } from "./CarouselItem";
import type { CarouselChildrenProps } from "./types";

type UseStylesOptions = {
  isTwoItems: boolean;
};

const useStyles = createUseStyles(({ spacing }) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  itemContainer: {
    paddingTop: "40px",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "row",
    gap: ({ isTwoItems }: UseStylesOptions) =>
      isTwoItems ? spacing.s : spacing.l,
    overflowX: "scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    maxWidth: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

type CarouselProps<T> = {
  items: T[];
  defaultSelectedIndex?: number;
  children: (props: CarouselChildrenProps<T>) => React.ReactNode;
};

export const Carousel = <T,>({
  items,
  defaultSelectedIndex = 0,
  children,
}: CarouselProps<T>): JSX.Element => {
  const [{ isCurrentSizeSm }] = useMediaQuery();
  const classes = useStyles({ isTwoItems: items.length === 2 });
  const itemContainerRef = useRef<HTMLDivElement>(null);

  const carouselItems = useMemo(() => {
    const newItems: (T | null)[] = items;

    // If there are only two items, we need to add a fake item in the middle to center both cards
    if (isCurrentSizeSm && newItems.length === 2) {
      newItems.splice(1, 0, null);
    }

    return newItems;
  }, [items, isCurrentSizeSm]);

  return (
    <div className={classes.container}>
      <div className={classes.itemContainer} ref={itemContainerRef}>
        {carouselItems.map((item, index) => (
          <CarouselItem
            key={index}
            index={index}
            item={item}
            defaultSelectedIndex={defaultSelectedIndex}
            itemContainerRef={itemContainerRef}
          >
            {children}
          </CarouselItem>
        ))}
      </div>
    </div>
  );
};
