import type React from "react";
import { useTranslation } from "react-i18next";

import EditIcon from "@/assets/svg/edit.svg?react";
import TextField from "@/base/components/Form/v1/TextField";
import Link from "@/base/components/Global/Link";
import P from "@/base/components/Global/P";
import { Row } from "@/base/components/Layout";
import Col from "@/base/components/Layout/Col";
import { formatDate } from "@/base/utils/formatting";
import { NavigationPath } from "@/constants/navigation";
import { useAppSelector } from "@/store/hooks";
import { selectHasPersonalCodeCategories } from "@/store/region/selectors";
import { selectUserData } from "@/store/user";

const MyPersonalDataBlock: React.FC = () => {
  const { t } = useTranslation();
  const userData = useAppSelector(selectUserData);
  const personalCode =
    userData.status === "succeeded" ? userData?.personalCode : undefined;
  const birthDay =
    userData.status === "succeeded" ? userData?.birthDay : undefined;

  const hasPersonalCodeCategories = useAppSelector(
    selectHasPersonalCodeCategories,
  );

  return (
    <Row noMargin>
      <Col col={12} directionColumn>
        <P gray1>{t("birth_date")}</P>
        {birthDay && <P gray1>{formatDate(birthDay, false, " / ")}</P>}
        <P gray3 xSmall>
          {t("why_is_birth_date_asked")}
        </P>
      </Col>
      {hasPersonalCodeCategories && (
        <>
          <Col col={12} directionColumn>
            <P gray1>{t("personal_id")}</P>
            <P gray3 xSmall>
              {t("personal_id_change_text")}
            </P>
          </Col>
          <Col col={12} colMd={8} hide={!personalCode}>
            <TextField
              value={`${personalCode?.slice(0, 2)}**********`}
              label={t("field_personalCode")}
              isDisabled
            />
          </Col>
        </>
      )}
      <Col col={12} hide={!!personalCode && !!birthDay}>
        <Link
          brandColor
          navigateTo={NavigationPath.EditPersonalData}
          suffixIcon={<EditIcon />}
        >
          {t("action_edit_personal_data")}
        </Link>
      </Col>
    </Row>
  );
};

export default MyPersonalDataBlock;
