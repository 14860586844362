import { isPresent } from "@apl-digital/utils";

type EmptyString = string | undefined | null;

export const EmptyString = (value: EmptyString, defaultValue: EmptyString) => {
  if (!isPresent(value)) {
    return defaultValue;
  }

  if (value.trim() === "") {
    return defaultValue;
  }

  return value;
};

export const MapEmptyString = (
  object: Record<string, unknown>,
  defaultValue?: EmptyString,
) => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      key,
      typeof value === "string" ? EmptyString(value, defaultValue) : value,
    ]),
  );
};
