import type React from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CheckboxRow from "@/base/components/Form/v1/CheckboxRow";
import { Button, H5, Icon, Link, P } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import { Col, Row } from "@/base/components/Layout";
import { PAGE_MARGIN_BOTTOM } from "@/base/components/Layout/constants";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import { useUser } from "@/base/components/UserProvider";
import {
  APOLLO_STAR_ICON,
  CHECK_SMALL_ICON,
  TERMS_AND_CONDITIONS_ICON,
} from "@/constants/blobIcons";
import { NavigationPath } from "@/constants/navigation";
import {
  fetchAcceptTermsAndConditionsPage,
  selectAcceptTermsAndConditionsPage,
} from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { selectUserData } from "@/store/user";
import { createUseStyles, getTheme } from "@/theme";

const useStyles = createUseStyles(({ color }) => ({
  fixedContent: {
    "@media screen and (max-width: 768px)": {
      position: "fixed",
      bottom: 0,
      background: color.white,
      width: "100%",

      "&:before": {
        width: "100%",
        height: 14,
        content: "''",
        background: "linear-gradient(0deg, white, rgba(255,255,255,0))",
        display: "block",
        position: "absolute",
        top: -14,
      },
    },
  },
}));

const AcceptTermsAndConditionsPage: React.FC = () => {
  const classes = useStyles();
  const { color } = getTheme();
  const [isChecked, setIsChecked] = useState(false);
  const termsPage = useAppSelector(selectAcceptTermsAndConditionsPage);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  const [{ acceptTerms }] = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fixedDivRef = useRef<HTMLDivElement>(null);
  const [{ isCurrentSizeMd, isCurrentSizeLg, isCurrentSizeXl }] =
    useMediaQuery();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const isSuccess = await acceptTerms(true);
    setIsSubmitting(false);

    if (isSuccess) {
      navigate(NavigationPath.Home);
    }
  };

  return (
    <CmsPage
      pageStateMachine={termsPage}
      fetchPage={() => dispatch(fetchAcceptTermsAndConditionsPage())}
    >
      {({ page }) => (
        <>
          <div
            style={{
              marginBottom:
                isCurrentSizeMd || isCurrentSizeLg || isCurrentSizeXl
                  ? 0
                  : (fixedDivRef.current?.clientHeight ?? 0) -
                    PAGE_MARGIN_BOTTOM,
            }}
          >
            <Row>
              <Col col={12} directionColumn>
                <H5 prefixIcon={APOLLO_STAR_ICON} gray2>
                  {page.title}
                </H5>
                <H5>
                  {t("terms_page_greeting", {
                    firstName:
                      userData.status === "succeeded"
                        ? userData?.firstName
                        : "",
                  })}
                </H5>
                <P cmsContent={page.description} />
              </Col>
            </Row>
          </div>
          <div className={classes.fixedContent} ref={fixedDivRef}>
            <Row>
              <Col col={12} directionColumn>
                <Row noMargin>
                  <Col colMd={6}>
                    <CheckboxRow
                      icon={TERMS_AND_CONDITIONS_ICON}
                      label={t("terms_page_agreement_statement")}
                      bold
                      noBorder
                      slim
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                  </Col>
                </Row>
                <P gray3 small noMarginTop>
                  {t("terms_page_subtitle")}
                </P>
                {page.actions?.map((action) => (
                  <Link
                    brandColor
                    small
                    href={action.data}
                    target="_blank"
                    key={action.title}
                  >
                    {action.title}
                  </Link>
                ))}
              </Col>
              <Col col={12} colMd={6}>
                <Button
                  prefixIcon={
                    <Icon icon={CHECK_SMALL_ICON} stroke={color.white} />
                  }
                  isDisabled={!isChecked}
                  action={handleSubmit}
                  isLoading={isSubmitting}
                >
                  {t("terms_page_submit_button")}
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </CmsPage>
  );
};

export default AcceptTermsAndConditionsPage;
