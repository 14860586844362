import type React from "react";
import { useEffect, useState } from "react";
import classnames from "classnames";

import NewsletterController from "@/api/controller/newsletterController";
import type { INewsletter } from "@/api/interfaces/newsLetterLayout";
import CheckboxRow from "@/base/components/Form/v1/CheckboxRow";
import { findBusinessNewsletter } from "@/pages/Newsletters/helper/newslettersHelper";
import {
  fetchBusinessNewsletters,
  selectBusinessNewsletters,
} from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { hasData, shouldLoad } from "@/store/utils";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  itemsContainer: {
    display: "flex",
    padding: 0,
    flexDirection: "column",
    width: "100%",
  },
  subItem: {
    paddingLeft: spacing.l,
  },
}));

type NewsletterCheckboxesProps = {
  countryId: string;
  onChange: (checked: boolean, id: string) => void;
  reset: () => void;
};

const NewsletterCheckboxes: React.FC<NewsletterCheckboxesProps> = ({
  countryId,
  onChange,
  reset,
}) => {
  const classes = useStyles();

  const [newsLetters, setNewsletters] = useState<INewsletter[]>([]);
  const [filteredNewsletters, setFilteredNewsletters] = useState<INewsletter[]>(
    [],
  );
  const businessNewsletters = useAppSelector(selectBusinessNewsletters);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void NewsletterController.fetchAllNewsletters().then(
      ({ isResponseOk, response }) => {
        if (isResponseOk && response) {
          setNewsletters(response);
        }
      },
    );

    if (shouldLoad(businessNewsletters)) {
      void dispatch(fetchBusinessNewsletters());
    }
  }, []);

  useEffect(() => {
    reset();
    const filtered = newsLetters.filter(
      (newsletter) => newsletter.clientTerritoryId === countryId,
    );
    setFilteredNewsletters(filtered);
  }, [countryId, newsLetters]);

  return (
    <div className={classes.itemsContainer}>
      {filteredNewsletters.map((newsLetter, idx) => {
        const businessNewsletter = findBusinessNewsletter(
          newsLetter.externalIdentifier,
          hasData(businessNewsletters) ? businessNewsletters.array : [],
        );

        return (
          <div
            key={`${newsLetter.name}-checkbox`}
            className={classnames(idx !== 0 && classes.subItem)}
          >
            <CheckboxRow
              label={businessNewsletter?.name ?? newsLetter.name}
              slim
              onChange={(e) => onChange(e.target.checked, newsLetter.id)}
              icon={businessNewsletter?.icon}
              hintText={businessNewsletter?.description}
            />
          </div>
        );
      })}
    </div>
  );
};

export default NewsletterCheckboxes;
