import type React from "react";
import { useMemo } from "react";

import { Col } from "@/base/components/Layout";
import { formatMoney } from "@/base/utils/formatting";
import { useAppSelector } from "@/store";
import { selectCurrentLanguage } from "@/store/language";
import { createUseStyles } from "@/theme";

const ANIMATION_LENGTH = "1.5s";

type StyleProps = {
  progressCompleted: number;
};

const useStyles = createUseStyles<StyleProps>(
  ({ spacing, font, borderRadius, color }) => ({
    container: {
      width: "100%",
      height: 30,
      background: color.gray6,
      position: "relative",
      borderRadius: borderRadius.s,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: spacing.s,
      padding: [0, spacing.m],
      fontWeight: 700,
      fontSize: font.size.s,
    },
    "@keyframes expand": {
      from: {
        width: "0%",
      },
      to: {
        width: ({ progressCompleted }) => `${progressCompleted}%`,
      },
    },
    "@keyframes fadeIn": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    progress: {
      animation: `$expand ${ANIMATION_LENGTH} ease`,
      position: "absolute",
      background: color.primaryBrand,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: [0, spacing.s],
      borderRadius: borderRadius.s,
      height: 30,
      left: 0,
      color: color.white,
      width: ({ progressCompleted }) => `${progressCompleted}%`,
      minWidth: "fit-content",
    },
    progressValue: {
      opacity: 1,
      animation: `$fadeIn ${ANIMATION_LENGTH} ease`,
      overflow: "hidden",
    },
  }),
);

type SimpleProgressBarProps = {
  icon?: React.ReactNode;
  collected: number;
  total: number;
};

const SimpleProgressBar: React.FC<SimpleProgressBarProps> = ({
  icon,
  collected,
  total,
}) => {
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  const percentage = collected > total ? 100 : (collected * 100) / total;

  const classes = useStyles({
    progressCompleted: percentage,
  });

  const formattedTotal = useMemo(() => {
    return formatMoney(total, currentLanguage, true);
  }, [currentLanguage, total]);

  const formattedCollectedTotal = useMemo(() => {
    const format = formatMoney(
      Math.min(collected, total),
      currentLanguage,
      true,
    );

    if (percentage > 80 && percentage < 100) {
      return `${format} / ${formattedTotal}`;
    }

    return format;
  }, [currentLanguage, total, collected]);

  return (
    <Col fullWidth noHorizontalMargin>
      <div className={classes.container}>
        <span className={classes.progress}>
          <span className={classes.progressValue}>
            {formattedCollectedTotal}
          </span>
        </span>
        {percentage <= 80 && <span>{formattedTotal}</span>}
        {percentage <= 90 && icon}
      </div>
    </Col>
  );
};

export default SimpleProgressBar;
