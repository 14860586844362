import { createSlice } from "@reduxjs/toolkit";

import type { IGiftCard } from "@/api/interfaces/giftcardsLayout";
import type { ApiGetStateMachine, ArrayWrapper } from "@/store/types";
import { setStateFailed, setStatePending } from "@/store/utils";

import { fetchGiftCards } from "./actions";

type GiftCardsState = {
  giftCards: ApiGetStateMachine<ArrayWrapper<IGiftCard>>;
};

const initialState: GiftCardsState = {
  giftCards: { status: "idle" },
};

const giftCardsSlice = createSlice({
  name: "giftCards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGiftCards.pending, (state) => {
        setStatePending(
          state.giftCards,
          (nextState) => (state.giftCards = nextState),
        );
      })
      .addCase(fetchGiftCards.fulfilled, (state, action) => {
        state.giftCards = { array: action.payload, status: "succeeded" };
      })
      .addCase(fetchGiftCards.rejected, (state) => {
        setStateFailed(
          state.giftCards,
          (nextState) => (state.giftCards = nextState),
        );
      });
  },
});

export default giftCardsSlice.reducer;
