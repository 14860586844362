import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import UserController from "@/api/controller/userController";
import type { IEmail } from "@/api/interfaces/userLayouts";
import { Link } from "@/base/components/Global";
import LoaderTiny from "@/base/components/Global/LoaderTiny";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import type { AnalyticsAttributes } from "@/constants/googleAnalytics";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles((theme) => ({
  verifyBadge: {
    textWrap: "nowrap",

    fontSize: theme.font.size.s,
  },
}));

type VerifyEmailButtonProps = {
  email: IEmail;
  analyticsAttributes?: AnalyticsAttributes;
};

export const VerifyEmailButton: React.FC<VerifyEmailButtonProps> = ({
  email,
  analyticsAttributes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentModel, setCurrentModel] = useState<"success" | "error">();

  const verifyEmail = async (): Promise<void> => {
    setIsSubmitting(true);
    const { isResponseOk } = await UserController.updateEmail({ body: email });
    setIsSubmitting(false);
    setCurrentModel(isResponseOk ? "success" : "error");
  };

  return (
    <>
      <Link
        action={() => verifyEmail()}
        isLoading={isSubmitting}
        suffixIcon={isSubmitting && <LoaderTiny isSmall={true} />}
        brandColor
        flex
        analyticsAttributes={analyticsAttributes}
      >
        {!isSubmitting && (
          <span className={classes.verifyBadge}>
            {t("link_verify_contact")}
          </span>
        )}
      </Link>

      <SimpleModalWrapper
        isOpen={currentModel === "success"}
        onClose={() => setCurrentModel(undefined)}
        title={t("email_verification_sent_success_modal_title")}
        subTitle={t("email_verification_sent_success_modal_subtitle")}
        content={t("email_verification_sent_success_modal_content")}
      />
      <SimpleModalWrapper
        isOpen={currentModel === "error"}
        onClose={() => setCurrentModel(undefined)}
        title={t("email_verification_sent_error_modal_title")}
        subTitle={t("email_verification_sent_error_modal_subtitle")}
        content={t("email_verification_sent_error_modal_content")}
      />
    </>
  );
};
