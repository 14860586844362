import type { CountryIso2 } from "react-international-phone";

import AC from "@/assets/flags/AC.svg";
import AD from "@/assets/flags/AD.svg";
import AE from "@/assets/flags/AE.svg";
import AF from "@/assets/flags/AF.svg";
import AG from "@/assets/flags/AG.svg";
import AI from "@/assets/flags/AI.svg";
import AL from "@/assets/flags/AL.svg";
import AM from "@/assets/flags/AM.svg";
import AO from "@/assets/flags/AO.svg";
import AQ from "@/assets/flags/AQ.svg";
import AR from "@/assets/flags/AR.svg";
import AS from "@/assets/flags/AS.svg";
import AT from "@/assets/flags/AT.svg";
import AU from "@/assets/flags/AU.svg";
import AW from "@/assets/flags/AW.svg";
import AX from "@/assets/flags/AX.svg";
import AZ from "@/assets/flags/AZ.svg";
import BA from "@/assets/flags/BA.svg";
import BB from "@/assets/flags/BB.svg";
import BD from "@/assets/flags/BD.svg";
import BE from "@/assets/flags/BE.svg";
import BF from "@/assets/flags/BF.svg";
import BG from "@/assets/flags/BG.svg";
import BH from "@/assets/flags/BH.svg";
import BI from "@/assets/flags/BI.svg";
import BJ from "@/assets/flags/BJ.svg";
import BL from "@/assets/flags/BL.svg";
import BM from "@/assets/flags/BM.svg";
import BN from "@/assets/flags/BN.svg";
import BO from "@/assets/flags/BO.svg";
import BQ from "@/assets/flags/BQ.svg";
import BR from "@/assets/flags/BR.svg";
import BS from "@/assets/flags/BS.svg";
import BT from "@/assets/flags/BT.svg";
import BV from "@/assets/flags/BV.svg";
import BW from "@/assets/flags/BW.svg";
import BY from "@/assets/flags/BY.svg";
import BZ from "@/assets/flags/BZ.svg";
import CA from "@/assets/flags/CA.svg";
import CC from "@/assets/flags/CC.svg";
import CD from "@/assets/flags/CD.svg";
import CF from "@/assets/flags/CF.svg";
import CG from "@/assets/flags/CG.svg";
import CH from "@/assets/flags/CH.svg";
import CI from "@/assets/flags/CI.svg";
import CK from "@/assets/flags/CK.svg";
import CL from "@/assets/flags/CL.svg";
import CM from "@/assets/flags/CM.svg";
import CN from "@/assets/flags/CN.svg";
import CO from "@/assets/flags/CO.svg";
import CR from "@/assets/flags/CR.svg";
import CU from "@/assets/flags/CU.svg";
import CV from "@/assets/flags/CV.svg";
import CW from "@/assets/flags/CW.svg";
import CX from "@/assets/flags/CX.svg";
import CY from "@/assets/flags/CY.svg";
import CZ from "@/assets/flags/CZ.svg";
import DE from "@/assets/flags/DE.svg";
import DJ from "@/assets/flags/DJ.svg";
import DK from "@/assets/flags/DK.svg";
import DM from "@/assets/flags/DM.svg";
import DO from "@/assets/flags/DO.svg";
import DZ from "@/assets/flags/DZ.svg";
import EC from "@/assets/flags/EC.svg";
import EE from "@/assets/flags/EE.svg";
import EG from "@/assets/flags/EG.svg";
import EH from "@/assets/flags/EH.svg";
import ER from "@/assets/flags/ER.svg";
import ES from "@/assets/flags/ES.svg";
import ET from "@/assets/flags/ET.svg";
import EU from "@/assets/flags/EU.svg";
import FI from "@/assets/flags/FI.svg";
import FJ from "@/assets/flags/FJ.svg";
import FK from "@/assets/flags/FK.svg";
import FM from "@/assets/flags/FM.svg";
import FO from "@/assets/flags/FO.svg";
import FR from "@/assets/flags/FR.svg";
import GA from "@/assets/flags/GA.svg";
import GB from "@/assets/flags/GB.svg";
import GD from "@/assets/flags/GD.svg";
import GE from "@/assets/flags/GE.svg";
import GEAB from "@/assets/flags/GE-AB.svg";
import GEOS from "@/assets/flags/GE-OS.svg";
import GF from "@/assets/flags/GF.svg";
import GG from "@/assets/flags/GG.svg";
import GH from "@/assets/flags/GH.svg";
import GI from "@/assets/flags/GI.svg";
import GL from "@/assets/flags/GL.svg";
import GM from "@/assets/flags/GM.svg";
import GN from "@/assets/flags/GN.svg";
import GP from "@/assets/flags/GP.svg";
import GQ from "@/assets/flags/GQ.svg";
import GR from "@/assets/flags/GR.svg";
import GS from "@/assets/flags/GS.svg";
import GT from "@/assets/flags/GT.svg";
import GU from "@/assets/flags/GU.svg";
import GW from "@/assets/flags/GW.svg";
import GY from "@/assets/flags/GY.svg";
import HK from "@/assets/flags/HK.svg";
import HM from "@/assets/flags/HM.svg";
import HN from "@/assets/flags/HN.svg";
import HR from "@/assets/flags/HR.svg";
import HT from "@/assets/flags/HT.svg";
import HU from "@/assets/flags/HU.svg";
import IC from "@/assets/flags/IC.svg";
import ID from "@/assets/flags/ID.svg";
import IE from "@/assets/flags/IE.svg";
import IL from "@/assets/flags/IL.svg";
import IM from "@/assets/flags/IM.svg";
import IN from "@/assets/flags/IN.svg";
import IO from "@/assets/flags/IO.svg";
import IQ from "@/assets/flags/IQ.svg";
import IR from "@/assets/flags/IR.svg";
import IS from "@/assets/flags/IS.svg";
import IT from "@/assets/flags/IT.svg";
import JE from "@/assets/flags/JE.svg";
import JM from "@/assets/flags/JM.svg";
import JO from "@/assets/flags/JO.svg";
import JP from "@/assets/flags/JP.svg";
import KE from "@/assets/flags/KE.svg";
import KG from "@/assets/flags/KG.svg";
import KH from "@/assets/flags/KH.svg";
import KI from "@/assets/flags/KI.svg";
import KM from "@/assets/flags/KM.svg";
import KN from "@/assets/flags/KN.svg";
import KP from "@/assets/flags/KP.svg";
import KR from "@/assets/flags/KR.svg";
import KW from "@/assets/flags/KW.svg";
import KY from "@/assets/flags/KY.svg";
import KZ from "@/assets/flags/KZ.svg";
import LA from "@/assets/flags/LA.svg";
import LB from "@/assets/flags/LB.svg";
import LC from "@/assets/flags/LC.svg";
import LI from "@/assets/flags/LI.svg";
import LK from "@/assets/flags/LK.svg";
import LR from "@/assets/flags/LR.svg";
import LS from "@/assets/flags/LS.svg";
import LT from "@/assets/flags/LT.svg";
import LU from "@/assets/flags/LU.svg";
import LV from "@/assets/flags/LV.svg";
import LY from "@/assets/flags/LY.svg";
import MA from "@/assets/flags/MA.svg";
import MC from "@/assets/flags/MC.svg";
import MD from "@/assets/flags/MD.svg";
import ME from "@/assets/flags/ME.svg";
import MF from "@/assets/flags/MF.svg";
import MG from "@/assets/flags/MG.svg";
import MH from "@/assets/flags/MH.svg";
import MK from "@/assets/flags/MK.svg";
import ML from "@/assets/flags/ML.svg";
import MM from "@/assets/flags/MM.svg";
import MN from "@/assets/flags/MN.svg";
import MO from "@/assets/flags/MO.svg";
import MP from "@/assets/flags/MP.svg";
import MQ from "@/assets/flags/MQ.svg";
import MR from "@/assets/flags/MR.svg";
import MS from "@/assets/flags/MS.svg";
import MT from "@/assets/flags/MT.svg";
import MU from "@/assets/flags/MU.svg";
import MV from "@/assets/flags/MV.svg";
import MW from "@/assets/flags/MW.svg";
import MX from "@/assets/flags/MX.svg";
import MY from "@/assets/flags/MY.svg";
import MZ from "@/assets/flags/MZ.svg";
import NA from "@/assets/flags/NA.svg";
import NC from "@/assets/flags/NC.svg";
import NE from "@/assets/flags/NE.svg";
import NF from "@/assets/flags/NF.svg";
import NG from "@/assets/flags/NG.svg";
import NI from "@/assets/flags/NI.svg";
import NL from "@/assets/flags/NL.svg";
import NO from "@/assets/flags/NO.svg";
import NP from "@/assets/flags/NP.svg";
import NR from "@/assets/flags/NR.svg";
import NU from "@/assets/flags/NU.svg";
import NZ from "@/assets/flags/NZ.svg";
import OM from "@/assets/flags/OM.svg";
import PA from "@/assets/flags/PA.svg";
import PE from "@/assets/flags/PE.svg";
import PF from "@/assets/flags/PF.svg";
import PG from "@/assets/flags/PG.svg";
import PH from "@/assets/flags/PH.svg";
import PK from "@/assets/flags/PK.svg";
import PL from "@/assets/flags/PL.svg";
import PM from "@/assets/flags/PM.svg";
import PN from "@/assets/flags/PN.svg";
import PR from "@/assets/flags/PR.svg";
import PS from "@/assets/flags/PS.svg";
import PT from "@/assets/flags/PT.svg";
import PW from "@/assets/flags/PW.svg";
import PY from "@/assets/flags/PY.svg";
import QA from "@/assets/flags/QA.svg";
import RE from "@/assets/flags/RE.svg";
import RO from "@/assets/flags/RO.svg";
import RS from "@/assets/flags/RS.svg";
import RU from "@/assets/flags/RU.svg";
import RW from "@/assets/flags/RW.svg";
import SA from "@/assets/flags/SA.svg";
import SB from "@/assets/flags/SB.svg";
import SC from "@/assets/flags/SC.svg";
import SD from "@/assets/flags/SD.svg";
import SE from "@/assets/flags/SE.svg";
import SG from "@/assets/flags/SG.svg";
import SH from "@/assets/flags/SH.svg";
import SI from "@/assets/flags/SI.svg";
import SJ from "@/assets/flags/SJ.svg";
import SK from "@/assets/flags/SK.svg";
import SL from "@/assets/flags/SL.svg";
import SM from "@/assets/flags/SM.svg";
import SN from "@/assets/flags/SN.svg";
import SO from "@/assets/flags/SO.svg";
import SR from "@/assets/flags/SR.svg";
import SS from "@/assets/flags/SS.svg";
import ST from "@/assets/flags/ST.svg";
import SV from "@/assets/flags/SV.svg";
import SX from "@/assets/flags/SX.svg";
import SY from "@/assets/flags/SY.svg";
import SZ from "@/assets/flags/SZ.svg";
import TA from "@/assets/flags/TA.svg";
import TC from "@/assets/flags/TC.svg";
import TD from "@/assets/flags/TD.svg";
import TF from "@/assets/flags/TF.svg";
import TG from "@/assets/flags/TG.svg";
import TH from "@/assets/flags/TH.svg";
import TJ from "@/assets/flags/TJ.svg";
import TK from "@/assets/flags/TK.svg";
import TL from "@/assets/flags/TL.svg";
import TM from "@/assets/flags/TM.svg";
import TN from "@/assets/flags/TN.svg";
import TO from "@/assets/flags/TO.svg";
import TR from "@/assets/flags/TR.svg";
import TT from "@/assets/flags/TT.svg";
import TV from "@/assets/flags/TV.svg";
import TW from "@/assets/flags/TW.svg";
import TZ from "@/assets/flags/TZ.svg";
import UA from "@/assets/flags/UA.svg";
import UG from "@/assets/flags/UG.svg";
import UM from "@/assets/flags/UM.svg";
import US from "@/assets/flags/US.svg";
import UY from "@/assets/flags/UY.svg";
import UZ from "@/assets/flags/UZ.svg";
import VA from "@/assets/flags/VA.svg";
import VC from "@/assets/flags/VC.svg";
import VE from "@/assets/flags/VE.svg";
import VG from "@/assets/flags/VG.svg";
import VI from "@/assets/flags/VI.svg";
import VN from "@/assets/flags/VN.svg";
import VU from "@/assets/flags/VU.svg";
import WF from "@/assets/flags/WF.svg";
import WS from "@/assets/flags/WS.svg";
import XK from "@/assets/flags/XK.svg";
import YE from "@/assets/flags/YE.svg";
import YT from "@/assets/flags/YT.svg";
import ZA from "@/assets/flags/ZA.svg";
import ZM from "@/assets/flags/ZM.svg";
import ZW from "@/assets/flags/ZW.svg";

import { Icon } from "../../../Global";

const flags: Record<Uppercase<CountryIso2>, string> = {
  AC,
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  EU,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  "GE-AB": GEAB,
  "GE-OS": GEOS,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  IC,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TA,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
};

type FlagProps = {
  iso2: Uppercase<CountryIso2>;
  className?: string;
};

export const Flag = ({ iso2, className }: FlagProps) => {
  return <Icon className={className} icon={flags[iso2]} />;
};
