import type React from "react";
import { useState } from "react";

import LoaderIcon from "@/assets/svg/loader.svg?react";
import useTimeout from "@/base/hooks/useTimeout";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles({
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  outerContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingTop: 48,
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 100, // on top of everything
    overflowY: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflowY: "hidden",
  },
  icon: {
    animation: "$spin 3s infinite linear",
    display: "flex",
  },
});

type LoaderProps = {
  showLoader?: boolean;
  isMinified?: boolean;
};

const Loader: React.FC<LoaderProps> = ({
  showLoader = false,
  isMinified = false,
}) => {
  const classes = useStyles();
  const [shouldShowLoader, setShouldShowLoader] = useState(showLoader);

  useTimeout(() => setShouldShowLoader(true), 450); // Chosen by a true wizard (Henry)

  if (!shouldShowLoader) return null;

  if (isMinified) {
    return (
      <div className={classes.container}>
        <div className={classes.icon}>
          <LoaderIcon />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.icon}>
          <LoaderIcon />
        </div>
      </div>
    </div>
  );
};

export default Loader;
