import type React from "react";
import { useAuth } from "react-oidc-context";
import { isPresent } from "@apl-digital/utils";

import type { IRegion } from "@/api/interfaces/countriesLayout";
import type { IMenuItemLayout } from "@/api/interfaces/menuLayout";
import CheckedIcon from "@/assets/svg/checkedIcon.svg?react";
import EmptyIcon from "@/assets/svg/emptyCheckbox_sm.svg?react";
import AccordionItem from "@/base/components/AccordionList/AccordionItem";
import MenuItem from "@/base/components/BurgerMenu/components/MenuItem";
import { isMenuItemDisabled } from "@/base/components/BurgerMenu/helper/menuHelper";
import { useUser } from "@/base/components/UserProvider";
import { isRegionEnabled } from "@/base/utils/featureFlagUtil";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { changeSelectedLanguage } from "@/store/language";
import {
  changeSelectedRegion,
  selectCurrentRegion,
  selectRegions,
} from "@/store/region";
import { selectUserData } from "@/store/user";

type RegionsMenuProps = {
  item: IMenuItemLayout;
  closeMenu?: () => void;
};

const RegionsMenu: React.FC<RegionsMenuProps> = ({ item, closeMenu }) => {
  const userData = useAppSelector(selectUserData);
  const [{ updateProfile }] = useUser();
  const { isAuthenticated } = useAuth();
  const regions = useAppSelector(selectRegions);
  const currentRegion = useAppSelector(selectCurrentRegion);
  const dispatch = useAppDispatch();
  if (!regions) return null;

  const changeRegion = async (newRegion: IRegion) => {
    if (isAuthenticated && userData.status === "succeeded") {
      const newLocale = newRegion.locales.at(0);

      if (!isPresent(newLocale)) throw new Error("No locales found for region");

      dispatch(changeSelectedRegion(newRegion.prefix));
      dispatch(changeSelectedLanguage(newLocale.code));

      await updateProfile({
        regionId: newRegion.id,
        localeId: newLocale.id,
      });
    } else {
      window.open(newRegion.domainName, "_self");
    }
  };

  return (
    <AccordionItem id={item.title} title={item.title} iconURL={item.iconURL}>
      {regions
        .filter((region) => isRegionEnabled(region.id))
        .map((region) => (
          <MenuItem
            key={region.id}
            onClick={async () => changeRegion(region)}
            closeMenu={closeMenu}
            icon={
              region.id === currentRegion?.id ? <CheckedIcon /> : <EmptyIcon />
            }
            isDisabled={isMenuItemDisabled(
              item.disabledWhenNotValidate,
              userData.status === "succeeded"
                ? userData?.termsOfServiceStatus
                : undefined,
            )}
          >
            {region.name}
          </MenuItem>
        ))}
    </AccordionItem>
  );
};

export default RegionsMenu;
