import { useMemo } from "react";
import {
  type FieldPath,
  type FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as locales from "date-fns/locale";

import { muiTheme } from "@/theme";

type FormDatePickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<
  React.ComponentProps<typeof DatePicker>,
  "variant" | "ref" | "name" | "value" | "error" | "onChange" | "onBlur"
> & {
  id: string;
  name: TName;
  helperText?: React.ReactNode;
};

export const FormDatePicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  id,
  name,
  helperText,
  ...props
}: FormDatePickerProps<TFieldValues, TName>) => {
  const { i18n } = useTranslation();
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const currentLocale = useMemo(
    () =>
      Object.entries(locales).find(([key]) => key === i18n.language) ?? [
        "et",
        locales.et,
      ],
    [locales, i18n.language],
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={currentLocale[1]}
      >
        <DatePicker
          inputRef={field.ref}
          onChange={field.onChange}
          value={field.value ?? null}
          name={field.name}
          disabled={field.disabled}
          slotProps={{
            textField: {
              id: id,
              helperText: error ? error.message : helperText,
              error: !!error,
              onBlur: field.onBlur,
            },
          }}
          {...props}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
