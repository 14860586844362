import type React from "react";
import { useTranslation } from "react-i18next";

import UpArrowIcon from "@/assets/svg/up-arrow.svg?react";
import { Link } from "@/base/components/Global";
import { handleScrollToTop } from "@/base/utils/scrollHelpers";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ color, spacing }) => ({
  icon: {
    position: "relative",
    top: 2,
  },
  link: {
    background: color.gray2,
    width: "100%",
    justifyContent: "center",
    padding: spacing.s,
  },
}));

const ScrollToTop: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Link
      flex
      white
      small
      bold
      action={handleScrollToTop}
      className={classes.link}
      suffixIcon={<UpArrowIcon className={classes.icon} />}
    >
      {t("footer_scroll_top")}
    </Link>
  );
};

export default ScrollToTop;
