import type React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import type { IHomeClubCardInfo } from "@/api/interfaces/apolloClubCardLayout";
import MyGiftCardIcon from "@/assets/svg/gift_cards_icon.svg?react";
import PlusIcon from "@/assets/svg/orange_plus.svg?react";
import { PERKS_BLOCK_ID } from "@/base/blocks/PerkBlock/PerkBlock";
import { Button, ButtonTile, P } from "@/base/components/Global";
import Icon from "@/base/components/Global/Icon";
import { Col, Row } from "@/base/components/Layout";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import { getApolloLogoWithStartURLByLanguage } from "@/base/utils/iconUtil";
import { scrollToWithOffset } from "@/base/utils/scrollHelpers";
import { NavigationPath } from "@/constants/navigation";
import Card from "@/pages/HomePage/components/ClaimYourClubCard/Card";
import { INTRO_BLOCK_ID } from "@/pages/HomePage/components/IntroductionBlocks";

type ApolloClubCardProps = {
  block?: IHomeClubCardInfo;
};

const ApolloClubCard: React.FC<ApolloClubCardProps> = ({ block }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [{ isCurrentSizeSm }] = useMediaQuery();

  const scrollToIntroductionBlock = () => {
    if (isAuthenticated) {
      navigate(NavigationPath.ApolloClubPromo);
    } else {
      scrollToWithOffset(INTRO_BLOCK_ID);
    }
  };

  const onClickGiftCards = () => {
    if (isAuthenticated) {
      navigate(NavigationPath.GiftCard);
    } else {
      scrollToWithOffset(PERKS_BLOCK_ID);
    }
  };

  if (!block) return null;

  return (
    <Row>
      <Col col={12} colMd={6} alignCenter>
        <Card />
      </Col>
      <Col col={12} colMd={6} directionColumn noMarginRight={isCurrentSizeSm}>
        <Row noMargin>
          <Col col={6} noMarginTop noMarginLeft={!isCurrentSizeSm}>
            <ButtonTile icon={<MyGiftCardIcon />} action={onClickGiftCards}>
              {t("my_gift_cards")}
            </ButtonTile>
          </Col>
          <Col col={6} noMarginTop noMarginRight={!isCurrentSizeSm}>
            <ButtonTile
              icon={
                <Icon
                  icon={getApolloLogoWithStartURLByLanguage(i18n.language)}
                />
              }
              action={scrollToIntroductionBlock}
            />
          </Col>
          {block?.cta?.label && (
            <Col col={12} alignCenter noMarginBottom>
              <Button
                prefixIcon={<PlusIcon />}
                transparentBrand
                action={() => navigate(NavigationPath.SignUpStart)}
              >
                {block.cta.label}
              </Button>
            </Col>
          )}
        </Row>
        <Col col={12} noHorizontalMargin={!isCurrentSizeSm}>
          <P noMargin cmsContent={block.description} />
        </Col>
      </Col>
    </Row>
  );
};

export default ApolloClubCard;
