import * as React from "react";
import { memo } from "react";
const SvgCheckedIcon = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 16,
    height: 16,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement("rect", { width: 16, height: 16, fill: "#F68A1F", rx: 2 }),
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      width: 6.5,
      height: 2,
      x: 7.1,
      y: 11.99,
      fill: "#fff",
      rx: 1,
      transform: "rotate(-135 7.1 11.99)"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      width: 2,
      height: 10,
      x: 7.1,
      y: 11.99,
      fill: "#fff",
      rx: 1,
      transform: "rotate(-135 7.1 11.99)"
    }
  )
);
const Memo = memo(SvgCheckedIcon);
export default Memo;
