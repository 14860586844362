import { isPresent } from "@apl-digital/utils";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import {
  getSessionStorage,
  getStorage,
  LocalStorageKey,
  SessionStorageKey,
} from "@/base/utils/storage";
import { setStateFailed, setStatePending } from "@/store/utils";

import { fetchRegions } from "./actions";
import type { Regions, RegionState } from "./types";

const getInitialState = (loadFromStorage: boolean): RegionState => {
  const storedRegions = getStorage(LocalStorageKey.REGIONS);
  const regions: Regions = isPresent(storedRegions)
    ? { status: "stale", array: storedRegions }
    : { status: "idle" };
  const regionOverride = getSessionStorage(SessionStorageKey.REGION_OVERRIDE);

  return {
    regions: loadFromStorage ? regions : { status: "idle" },
    selectedRegion: null,
    regionOverride: loadFromStorage ? regionOverride : null,
  };
};

export const regionSlice = createSlice({
  name: "region",
  initialState: getInitialState(true),
  reducers: {
    overrideRegion: (state, { payload }: PayloadAction<string>) => {
      state.regionOverride = payload;
    },
    changeSelectedRegion: (state, { payload }: PayloadAction<string>) => {
      state.selectedRegion = payload;
      state.regionOverride = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state) => {
        setStatePending(
          state.regions,
          (nextState) => (state.regions = nextState),
        );
      })
      .addCase(fetchRegions.fulfilled, (state, { payload }) => {
        state.regions = { status: "succeeded", array: payload };
      })
      .addCase(fetchRegions.rejected, (state) => {
        setStateFailed(
          state.regions,
          (nextState) => (state.regions = nextState),
        );
      });
  },
});

export const { overrideRegion, changeSelectedRegion } = regionSlice.actions;

export default regionSlice.reducer;
