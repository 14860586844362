import type React from "react";
import classnames from "classnames";

import { VisibilityBlock } from "@/base/components/Layout";
import {
  BLOCK_BREAK,
  BLOCK_BREAK_MOBILE,
} from "@/base/components/Layout/constants";
import type { VisibilityProps } from "@/base/components/Layout/VisibilityBlock";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ sizes }) => ({
  blockBreak: {
    width: "100%",
    height: BLOCK_BREAK_MOBILE,
    display: "flex",
    [sizes.md]: {
      height: BLOCK_BREAK,
    },
  },
}));

type BlockBreakProps = VisibilityProps & {
  className?: string | number | boolean | null;
};

const BlockBreak: React.FC<BlockBreakProps> = ({
  className,
  hide,
  belowSm,
  belowMd,
  belowLg,
  aboveSm,
  aboveMd,
  aboveLg,
  aboveXl,
}) => {
  const classes = useStyles();

  return (
    <VisibilityBlock
      hide={hide}
      belowSm={belowSm}
      belowMd={belowMd}
      belowLg={belowLg}
      aboveSm={aboveSm}
      aboveMd={aboveMd}
      aboveLg={aboveLg}
      aboveXl={aboveXl}
    >
      <div className={classnames([classes.blockBreak, className])} />
    </VisibilityBlock>
  );
};

export default BlockBreak;
