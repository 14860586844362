import type React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import TerritoryController from "@/api/controller/territoryController";
import {
  AddressType,
  type IAddress,
  type IAddressType,
} from "@/api/interfaces/userLayouts";
import { Form, FormTextField, MapEmptyString } from "@/base/components/Form";
import { FormCheckbox } from "@/base/components/Form";
import CountrySelect from "@/base/components/Form/v1/CountrySelect";
import PhoneField from "@/base/components/Form/v1/PhoneField";
import { Button, Icon, P } from "@/base/components/Global";
import { Col } from "@/base/components/Layout";
import Row from "@/base/components/Layout/Row";
import ConfirmationModal from "@/base/components/Modal/ConfirmationModal";
import { isAddressOfType } from "@/base/utils/userUtil";
import { CLOSE_SMALL_ICON } from "@/constants/blobIcons";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { getTheme } from "@/theme";

type AddressFieldType = {
  address?: IAddress;
  save?: (newAddress: IAddress) => Promise<void>;
  remove?: () => Promise<void>;
};

const AddressFormSchema = z.object({
  addressLine1: z.string().min(1),
  postalCode: z.string().min(1),
  country: z.string().min(1),
  region: z.string().min(1),
  addressLine2: z.string().optional(),
  city: z.string().min(1),
  countryId: z.string().optional(),
  contactName: z.string().optional(),
  contactPhone: z.string().optional(),
  contactEmail: z.string().email().optional(),
  cityDistrict: z.string().optional(),
  isBillingAddress: z.boolean().optional(),
  isShippingAddress: z.boolean(),
  alias: z.string().optional(),
});

type AddressFormSchemaType = z.infer<typeof AddressFormSchema>;

const AddressForm: React.FC<AddressFieldType> = ({ address, save, remove }) => {
  const { t } = useTranslation();
  const { color } = getTheme();

  const form = useForm<AddressFormSchemaType>({
    mode: "onChange",
    resolver: zodResolver(AddressFormSchema),
    defaultValues: MapEmptyString({
      addressLine1: address?.addressLine1,
      addressLine2: address?.addressLine2,
      city: address?.city,
      region: address?.region,
      postalCode: address?.postalCode,
      country: address?.country, // Used for select label....
      countryId: address?.countryId,
      contactName: address?.contactName,
      contactPhone: address?.contactPhone,
      contactEmail: address?.contactEmail,
      isBillingAddress: isAddressOfType(AddressType.BILLING, address),
      isShippingAddress: isAddressOfType(AddressType.SHIPPING, address),
      alias: address?.alias ?? t("my_address_alias"),
    }),
  });

  const {
    register,
    getValues,
    setValue,
    formState: { errors, isSubmitted, isSubmitting },
  } = form;

  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);

  const hasError = (inputName: keyof IAddress) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  const onSubmit = async ({
    isShippingAddress,
    isBillingAddress,
    ...data
  }: AddressFormSchemaType) => {
    const addressTypes: IAddressType[] = [];

    if (isShippingAddress) {
      addressTypes.push({
        isPrimary: true,
        type: AddressType.SHIPPING,
      });
    }

    if (isBillingAddress) {
      addressTypes.push({
        isPrimary: true,
        type: AddressType.BILLING,
      });
    }

    if (!isBillingAddress && !isShippingAddress) {
      addressTypes.push({
        isPrimary: true,
        type: AddressType.MAILING,
      });
    }

    await save?.({
      ...address,
      ...data,
      types: addressTypes,
    });
  };

  const values = getValues();
  const { ref: contactPhoneRef, ...contactPhoneProps } =
    register("contactPhone");

  return (
    <>
      <ConfirmationModal
        isOpen={shouldShowDeleteModal}
        onClose={() => setShouldShowDeleteModal(false)}
        title={t("address_delete_confirmation_title")}
        content={t("address_delete_confirmation_content")}
        primaryAction={() => {
          if (remove) void remove();
        }}
        primaryTitle={t("address_delete_button")}
      />
      <Form {...form} onSubmitValid={onSubmit}>
        <Row noMarginRight noMarginLeft>
          <Col col={12}>
            <FormTextField
              name={"addressLine1"}
              id="addressLine1-input"
              label={t("field_addressLine1_label")}
              placeholder={t("field_addressLine1_placeholder")}
              autoComplete="address-line1"
            />
          </Col>
          <Col col={12}>
            <FormTextField
              name={"addressLine2"}
              id="addressLine2-input"
              label={t("field_addressLine2_label")}
              placeholder={t("field_addressLine2_placeholder")}
              autoComplete="address-line2"
            />
          </Col>
          <Col col={6} directionColumn alignStart>
            <FormTextField
              name={"postalCode"}
              id="postalCode-input"
              label={t("field_postalCode_label")}
              placeholder={t("field_postalCode_placeholder")}
              autoComplete="postal-code"
            />
          </Col>
          <Col col={6} directionColumn alignStart>
            <FormTextField
              name={"city"}
              id="city-input"
              label={t("field_city_label")}
              placeholder={t("field_city_placeholder")}
              autoComplete="address-level2"
            />
          </Col>
          <Col col={12}>
            <FormTextField
              name={"region"}
              id="region-input"
              label={t("field_region_label")}
              placeholder={t("field_region_placeholder")}
              autoComplete="address-level1"
            />
          </Col>
          <Col col={12}>
            <CountrySelect
              api={TerritoryController.getClientCountries}
              label={t("field_country_label")}
              id="country-input"
              helperText={hasError("country") && t("field_country_error")}
              hasError={hasError("country")}
              currentValue={values.countryId}
              setCurrentValue={({
                value,
                label,
              }: {
                value: string;
                label: string;
              }) => {
                setValue("countryId", value, { shouldDirty: true });
                setValue("country", label, { shouldDirty: true });
              }}
            />
          </Col>
          <Col col={12}>
            <FormTextField
              name={"contactName"}
              id="contactName-input"
              label={t("field_contactName_label")}
              placeholder={t("field_contactName_placeholder")}
              autoComplete="name"
            />
          </Col>
          <Col col={12}>
            <PhoneField
              value={values.contactPhone}
              label={t("field_contactPhone_label")}
              helperText={
                hasError("contactPhone") && t("field_contactPhone_error")
              }
              hasError={hasError("contactPhone")}
              innerRef={contactPhoneRef}
              {...contactPhoneProps}
            />
          </Col>
          <Col col={12}>
            <FormTextField
              name={"contactEmail"}
              id="contactEmail-input"
              label={t("field_contactEmail_label")}
              placeholder={t("field_contactEmail_placeholder")}
              type="email"
              autoComplete="email"
            />
          </Col>
          <Col col={12}>
            <FormTextField
              name={"alias"}
              id="contactAlias-input"
              label={t("field_contactAlias_label")}
              placeholder={t("field_contactAlias_placeHolder")}
            />
          </Col>
          {!remove && (
            <Col col={12}>
              <P>{t("address_form_helper")}</P>
            </Col>
          )}
          <Col col={12} directionColumn noVerticalMargin>
            <FormCheckbox
              name={"isBillingAddress"}
              id="isBillingAddress-input"
              label={t("checkbox_address_use_as_billing")}
            />
          </Col>
          <Col col={12} directionColumn noVerticalMargin>
            <FormCheckbox
              name="isShippingAddress"
              id="isShippingAddress-input"
              label={t("checkbox_address_use_as_shipping")}
            />
          </Col>
          {!remove && (
            <Col col={12}>
              <P>{t("address_explanation_billing_or_shipping")}</P>
            </Col>
          )}
          <Col noMargin>
            {remove && (
              <Col col={12}>
                <Button
                  transparentBrand
                  action={() => setShouldShowDeleteModal(true)}
                  prefixIcon={
                    <Icon icon={CLOSE_SMALL_ICON} stroke={color.primaryBrand} />
                  }
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_ADDRESS
                  }
                >
                  {t("delete_address_button")}
                </Button>
              </Col>
            )}
            <Col col={12} colMd={6}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                analyticsAttributes={
                  remove
                    ? GOOGLE_ANALYTICS_ATTRIBUTES.ADDRESS_CHANGE
                    : GOOGLE_ANALYTICS_ATTRIBUTES.ADD_ADDRESS
                }
              >
                {t(remove ? "save_address_button" : "add_new_address_button")}
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddressForm;
