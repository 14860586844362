import type React from "react";
import classnames from "classnames";

import { Icon } from "@/base/components/Global";
import type { StyleProps } from "@/base/components/Global/P";
import { createUseStyles, getTheme } from "@/theme";

const useStyles = createUseStyles<StyleProps>(({ spacing }) => ({
  prefixIcon: {
    marginRight: spacing.s,
  },
  prefixSpanIcon: {
    display: "flex",
    marginRight: spacing.s,
  },
}));

type PrefixIconProps = {
  className?: string;
  icon?: React.ReactNode;
  isActive: boolean;
};

const PrefixIcon: React.FC<PrefixIconProps> = ({
  icon,
  className,
  isActive = false,
}) => {
  const classes = useStyles();
  const { color } = getTheme();

  return (
    <Icon
      icon={icon}
      className={classnames([classes.prefixIcon, className])}
      stroke={isActive ? color.primaryBrand : ""}
    />
  );
};

export default PrefixIcon;
