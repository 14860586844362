import type React from "react";
import { useTranslation } from "react-i18next";

import { P } from "@/base/components/Global";
import Icon from "@/base/components/Global/Icon";
import {
  APOLLO_CLUB_CARD_BG,
  APOLLO_STAR_ICON,
  INFO_ICON,
  QR_ICON,
} from "@/constants/blobIcons";
import { createUseStyles, getTheme } from "@/theme";

const useStyles = createUseStyles(({ spacing, font, color, card }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundPosition: "center",
    backgroundImage: `url(${APOLLO_CLUB_CARD_BG})`,
    width: "100%",
    padding: spacing.m,
    color: color.white,
    aspectRatio: card.ratio,
    minWidth: card.minWidth,
    backgroundRepeat: "no-repeat",
  },
  clubTitle: {
    fontSize: font.size.s,
    gap: spacing.s,
    display: "flex",
    alignItems: "center",
  },
  claimCardMessage: {
    color: color.gray4,
    fontSize: font.size.m,
    textTransform: "uppercase",
    gap: spacing.s,
    display: "flex",
    alignItems: "center",
  },
  logInMessage: {
    textTransform: "uppercase",
    display: "flex",
    gap: spacing.s,
    alignItems: "center",
    justifyContent: "space-between",
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

const Card: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = getTheme();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.clubTitle}>
          <Icon icon={APOLLO_STAR_ICON} />
          <span>{t("global_title")}</span>
        </div>
        <div className={classes.bottom}>
          <div className={classes.claimCardMessage}>
            <span>{t("claim_club_card")}</span>
            <Icon
              icon={QR_ICON}
              width={60}
              height={60}
              stroke={theme.color.white}
            />
          </div>
          <div className={classes.logInMessage}>
            <P xxSmall gray4 noMargin>
              {t("club_card_login_message")}
            </P>
            <Icon
              icon={INFO_ICON}
              width={16}
              height={16}
              stroke={theme.color.gray4}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
