import type React from "react";
import { useTranslation } from "react-i18next";

import { Button, GenericModal, H5, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import type { GenericModalProps } from "@/base/components/Modal/GenericModal";
import type { AnalyticsAttributes } from "@/constants/googleAnalytics";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ color }) => ({
  reverse: {
    flexDirection: "row-reverse",
  },
  warning: {
    color: color.error,
  },
}));

type ConfirmationModalProps = GenericModalProps & {
  title?: string;
  subTitle?: string;
  warning?: string;
  content?: string;
  primaryTitle?: string;
  primaryAction: () => void;
  secondaryAction?: () => void;
  secondaryTitle?: string;
  areButtonsReversed?: boolean;
  isLoading?: boolean;
  analyticsAttributes?: AnalyticsAttributes;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  title = "",
  subTitle = "",
  content = "",
  primaryAction,
  primaryTitle = "",
  secondaryAction,
  secondaryTitle = "",
  analyticsAttributes,
  areButtonsReversed = false,
  warning = "",
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <Col col={12}>
        <H5 gray1 noMargin>
          {title}
        </H5>
      </Col>
      <Col col={12} hide={!subTitle}>
        <P bold>{subTitle}</P>
      </Col>
      <Col col={12}>
        <P>{content}</P>
      </Col>
      {warning && (
        <Col col={12}>
          <P className={classes.warning}>{warning}</P>
        </Col>
      )}
      <Row
        noMargin
        className={areButtonsReversed ? classes.reverse : undefined}
      >
        <Col col={6}>
          <Button
            action={secondaryAction || onClose}
            transparentBrand={!areButtonsReversed}
          >
            {secondaryTitle || t("confirmation_cancel")}
          </Button>
        </Col>
        <Col col={6}>
          <Button
            analyticsAttributes={analyticsAttributes}
            action={primaryAction}
            isLoading={isLoading}
            transparentBrand={areButtonsReversed}
          >
            {primaryTitle}
          </Button>
        </Col>
      </Row>
    </GenericModal>
  );
};

export default ConfirmationModal;
