import * as React from "react";
import { memo } from "react";
const SvgAddItemIcon = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 25,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "mask",
    {
      id: "65b40e3e-9ce2-4310-9d85-4485ad2e5969-a",
      width: 25,
      height: 24,
      x: 0,
      y: 0,
      maskUnits: "userSpaceOnUse",
      style: {
        maskType: "alpha"
      }
    },
    /* @__PURE__ */ React.createElement("path", { fill: "#C4C4C4", d: "M.5 0h24v24H.5z" })
  ),
  /* @__PURE__ */ React.createElement("g", { mask: "url(#65b40e3e-9ce2-4310-9d85-4485ad2e5969-a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#F68A1F",
      fillRule: "evenodd",
      d: "M12.5 5a1 1 0 0 0-1 1v5h-5a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5V6a1 1 0 0 0-1-1",
      clipRule: "evenodd"
    }
  ))
);
const Memo = memo(SvgAddItemIcon);
export default Memo;
