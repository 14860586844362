import type React from "react";

import { CmsBlock, CmsBlocks } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import BenefitsBlock from "@/pages/HomePage/components/BenefitsBlock/BenefitsBlock";
import IntroductionBlocks from "@/pages/HomePage/components/IntroductionBlocks";
import { fetchClubPage } from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

const ApolloClubPage: React.FC = () => {
  const clubPage = useAppSelector((state) => state.cms.clubPage);
  const dispatch = useAppDispatch();

  return (
    <CmsPage
      pageStateMachine={clubPage}
      fetchPage={() => dispatch(fetchClubPage())}
    >
      {({ page }) => (
        <CmsBlocks>
          <CmsBlock hide={!page.benefitsBlock} addBreak>
            <BenefitsBlock block={page.benefitsBlock} />
          </CmsBlock>

          <CmsBlock hide={!page.apolloClubIntroductionBlocks} addBreak={false}>
            <IntroductionBlocks block={page.apolloClubIntroductionBlocks} />
          </CmsBlock>
        </CmsBlocks>
      )}
    </CmsPage>
  );
};

export default ApolloClubPage;
