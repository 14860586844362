import type React from "react";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import { getToken } from "@/api";
import { RowBreak } from "@/base/components/Layout";
import RootProvider from "@/base/components/RootProvider";
import { isInIframe } from "@/base/utils/iframeUtils";
import config from "@/constants/config";
import { NavigationPath } from "@/constants/navigation";
import routes from "@/constants/routes";
import ErrorPage from "@/pages/ErrorPage/ErrorPage";
import MyInfoBlock from "@/pages/ProfilePage/components/MyInfoBlock";

import { Loader } from "../Global";
import GenericRouteLayout from "../Layouts/GenericRouteLayout";
import { useUser } from "../UserProvider";

type PrivateRouteProps = {
  children: JSX.Element;
};

const ProtectedRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated, user, isLoading } = useAuth();
  const location = useLocation();
  const [{ logIn }, { hasTriedSilentSignin }] = useUser();
  const token = getToken();

  useEffect(() => {
    if (!isAuthenticated && !isLoading && hasTriedSilentSignin) {
      logIn();
    }
  }, [isAuthenticated, isLoading, hasTriedSilentSignin]);

  const pathname = location.pathname;
  // Allow these paths to pass auth check so user can be redirected to SSO
  if (
    pathname === NavigationPath.AuthComplete ||
    pathname === NavigationPath.AuthStart
  ) {
    return children;
  }

  if (isAuthenticated && user && token) {
    return children;
  }

  return <Loader />;
};

const getDomainRoutes = () => {
  let currentRoutes = routes.hub;

  switch (config.routerConfig.routerMode) {
    case "hub":
      currentRoutes = routes.hub;
      break;

    case "giftCard":
      currentRoutes = routes.giftCard;
      break;

    case "profile":
      currentRoutes = routes.profile;
      break;

    case "regional":
      currentRoutes = routes.regional;
      break;

    default:
      currentRoutes = routes.hub;
  }

  if (isInIframe() && location.pathname === NavigationPath.AuthComplete) {
    return <Route path={NavigationPath.AuthComplete} element={<Loader />} />;
  }

  return currentRoutes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      element={
        route.isProtected ? (
          <GenericRouteLayout {...route.layoutProps}>
            <ProtectedRoute>
              <>
                {route.showProfileBlock && (
                  <>
                    <MyInfoBlock minimal={route.isProfileBlockMinified} />
                    <RowBreak />
                  </>
                )}
                <route.Component />
              </>
            </ProtectedRoute>
          </GenericRouteLayout>
        ) : (
          <GenericRouteLayout {...route.layoutProps}>
            {route.showProfileBlock && (
              <>
                <MyInfoBlock minimal={route.isProfileBlockMinified} />
                <RowBreak />
              </>
            )}
            <route.Component />
          </GenericRouteLayout>
        )
      }
    />
  ));
};

const sentryRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />} element={<RootProvider />}>
      {getDomainRoutes()}
    </Route>,
  ),
);
