export const GENERIC_LOCATION_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Club_Location.svg";
export const APOLLO_STAR_ICON =
  "https://assets.markus.live//00-generic-app/logo/ApolloClub_logo.svg";
export const LOCATION_CLUB_ICON =
  "https://assets.markus.live/00-generic-app/icons/location/Club_Location.svg";
export const SEARCH_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Search_Small.svg";
export const EMAIL_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/E_mail.svg";
export const PHONE_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Phone.svg";
export const APOLLO_CLUB_CARD_BG =
  "https://assets.markus.live/01-loyalty-portal/media/ApolloClubCard_bg_343x200.svg";
export const APOLLO_CARD_BG =
  "https://assets.markus.live/01-loyalty-portal/media/ApolloClubCard_bg_empty_1029x600.svg";
export const ADDRESS_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Aadress.svg";
export const ADDRESS_DELIVERY_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Address_Delivery.svg";
export const ADDRESS_BILLING_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Address_Billing.svg";
export const ATTENTION_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Attention.svg";
export const SUPPORT_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Feedback.svg";
export const CHEVRON_FORWARD =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Chevron_Forward.svg";
export const GIFT_CARD_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Gift_Card.svg";
export const GIFT_CARD_COLORED_ICON =
  "https://assets.markus.live/01-loyalty-portal/icons/tabbar/GiftCard_selected.svg";
export const ADD_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Add.svg";
export const TRANSACTION_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Transaction.svg";
export const CHECK_SMALL_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Check_Small.svg";
export const COPY_SMALL_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Copy_Small.svg";
export const SIGN_IN_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Sign_In.svg";
export const CLUB_MEMBER_ICON =
  "https://assets.markus.live/00-generic-app/icons/small/Club_Member_Star16.svg";
export const CINEMA_MEMBER_ICON =
  "https://assets.markus.live/00-generic-app/icons/small/Cinema_Staff_Ticket16.svg";
export const BUSINESS_MEMBER_ICON =
  "https://assets.markus.live/00-generic-app/icons/small/Apollo_Staff_A16.svg";
export const PROFILE_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Profile.svg";
export const APOLLO_CLUB_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Apollo_Club_Line.svg";
export const APOLLO_CLUB_WITH_STAR_EN_ICON =
  "https://assets.markus.live/01-loyalty-portal/logo/Apollo_klubi_logo_EN.svg";
export const APOLLO_CLUB_WITH_STAR_ET_ICON =
  "https://assets.markus.live/01-loyalty-portal/logo/Apollo_klubi_logo_EE.svg";
export const APOLLO_CLUB_WITH_STAR_LT_ICON =
  "https://assets.markus.live/01-loyalty-portal/logo/Apollo_klubi_logo_LT.svg";
export const APOLLO_CLUB_WITH_STAR_LV_ICON =
  "https://assets.markus.live/01-loyalty-portal/logo/Apollo_klubi_logo_LV.svg";
export const APOLLO_CLUB_TEXT_WHITE_EN_ICON =
  "https://assets.markus.live/01-loyalty-portal/logo/Apollo_klubi_logotext_EN.svg";
export const APOLLO_CLUB_TEXT_WHITE_ET_ICON =
  "https://assets.markus.live/01-loyalty-portal/logo/Apollo_klubi_logotext_EE.svg";
export const APOLLO_CLUB_TEXT_WHITE_LT_ICON =
  "https://assets.markus.live/01-loyalty-portal/logo/Apollo_klubi_logotext_LT.svg";
export const APOLLO_CLUB_TEXT_WHITE_LV_ICON =
  "https://assets.markus.live/01-loyalty-portal/logo/Apollo_klubi_logotext_LV.svg";
export const INFO_ICON =
  "https://assets.markus.live/00-generic-app/icons/small/Info16.svg";
export const APOLLO_SIGN_IN_LOGO_ICON =
  "https://assets.markus.live/00-generic-app/media/ApolloClubCardSmall_74x48.svg";
export const SHOW_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Show_Preview.svg";
export const HIDE_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Hide_Preview.svg";
export const CLOSE_SMALL_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Close_small.svg";
export const BACK_CIRCLE_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Back_Circle.svg";
export const PASSWORD_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Password.svg";
export const LOADING_SPINNER_ICON =
  "https://assets.markus.live/01-loyalty-portal/media/loader_small_24.svg";
export const TERMS_AND_CONDITIONS_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Terms_Conditions.svg";
export const MARKUS_BURGER_MENU_LOGO_ICON =
  "https://assets.markus.live/00-generic-app/logo/markus_footer_lightgray-color_130x46.svg";
export const MARKUS_FOOTER_LOGO_ICON =
  "https://assets.markus.live/00-generic-app/logo/markus_footer_darkgray-color_130x46.svg";
export const DELETE_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Delete.svg";
export const LOGOUT_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Sign_Out.svg";
export const LOGIN_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Sign_In.svg";
export const QR_ICON =
  "https://assets.markus.live/01-loyalty-portal/media/qr-img.svg";
export const BURGER_NOTIFICATION_ICON =
  "https://assets.markus.live/01-loyalty-portal/icons/topbar/TopBar_Hamburger_active.svg";
export const BACKWARDS_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Backwards.svg";
export const SCAN_QR_ICON =
  "https://assets.markus.live/00-generic-app/icons/generic-icons/Scan_QR_thin.svg";
