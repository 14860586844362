import type React from "react";
import type { ChangeEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import type { ILocationPageLayout } from "@/api/interfaces/locationPageLayout";
import AccordionListProvider from "@/base/components/AccordionList/AccordionListProvider";
import SelectList from "@/base/components/Form/v1/SelectList";
import { GenericModal, Loader } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import { Col, Row, RowBreak } from "@/base/components/Layout";
import useDidUpdateEffect from "@/base/hooks/useDidUpdateEffect";
import { useAppDispatch, useAppSelector } from "@/store";
import { fetchLocationsPage, selectLocationsPage } from "@/store/cms";

import AllLocationsMap from "./components/AllLocationsMapBlock";
import { BusinessListItems } from "./components/BusinessSelectAccordion";
import LocationTitleBlock from "./components/LocationsTitleBlock";
import Tabs from "./components/Tabs";
import UrlCopiedModalContent from "./components/UrlCopiedModalContent";
import useFilteredLocations from "./hooks/useFilteredLocations";
import {
  getUniqueBusinessTypes,
  getUniqueCitiesForRegionAndBusinessType,
  getUniqueRegionsForBusinessType,
} from "./helpers";

type LocationsPageContent = {
  page: ILocationPageLayout;
};

const LocationsPageContent: React.FC<LocationsPageContent> = ({ page }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const filteredLocations = useFilteredLocations(page.locationsBlocks);
  const [isUrlCopiedModalOpen, setIsUrlCopiedModalOpen] = useState(false);

  const countryParam = searchParams.get("country");
  const businessTypeParam = searchParams.get("type");
  const cityParam = searchParams.get("city");
  const locationParam = searchParams.get("location");
  const currentLocation = page.locationsBlocks?.find(
    (loc) => loc.name === locationParam,
  );

  useDidUpdateEffect(() => {
    searchParams.delete("brand");
    searchParams.delete("location");
    setSearchParams(searchParams);
  }, [countryParam, businessTypeParam, cityParam]);

  const getTabs = () => {
    if (!page.locationsBlocks) return [];

    const businessTypes = getUniqueBusinessTypes(page.locationsBlocks).map(
      (i) => ({
        label: i.name,
        value: i.key,
        action: () => {
          searchParams.set("type", i.key);
          setSearchParams(searchParams);
        },
      }),
    );

    return [
      {
        label: t("show_all"),
        value: "all",
        action: () => {
          searchParams.delete("type");
          setSearchParams(searchParams);
        },
      },
      ...businessTypes,
    ];
  };

  const getRegions = () => {
    if (!page.locationsBlocks) return [];

    return getUniqueRegionsForBusinessType(page.locationsBlocks);
  };

  const getCities = () => {
    if (!page.locationsBlocks) return [];

    return getUniqueCitiesForRegionAndBusinessType(
      page.locationsBlocks,
      countryParam,
    );
  };

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    searchParams.delete("city");

    if (!e.target.value) {
      searchParams.delete("country");
      setSearchParams(searchParams);
      return;
    }

    searchParams.set("country", e.target.value);
    setSearchParams(searchParams);
  };

  const handleCityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      searchParams.delete("city");
      setSearchParams(searchParams);
      return;
    }

    searchParams.set("city", e.target.value);
    setSearchParams(searchParams);
  };

  if (!page.locationsBlocks)
    return (
      <>
        <RowBreak />
        <Loader isMinified />
      </>
    );

  return (
    <>
      <GenericModal
        isOpen={isUrlCopiedModalOpen && !!currentLocation}
        onClose={() => setIsUrlCopiedModalOpen(false)}
      >
        {currentLocation && (
          <UrlCopiedModalContent
            location={currentLocation}
            onConfirm={() => setIsUrlCopiedModalOpen(false)}
          />
        )}
      </GenericModal>
      <RowBreak belowSm />
      <Row>
        <LocationTitleBlock />
      </Row>
      <RowBreak />
      <Row>
        <Col colMd={6}>
          <Tabs
            items={page.locationsBlocks ? getTabs() : []}
            value={businessTypeParam ?? "all"}
          />
        </Col>
      </Row>
      <Row>
        <Col colMd={6} directionColumn>
          <Row noMargin>
            <Col directionColumn noHorizontalMargin>
              <SelectList
                displayEmpty
                key="currentCountry"
                id="territory-selection"
                label={t("select_region")}
                initialValue={countryParam}
                currentValue={countryParam}
                values={getRegions().map((region) => ({
                  value: region.key,
                  label: region.name,
                }))}
                onChange={handleRegionChange}
              />
              <SelectList
                displayEmpty
                key="currentCity"
                id="city-selection"
                label={t("select_city")}
                initialValue={cityParam}
                currentValue={cityParam}
                values={getCities().map((city) => ({
                  value: city.key,
                  label: city.name,
                }))}
                onChange={handleCityChange}
              />
            </Col>
          </Row>
          <Row noMargin>
            <Col directionColumn noMargin>
              <AccordionListProvider>
                <BusinessListItems
                  locations={filteredLocations ?? []}
                  onCopyUrl={() => {
                    setIsUrlCopiedModalOpen(true);
                  }}
                />
              </AccordionListProvider>
            </Col>
          </Row>
        </Col>
        <Col directionColumn noMargin col={12} colMd={6}>
          <AllLocationsMap
            locations={filteredLocations ?? []}
            onCopyUrl={() => {
              setIsUrlCopiedModalOpen(true);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

const LocationsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const locationsPage = useAppSelector(selectLocationsPage);

  return (
    <CmsPage
      pageStateMachine={locationsPage}
      fetchPage={() => dispatch(fetchLocationsPage())}
    >
      {({ page }) => <LocationsPageContent page={page} />}
    </CmsPage>
  );
};

export default LocationsPage;
