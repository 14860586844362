import type React from "react";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";

import type { IHomeLoyaltyCards } from "@/api/interfaces/giftcardsLayout";
import NoPerksBlock from "@/base/blocks/PerkBlock/NoPerksBlock";
import { Loader, P, Section } from "@/base/components/Global";
import { Row } from "@/base/components/Layout";
import { GIFT_CARD_ICON } from "@/constants/blobIcons";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { fetchPerks, selectPerks } from "@/store/perks";
import { hasData, isLoading, shouldLoad } from "@/store/utils";
import { createUseStyles } from "@/theme";

import GiftCardActions from "./GiftCardActions";
import PerksAccordion from "./PerksAccordion";
import PerksTable from "./PerksTable";

export const PERKS_BLOCK_ID = "giftcard-content";

const useStyles = createUseStyles(({ spacing, sizes }) => ({
  perks: {
    marginTop: spacing.xl,

    [sizes.md]: {
      display: "flex",
      columnGap: spacing.xxl,
    },
  },
  perkBlock: {
    paddingLeft: spacing.l,
    paddingRight: spacing.l,

    [sizes.md]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  perksTable: {
    "& tbody td": {
      paddingLeft: spacing.xs,
      paddingRight: spacing.xs,
    },
    "& thead th": {
      wordBreak: "normal",
      paddingTop: 0,
    },
  },
}));

type Props = {
  block?: IHomeLoyaltyCards;
  showTable?: boolean;
  showDescription?: boolean;
};

const PerkBlock: React.FC<Props> = ({
  block,
  showTable = true,
  showDescription = true,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const perks = useAppSelector(selectPerks);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated && shouldLoad(perks)) {
      void dispatch(fetchPerks());
    }
  }, [isAuthenticated, perks]);

  if (!block) return null;

  if (
    !isAuthenticated ||
    shouldLoad(perks) ||
    (hasData(perks) && perks.array.length === 0)
  ) {
    return (
      <Row>
        <NoPerksBlock block={block} />
      </Row>
    );
  }

  if (isLoading(perks)) {
    return <Loader isMinified />;
  }

  const { title, subTitle, actions, description } = block;

  return (
    <Row id={PERKS_BLOCK_ID} noMargin className={classes.perkBlock}>
      <Section
        title={title}
        subTitle={subTitle}
        icon={GIFT_CARD_ICON}
        noAdditionalMargin
      >
        <div className={classes.perks}>
          <PerksAccordion perks={perks.array} />
          <div>
            {showTable && <PerksTable classNames={classes.perksTable} />}
            <GiftCardActions actions={actions} />
            {showDescription && <P noMarginBottom cmsContent={description} />}
          </div>
        </div>
      </Section>
    </Row>
  );
};

export default PerkBlock;
