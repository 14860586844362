import { useController, useFormContext } from "react-hook-form";

import PhoneField, {
  type PhoneFieldProps,
} from "@/base/components/Form/v1/PhoneField";

type PhoneFieldWithControllerProps = {
  name: string;
} & PhoneFieldProps;

const PhoneFieldWithController = ({
  name,
  ...restProps
}: PhoneFieldWithControllerProps) => {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { invalid },
  } = useController({
    control,
    name,
  });

  return (
    <PhoneField
      {...field}
      {...restProps}
      innerRef={ref}
      hasError={invalid}
      onChange={(e) => {
        field.onChange(e);
        if (restProps.onChange) {
          restProps.onChange(e);
        }
      }}
    />
  );
};

export default PhoneFieldWithController;
