export type FooterLinkProps = {
  name: string;
  path: string;
  isExternal: boolean;
};

export const footerLinks: FooterLinkProps[] = [
  {
    name: "footer_privacy_policy",
    path: "footer_privacy_link",
    isExternal: true,
  },
  {
    name: "footer_terms_and_conditions",
    path: "footer_terms_link",
    isExternal: true,
  },
];
