import type React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider as ReactHookFormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import GiftCardController from "@/api/controller/giftCardController";
import TextFieldWithController from "@/base/components/Form/v1/Controller/TextFieldWithController";
import { H5, P } from "@/base/components/Global";
import Button from "@/base/components/Global/Button";
import H6 from "@/base/components/Global/H6";
import Icon from "@/base/components/Global/Icon";
import { Col, Row } from "@/base/components/Layout";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import Modal from "@/base/components/Modal/GenericModal";
import { toTranslationKey } from "@/base/utils/formUtils";
import { CHECK_SMALL_ICON, SCAN_QR_ICON } from "@/constants/blobIcons";
import {
  GiftCardNumberBC,
  GiftCardNumberQP,
  NavigationPath,
} from "@/constants/navigation";
import GiftCardDomainTitle from "@/pages/GiftCardStatusPage/components/GiftCardDomainTitle";
import Scanner from "@/pages/GiftCardStatusPage/components/Scanner";
import { createUseStyles, getTheme } from "@/theme";

type FormType = {
  usageBarcode: string;
  pin1: string;
  pin2: string;
  pin3: string;
  pin4: string;
};

type pinsFieldsNames = "pin1" | "pin2" | "pin3" | "pin4";

const useStyles = createUseStyles(({ color, spacing }) => ({
  pin: {
    "&  .MuiFormControl-root": {
      width: 48,
      height: 48,
    },
    "& input": {
      textAlign: "center",
      textTransform: "uppercase",
    },
  },
  error: {
    "& p": {
      color: color.error,
    },
  },
  inputWithButton: {
    display: "flex",
    alignItems: "flex-start",
  },
  number: {
    "& input": {
      textTransform: "uppercase",
    },
  },
  description: {
    alignSelf: "flex-start",
  },
  closeButton: {
    position: "absolute",
    right: spacing.l,
    cursor: "pointer",
  },
  scanButton: {
    marginTop: spacing.m, // allows alignment with the input field even with helper text
    padding: 0,
  },
  scanCancelButton: {
    marginTop: spacing.l,
  },
  scannerWrapper: {
    maxWidth: "100%",
  },
}));

const FormSchema = z.object({
  usageBarcode: z
    .string({
      required_error: "required",
      message: "invalid_format" as const,
    })
    .regex(new RegExp("^92[A-Za-z0-9]{9}$"), {
      message: "invalid_format" as const,
    }),
  pin1: z.string().regex(new RegExp("^[A-Za-z0-9]$")),
  pin2: z.string().regex(new RegExp("^[A-Za-z0-9]$")),
  pin3: z.string().regex(new RegExp("^[A-Za-z0-9]$")),
  pin4: z.string().regex(new RegExp("^[A-Za-z0-9]$")),
});
type FormErrorMap = typeof FormErrorMap;

const FormErrorMap = {
  invalid_format: "gift_card_status_card_number_format_error",
  scanned_purchase_code_error:
    "gift_card_status_card_number_scanned_purchase_code_error",
  default: "gift_card_status_card_number_format_error",
} as const;

const NEXT_PIN_REGEX = /^(?:[\dA-Za-z]|ArrowRight)$/;
const PREV_PIN_REGEX = /^(?:Backspace|ArrowLeft)$/;

const pinFieldOnFocusHandler = (e: React.FocusEvent<HTMLInputElement>) =>
  e.target.select();

const GiftCardStatusPage = () => {
  const classes = useStyles();
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const { t } = useTranslation();
  const { color } = getTheme();
  const navigate = useNavigate();
  const [searchQuery] = useSearchParams();
  const [{ isMobile }] = useMediaQuery();
  const [isScanQrModalOpened, setIsScanQrModalOpened] = useState(false);

  const methods = useForm<FormType>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
    defaultValues: {
      usageBarcode: searchQuery?.get(GiftCardNumberQP) ?? "",
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
    },
  });

  const {
    handleSubmit,
    trigger,
    setFocus,
    setValue,
    setError,
    formState: { errors, isSubmitting, isValid },
  } = methods;

  const onSubmit = async (data: FormType) => {
    setShouldDisplayError(false);

    const { isResponseOk, response } =
      await GiftCardController.validateGiftCard({
        body: {
          usageBarcode: data.usageBarcode,
          pin: `${data.pin1}${data.pin2}${data.pin3}${data.pin4}`,
        },
      });

    if (isResponseOk && response) {
      navigate(NavigationPath.GiftCardStatusDetails, {
        state: { validationCard: response },
      });
    } else {
      setShouldDisplayError(true);
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement> | undefined) => {
    if (!e) return;

    e.preventDefault();

    const pasteData = e.clipboardData.getData("Text")?.split("");
    if (pasteData.length === 4) {
      setValue("pin1", pasteData[0]);
      setValue("pin2", pasteData[1]);
      setValue("pin3", pasteData[2]);
      setValue("pin4", pasteData[3]);
      setFocus("pin4");
    }
  };

  const pinShift = (
    e: React.ChangeEvent<HTMLInputElement>,
    next?: pinsFieldsNames,
    prev?: pinsFieldsNames,
  ) => {
    if (!isMobile) return;

    if (next && e.target.value) {
      setFocus(next);
    } else if (prev && !e.target.value) {
      setFocus(prev);
    }
  };

  const onKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    next?: pinsFieldsNames,
    prev?: pinsFieldsNames,
  ) => {
    if (isMobile) return;

    if (next && Boolean(NEXT_PIN_REGEX.test(e.key))) {
      setFocus(next);
    } else if (prev && Boolean(PREV_PIN_REGEX?.test(e.key))) {
      setFocus(prev);
    }
  };

  const onQrScan = (scannedValue: string) => {
    if (scannedValue.startsWith("http")) {
      const url = new URL(scannedValue);
      const searchParams = new URLSearchParams(url.search);

      if (searchParams.has(GiftCardNumberQP)) {
        setValue("usageBarcode", searchParams.get(GiftCardNumberQP) ?? "", {
          shouldValidate: true,
        });

        setFocus("pin1");
      } else if (searchParams.has(GiftCardNumberBC)) {
        setValue("usageBarcode", "");
        setError("usageBarcode", {
          type: "custom",
          message: "scanned_purchase_code_error",
        });
      }
    } else {
      setError("usageBarcode", {
        message: "invalid_format",
      });
    }

    setIsScanQrModalOpened(false);
  };

  return (
    <Row>
      <Modal
        isOpen={isScanQrModalOpened}
        onClose={() => setIsScanQrModalOpened(false)}
      >
        <div className={classes.scannerWrapper}>
          <Scanner onScan={onQrScan} />
          <Button
            className={classes.scanCancelButton}
            action={() => setIsScanQrModalOpened(false)}
          >
            {t("gift_card_status_page_scan_cancel_button")}
          </Button>
        </div>
      </Modal>
      <Col fullWidth alignCenter>
        <Col col={12} colMd={6} noMargin directionColumn fullWidth>
          <GiftCardDomainTitle />
          <ReactHookFormProvider {...methods}>
            <Col col={12} noMargin directionColumn alignCenter fullWidth>
              <Row noMargin>
                <Col col={12}>
                  <H5 gray1>{t("gift_card_status_page_card_number")}</H5>
                </Col>
              </Row>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Row noMargin className={classes.inputWithButton}>
                  <Col col={isMobile ? 9.8545 : 12} className={classes.number}>
                    <TextFieldWithController
                      label={t("field_card_number")}
                      id="usageBarcode"
                      data-testid="field-usageBarcode"
                      onEnter={{ trigger, name: "pin1" }}
                      onFocus={(e) => e.target.select()}
                      autoFocus={!searchQuery?.get(GiftCardNumberQP)}
                      placeholder={t("field_card_number_placeholder")}
                      errorText={t(
                        toTranslationKey(
                          FormErrorMap,
                          errors.usageBarcode?.message,
                        ),
                      )}
                      name="usageBarcode"
                    />
                  </Col>
                  {isMobile && (
                    <Col col={2.1455}>
                      <Button
                        transparentBrand
                        action={() => setIsScanQrModalOpened(true)}
                        className={classes.scanButton}
                      >
                        <Icon icon={SCAN_QR_ICON} stroke={color.primaryBrand} />
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row noMargin>
                  <Col col={12}>
                    <H6 gray1>{t("gift_card_status_page_pin")}</H6>
                  </Col>
                </Row>
                <Row noMargin style={{ justifyContent: "center" }}>
                  <Col col={2} className={classes.pin} noHorizontalMargin>
                    <TextFieldWithController
                      id="pin1"
                      data-testid="field-pin1"
                      autoFocus={!!searchQuery?.get(GiftCardNumberQP)}
                      onFocus={pinFieldOnFocusHandler}
                      maxLength={1}
                      handleKeyUp={(e) => onKeyUp(e, "pin2")}
                      onPaste={onPaste}
                      name="pin1"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        pinShift(e, "pin2")
                      }
                    />
                  </Col>
                  <Col col={2} className={classes.pin} noHorizontalMargin>
                    <TextFieldWithController
                      id="pin2"
                      data-testid="field-pin2"
                      maxLength={1}
                      handleKeyUp={(e) => onKeyUp(e, "pin3", "pin1")}
                      onFocus={pinFieldOnFocusHandler}
                      name="pin2"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        pinShift(e, "pin3", "pin1")
                      }
                    />
                  </Col>
                  <Col col={2} className={classes.pin} noHorizontalMargin>
                    <TextFieldWithController
                      id="pin3"
                      data-testid="field-pin3"
                      maxLength={1}
                      handleKeyUp={(e) => onKeyUp(e, "pin4", "pin2")}
                      onFocus={pinFieldOnFocusHandler}
                      name="pin3"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        pinShift(e, "pin4", "pin2")
                      }
                    />
                  </Col>
                  <Col col={2} className={classes.pin} noHorizontalMargin>
                    <TextFieldWithController
                      id="pin4"
                      data-testid="field-pin4"
                      maxLength={1}
                      handleKeyUp={(e) => onKeyUp(e, undefined, "pin3")}
                      onFocus={pinFieldOnFocusHandler}
                      submitOnEnter
                      name="pin4"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        pinShift(e, undefined, "pin3")
                      }
                    />
                  </Col>
                </Row>
                <Col col={12} hide={!shouldDisplayError}>
                  <P className={classes.error}>
                    {t("gift_card_number_or_pin_error")}
                  </P>
                </Col>
                <Col col={12}>
                  <Button
                    type="submit"
                    data-testid="status-check-submit-btn"
                    isLoading={isSubmitting}
                    isDisabled={!isValid}
                    prefixIcon={
                      <Icon icon={CHECK_SMALL_ICON} stroke={color.white} />
                    }
                  >
                    {t("action_check_card_status")}
                  </Button>
                </Col>
              </form>
            </Col>
          </ReactHookFormProvider>
        </Col>
        <Col aboveMd col={1} />
        <Col
          colMd={5}
          aboveMd
          directionColumn
          fullWidth
          className={classes.description}
          alignCenter
        >
          <Col fullWidth>
            <H5>{t("gift_card_status_description_title")}</H5>
          </Col>
          <Col fullWidth noMarginTop>
            <P>{t("gift_card_status_description_text")}</P>
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export default GiftCardStatusPage;
