import * as React from "react";
import { memo } from "react";
const SvgOrangePlus = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 14,
    height: 14,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#F68A1F",
      fillRule: "evenodd",
      d: "M7 0a1 1 0 0 0-1 1v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0-2H8V1a1 1 0 0 0-1-1",
      clipRule: "evenodd"
    }
  )
);
const Memo = memo(SvgOrangePlus);
export default Memo;
