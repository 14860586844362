import type React from "react";
import { createContext, useContext, useEffect, useReducer } from "react";

import {
  DEFAULT_STATE,
  MediaQueryActions,
  mediaQueryReducer,
} from "@/base/components/MediaQueryProvider/MediaQueryReducer";
import type {
  MediaQueryContextProps,
  MediaQueryProviderProps,
} from "@/base/components/MediaQueryProvider/types";
import useMediaQueryHook from "@/base/hooks/useMediaQueryHook";
import theme from "@/theme/common";

const MediaQueryContext = createContext<MediaQueryContextProps>([
  DEFAULT_STATE,
]);

const useMediaQuery = () => useContext(MediaQueryContext);

const MediaQueryProvider: React.FC<MediaQueryProviderProps> = ({
  children,
}) => {
  const isCurrentSizeXl = useMediaQueryHook(theme.sizes.xlMinWidth);
  const isCurrentSizeLg = useMediaQueryHook(theme.sizes.lgMinWidth);
  const isCurrentSizeMd = useMediaQueryHook(theme.sizes.mdMinWidth);
  const isCurrentSizeSm = useMediaQueryHook(theme.sizes.smMinWidth);

  const [state, dispatch] = useReducer(mediaQueryReducer, { ...DEFAULT_STATE });

  useEffect(() => {
    dispatch(
      MediaQueryActions.setMediaQuery({
        isCurrentSizeXl,
        isCurrentSizeLg,
        isCurrentSizeMd,
        isCurrentSizeSm,
      }),
    );
  }, [isCurrentSizeXl, isCurrentSizeLg, isCurrentSizeMd, isCurrentSizeSm]);

  useEffect(() => {
    if (
      /android|webos|iphone|ipad|ipod|iemobile|opera mini/i.test(
        navigator.userAgent,
      )
    ) {
      dispatch(MediaQueryActions.setIsMobile(true));
      const mediaMatcher = globalThis.matchMedia("(orientation: landscape)");
      // on first load, set true if already in landscape
      if (mediaMatcher.matches) {
        dispatch(MediaQueryActions.setIsLandscape(true));
      }
      // detect screen orientation changes
      mediaMatcher.addEventListener("change", (e) => {
        if (e.matches) {
          dispatch(MediaQueryActions.setIsLandscape(true));
        } else {
          dispatch(MediaQueryActions.setIsLandscape(false));
        }
      });
    }
  }, []);

  return (
    <MediaQueryContext.Provider value={[state]}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export default MediaQueryProvider;
export { useMediaQuery };
