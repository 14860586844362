import type React from "react";

import { Icon } from "@/base/components/Global";
import { CHEVRON_FORWARD } from "@/constants/blobIcons";
import { createUseStyles } from "@/theme";

type StyleProps = {
  isActive: boolean;
};

const useStyles = createUseStyles<StyleProps>(
  ({ spacing, color, borderRadius }) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      padding: spacing.s,
      border: "1px solid",
      borderColor: ({ isActive }) =>
        isActive ? color.neutralGray : color.neutralMidGray,
      borderBottomRightRadius: borderRadius.s,
      borderTopRightRadius: borderRadius.s,
      cursor: ({ isActive }) => (isActive ? "pointer" : "auto"),
    },
  }),
);

type Props = {
  disabled?: boolean;
  onClick: React.ReactEventHandler<Element>;
  isReversed?: boolean;
};

const PerkScrollButton: React.FC<Props> = ({
  disabled = false,
  onClick,
  isReversed = false,
}) => {
  const classes = useStyles({
    isActive: !disabled,
  });

  return (
    <div
      className={classes.container}
      style={{ transform: `rotate(${isReversed ? 180 : 0}deg)` }}
      onClick={(el) => {
        if (!disabled) onClick(el);
      }}
    >
      <Icon icon={CHEVRON_FORWARD} />
    </div>
  );
};

export default PerkScrollButton;
