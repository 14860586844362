import { createSlice } from "@reduxjs/toolkit";

import type { IPerk } from "@/api/interfaces/perkLayouts";
import type { ApiGetStateMachine, ArrayWrapper } from "@/store/types";
import {
  setStateFailed,
  setStateInitial,
  setStatePending,
} from "@/store/utils";

import { fetchPerks } from "./actions";

type PerksState = {
  perks: ApiGetStateMachine<ArrayWrapper<IPerk>>;
};

const initialState: PerksState = {
  perks: { status: "idle" },
};

const perksSlice = createSlice({
  name: "perks",
  initialState,
  reducers: {
    perksRefreshing: (state) => {
      setStateInitial(state.perks, (nextState) => (state.perks = nextState));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPerks.pending, (state) => {
        setStatePending(state.perks, (nextState) => (state.perks = nextState));
      })
      .addCase(fetchPerks.fulfilled, (state, action) => {
        state.perks = { array: action.payload, status: "succeeded" };
      })
      .addCase(fetchPerks.rejected, (state) => {
        setStateFailed(state.perks, (nextState) => (state.perks = nextState));
      });
  },
  selectors: {
    selectPerks: (state) => state.perks,
  },
});

export const { selectPerks } = perksSlice.selectors;

export const { perksRefreshing } = perksSlice.actions;

export default perksSlice.reducer;
