import * as React from "react";
import { memo } from "react";
const SvgLinkArrowRight = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 8,
    height: 12,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#F68A1F",
      fillRule: "evenodd",
      d: "M6.95 6.7a1 1 0 0 0 0-1.4L2 .33A1 1 0 0 0 .59 1.76L4.83 6 .59 10.24a1 1 0 0 0 1.4 1.42z",
      clipRule: "evenodd"
    }
  )
);
const Memo = memo(SvgLinkArrowRight);
export default Memo;
