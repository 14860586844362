import GiftCardController from "@/api/controller/giftCardController";
import { createApiGetAsyncThunk } from "@/store/utils";

import { selectGiftCards } from "./selectors";

export const fetchGiftCards = createApiGetAsyncThunk(
  "giftCards/getGiftCards",
  GiftCardController.fetchGiftCards,
  selectGiftCards,
);
